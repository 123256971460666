import { useStores } from 'RootStore';
import axios from 'axios';
import { useToast } from 'contexts/ToastProvider';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'theme/atoms/modal';
import { List } from 'theme/atoms/multiselect';
import handleRequestError from 'utils/handleRequestError';
import { CustomError } from 'utils/types/CustomError';
import { Profile } from 'utils/types/Profile';

import { ProfileDto } from '../profilesRequests';
import ProfileForm from './ProfileForm/ProfileForm';
import { FormData } from './profileFormTypes';

interface ProfileFormModalProps {
  close: () => void;
  isOpen: boolean;
  isParentDetails?: boolean;
  onCreate?: () => void;
  profile: Profile | null;
}

function ProfileFormModal({ isOpen, close, profile, isParentDetails, onCreate }: ProfileFormModalProps): JSX.Element {
  const [error, setError] = useState<CustomError | null>(null);
  const { profilesStore } = useStores();

  const { profileType } = useParams();
  const { addToast, toastMessages } = useToast();
  const { addProfile, updateProfile, isSubmitting } = profilesStore;

  const handleSubmit = async ({
    flags,
    latitude,
    longitude,
    parent,
    postalCode,
    sectionsLists,
    source,
    userLabels,
  }: FormData & { sectionsLists: List[] }) => {
    const sectionIds = sectionsLists
      .map((list) => list.items)
      .flat()
      .filter((item) => item.checked && item.id !== 'National' && item.id !== 'Canada')
      .map((item) => item.id);

    //countryList should be single choice value, but it's not implemented yet, so selecting both coutries means no filters for country & selecting one country means filtering for the selected country
    let country: string | undefined = undefined;
    const countryList = sectionsLists.find((list) => list.title === 'Country');
    if (countryList) {
      const checkedCountries = countryList.items.filter((item) => item.checked);
      country = checkedCountries.length === 1 ? checkedCountries[0].id : undefined;
    }

    const profileData: ProfileDto = {
      country,
      flags,
      latitude: latitude === '' ? undefined : Number(latitude),
      longitude: longitude === '' ? undefined : Number(longitude),
      parent,
      postalCode: !postalCode ? null : postalCode,
      sectionIds,
      source,
      userLabels,
    };

    try {
      if (!profile) {
        await addProfile(profileData, profileType ?? '');
        onCreate?.();
        addToast(toastMessages.FEEDS.CREATE_SUCCESS);
      } else {
        await updateProfile(profile.id, profileData);
        addToast(toastMessages.FEEDS.UPDATE_SUCCESS);
      }

      handleClose();
    } catch (err) {
      const errorMsg =
        axios.isAxiosError(err) && err.response?.status === 409
          ? toastMessages.FEEDS.FEED_ALREADY_EXISTS
          : toastMessages.FEEDS.SAVE_ERROR;
      addToast(errorMsg, 'error');

      setError(
        handleRequestError({
          error: err,
          defaultMessage: 'Error saving profile',
          config: {
            409: toastMessages.FEEDS.FEED_ALREADY_EXISTS,
            502: `Provided URL is not a valid ${profileType} feed`,
          },
        })
      );
    }
  };

  const handleClose = (): void => {
    setError(null);
    close();
  };

  const isTwitter = profileType === 'twitter' || profile?.sourceKind === 'Twitter';
  const isRSS = profileType === 'rss' || profile?.sourceKind === 'RSS';
  const isSitemap = profileType === 'sitemap' || profile?.sourceKind === 'Sitemap';

  const handleTitle = (): string => {
    const addOrEdit = profile ? 'Edit' : 'Add';

    if (isRSS) return `${addOrEdit} RSS Feed`;
    else if (isTwitter) return `${addOrEdit} Twitter Feed`;
    else if (isSitemap) return `${addOrEdit} Sitemap Feed`;
    else return '';
  };

  return (
    <Modal isOpen={isOpen} close={handleClose} title={handleTitle()}>
      <ProfileForm
        onSubmit={handleSubmit}
        isLoading={isSubmitting}
        error={error}
        profile={profile}
        isParentDetails={isParentDetails}
      />
    </Modal>
  );
}

export default observer(ProfileFormModal);
