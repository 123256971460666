import clsx from 'clsx';
import ButtonSpinner from 'theme/atoms/buttons/ButtonSpinner';

import styles from './IconButton.module.scss';

type IconButtonType = 'error' | 'warning' | 'default';

interface IconButtonProps {
  action: () => void;
  className?: string;
  disabled?: boolean;
  icon: React.ReactNode;
  loading?: boolean;
  text: string;
  type?: IconButtonType;
}

function IconButton({
  action,
  className = '',
  disabled = false,
  icon,
  loading = false,
  text,
  type = 'default',
}: IconButtonProps): JSX.Element {
  return (
    <button className={clsx(styles.root, className, styles[type])} onClick={action} disabled={disabled}>
      {loading ? (
        <ButtonSpinner />
      ) : (
        <>
          {icon}
          <span>{text}</span>
        </>
      )}
    </button>
  );
}

export default IconButton;
