import DatePicker from 'react-datepicker';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from 'theme/svg/search.svg';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './Datepicker.module.scss';

interface DatepickerProps {
  className?: string;
  onChange: (value: Date) => void;
  value: Date | undefined;
}

const Datepicker = ({ className, onChange, value }: DatepickerProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      <DatePicker
        selected={value}
        onChange={(date: Date) => onChange(date)}
        wrapperClassName={styles.datepickerWrapper}
        dateFormat="MM/dd/yyyy"
        formatWeekDay={(nameOfDay: string) => nameOfDay.substr(0, 2).toUpperCase()}
        maxDate={new Date()}
        isClearable
      />
      <SearchIcon className={styles.icon} />
    </div>
  );
};

export default Datepicker;
