import { useStores } from 'RootStore';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ProfileFilterLabel from 'profiles/ProfileFilterLabel/ProfileFilterLabel';
import ProfileName from 'profiles/ProfileName/ProfileName';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import ActionButton from 'theme/atoms/buttons/ActionButton';
import Datepicker from 'theme/atoms/datepicker';
import Input from 'theme/atoms/inputs/Input';
import LoadingTable from 'theme/atoms/loaders/LoadingTable';
import Modal from 'theme/atoms/modal';
import { List } from 'theme/atoms/multiselect';
import Pagination from 'theme/atoms/pagination';
import { ReactComponent as SearchIcon } from 'theme/svg/search.svg';
import { formatDate } from 'utils/formatDate';
import usePaginatedMetadataLists from 'utils/hooks/usePaginatedMetadataLists';
import { Profile, ProfileNameInfoLabel } from 'utils/types/Profile';
import { SortColumn } from 'utils/types/ProfilesList';

import styles from './ProfilesList.module.scss';

interface ProfilesListProps {
  lists: List[];
  setLists: Dispatch<SetStateAction<List[]>>;
}

const PROFILE_FLAGS = [
  { name: 'Auto Posting', key: 'autoPosting' },
  { name: 'Auto Categorization', key: 'mlCategorization' },
  { name: 'Team Identification', key: 'teamIdentification' },
  { name: 'Use Proxy', key: 'useProxy' },
  { name: 'Backloading', key: 'backload' },
  { name: 'Disabled', key: 'isDisabled' },
  { name: 'Use Key Points', key: 'useKeyPoints' },
  { name: 'Use SEO Title', key: 'useSeoTitle' },
  { name: 'Use Short Description', key: 'useShortDescription' },
  { name: 'Use Open AI Metadata', key: 'useOpenaiMetadata' },
  { name: 'Use Open AI Sports', key: 'useOpenaiSports' },
];

function ProfilesList({ lists, setLists }: ProfilesListProps): JSX.Element {
  const { profilesStore } = useStores();
  const {
    closeRSSLastErrorModal,
    createdAtSearchValue,
    fetchProfiles,
    isFetchingProfiles,
    isRSSLastErrorModalOpen,
    latestId,
    newlyCreated,
    openProfileArchiveModal,
    openProfileForm,
    openRSSLastErrorModal,
    page,
    profile,
    profiles,
    resetSortBy,
    setSortColumn,
    setSortDirection,
    sortColumn,
    sortDirection,
    size: pageSize,
    textSearchValue,
    totalProfileCount,
    updateColumnSearchAndRequest,
    updatePageAndRequest,
    updatePageSizeAndRequest,
    updateSortByAndRequest,
  } = profilesStore;

  const { profileType } = useParams();
  usePaginatedMetadataLists({
    lists,
    setLists,
  });

  const handleSort = (column: SortColumn): void => {
    const dir = sortDirection === 'asc' ? 'desc' : 'asc';

    setSortColumn(column);
    setSortDirection(dir);
    updateSortByAndRequest(column, profileType ?? '');
  };

  const goToPage = (page: number): void => {
    updatePageAndRequest(page, profileType ?? '');
  };

  const onPageSizeChange = (newPageSize: number): void => {
    updatePageSizeAndRequest(newPageSize, profileType ?? '');
  };

  useEffect(() => {
    return () => resetSortBy();
  }, []);

  useEffect(() => {
    fetchProfiles(true, false, profileType);
  }, [profileType]);

  const addInfoLabel = (profile: Profile): ProfileNameInfoLabel[] => {
    const arr: ProfileNameInfoLabel[] = [];

    if (profile.isDisabled) {
      arr.push({
        type: 'Error',
        text: 'Disabled',
      });
    }
    if (profile.lastError) {
      arr.push({
        type: 'Warning',
        text: 'An error occurred',
      });
    }

    return arr;
  };

  return (
    <>
      <LoadingTable loading={isFetchingProfiles}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={clsx(styles.sortable, { [styles[sortDirection]]: sortColumn === 'title' })}>
                  <span onClick={() => handleSort('title')}>Name</span>
                  <Input
                    className={styles.search}
                    name="search"
                    label="Search"
                    hiddenLabel
                    type="search"
                    placeholder="Search by name or URL"
                    endIcon={<SearchIcon />}
                    value={textSearchValue}
                    onChange={(value: string) => updateColumnSearchAndRequest('text', value, profileType ?? '')}
                  />
                </th>
                <th className={styles.flagsAndLabelsCell}>
                  <span>Flags and Labels</span>
                </th>
                <th className={styles.zipCodeCell}>
                  <span>Zip code</span>
                </th>
                <th
                  className={clsx(styles.dateCell, styles.sortable, {
                    [styles[sortDirection]]: sortColumn === 'createdat',
                  })}
                >
                  <span onClick={() => handleSort('createdat')}>Date created</span>
                  <Datepicker
                    className={styles.search}
                    value={createdAtSearchValue}
                    onChange={(value: Date) => updateColumnSearchAndRequest('createdAt', value, profileType ?? '')}
                  />
                </th>
                <th className={styles.actionCell}></th>
              </tr>
            </thead>
            <tbody>
              {profiles.map((p) => {
                return (
                  <tr
                    key={p.id}
                    className={clsx({
                      [styles.highlighted]: newlyCreated && p.id === latestId,
                    })}
                  >
                    <td>
                      <ProfileName
                        {...p}
                        infoLabels={addInfoLabel(p)}
                        onWarningLabelClick={() => openRSSLastErrorModal(p)}
                      />
                    </td>
                    <td>
                      <div className={styles.flagsAndLabels}>
                        {PROFILE_FLAGS.map(({ key, name }) =>
                          p[key] ? <ProfileFilterLabel key={key} name={name} /> : null
                        )}

                        {p.userLabels.map((m) => (
                          <ProfileFilterLabel key={m.id} text={m.title} name={m.id} isUserLabel />
                        ))}
                      </div>
                    </td>
                    <td className={styles.zipCodeCell}>
                      <div>
                        <span className={styles.postalCode}>{p.postalCode || ''}</span>
                      </div>
                    </td>
                    <td className={styles.dateCell}>{formatDate(p.createdAt, 'MM/dd/yyyy')}</td>
                    <td className={styles.actionCell}>
                      <div className={styles.actionBtnCellContent}>
                        <ActionButton type="Edit" action={() => openProfileForm(p)} />
                        <ActionButton type="Delete" action={() => openProfileArchiveModal(p)} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          activePage={page}
          activePageItemCount={profiles.length}
          allItemCount={totalProfileCount}
          disabled={isFetchingProfiles}
          goToPage={goToPage}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
      </LoadingTable>

      <Modal isOpen={isRSSLastErrorModalOpen} close={closeRSSLastErrorModal} title="RSS Feed — last error">
        <p className={styles.rssLastError}>{profile?.lastError}</p>
      </Modal>
    </>
  );
}

export default observer(ProfilesList);
