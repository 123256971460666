import { useStores } from 'RootStore';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Tooltip from 'theme/atoms/tooltip';
import { ReactComponent as CloseIcon } from 'theme/svg/close.svg';
import { calculateDynamicFontSize } from 'utils/calculateDynamicFontSize';

import styles from './ProfileFilterLabel.module.scss';

interface ProfileFilterLabelProps {
  isUserLabel?: boolean;
  name: string;
  text?: string;
  updateFilterLists?: () => void;
}

const ProfileFilterLabel = ({ isUserLabel = false, name, text = '' }: ProfileFilterLabelProps): JSX.Element => {
  const { profilesStore } = useStores();
  const { filters } = profilesStore;

  const userLabelText = (
    <>
      <span>{text}</span>
      <span>{filters.find((f) => f.id === name) ? <CloseIcon className={styles.removeIcon} /> : null}</span>
    </>
  );

  return (
    <div
      className={clsx(styles.root, styles[name.replace(/ /g, '')], {
        [styles.flagIcon]: !isUserLabel,
      })}
      style={{ fontSize: calculateDynamicFontSize(text) }}
    >
      {isUserLabel ? userLabelText : <Tooltip text={name} />}
    </div>
  );
};

export default observer(ProfileFilterLabel);
