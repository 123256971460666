import React from 'react';
import clsx from 'clsx';
import Spinner from 'theme/atoms/loaders/Spinner';

import styles from './LoadingTable.module.scss';

interface LoadingTableProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  loading: boolean;
}

const LoadingTable = ({ children, className = '', loading }: LoadingTableProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      <div className={clsx(styles.spinnerArea, { [styles.visible]: loading })}>
        <Spinner />
      </div>
      {children}
    </div>
  );
};

export default LoadingTable;
