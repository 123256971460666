import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import Modal from 'theme/atoms/modal';
import Multiselect, { List } from 'theme/atoms/multiselect';
import { ReactComponent as CloseIcon } from 'theme/svg/close.svg';
import { filtersToBeDisplayedInFeedAndFollowed } from 'utils/constants';
import getFiltersLists from 'utils/getFiltersLists';
import usePaginatedMetadataLists from 'utils/hooks/usePaginatedMetadataLists';
import scrollToTop from 'utils/scrollToTop';
import { Filter } from 'utils/types/Filter';

import styles from './PostsFilters.module.scss';

function PostsFilters(): JSX.Element {
  const { postsStore } = useStores();
  const { filtersSource, updateFilters, updateFiltersSource, filterClickedOnPost } = postsStore;

  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const [lists, setLists] = useState<List[]>(getFiltersLists());

  //Reset local filters on filters source change
  useEffect(() => {
    if (filtersSource === 'sidebar') setLists(getFiltersLists());
  }, [filtersSource]);

  useEffect(() => {
    if (filterClickedOnPost) {
      const newLists = lists.map((list) => {
        if (list.title === filterClickedOnPost.listTitle) {
          const isInItems = list.items.findIndex((item) => item.id === filterClickedOnPost.id) !== -1;

          return {
            ...list,
            items: isInItems
              ? list.items.filter((item) => item.id !== filterClickedOnPost.id)
              : [
                  ...list.items,
                  {
                    id: filterClickedOnPost.id,
                    title: filterClickedOnPost.title,
                    checked: true,
                    hide: false,
                  },
                ],
          };
        }

        return list;
      });

      setLists(newLists);

      const listsItems = newLists.flatMap((list) => list.items.filter((item) => item.checked));
      const newFilters: Filter[] = listsItems.map((item) => ({
        id: item.id,
        title: item.title,
      }));

      const shouldScrollToTop = updateFilters(newFilters);
      if (shouldScrollToTop) {
        scrollToTop();
      }
      updateFiltersSource('main');
    }
  }, [filterClickedOnPost]);

  const handleFiltersUpdate = (newLists: List[]) => {
    const listsItems = newLists.flatMap((list) => list.items.filter((item) => item.checked));
    const newFilters: Filter[] = listsItems.map((item) => ({
      id: item.id,
      title: item.title,
    }));

    const shouldScrollToTop = updateFilters(newFilters);
    if (shouldScrollToTop) scrollToTop();
    updateFiltersSource('main');
  };

  const { handleItemToggle, handleNextPage, handleSearchValueChange } = usePaginatedMetadataLists({
    lists,
    setLists,
    onUpdate: handleFiltersUpdate,
  });

  const handleReset = (title: string) => {
    const newLists = lists.map((list) =>
      list.title === title ? { ...list, items: list.items.map((item) => ({ ...item, checked: false })) } : list
    );
    setLists(newLists);
    handleFiltersUpdate(newLists);
  };

  const getClosedList = (list: List): List => {
    if (list.type === 'dynamic') {
      return {
        ...list,
        open: false,
        items: list.items.filter((item) => item.checked),
        fetchedItems: [],
        error: null,
        fetched: false,
        isLoading: false,
        isLoadingNextPage: true,
        page: 1,
      };
    } else {
      return { ...list, open: false, items: list.items.map((item) => ({ ...item, hide: false })) };
    }
  };

  const getUncheckedItems = (lists: List[]) => {
    return lists.map((list) => {
      return {
        ...list,
        items: list.items.map((item) => {
          return { ...item, checked: false };
        }),
      };
    });
  };

  const onDropdownOpen = (title: string): void => {
    const newLists = lists.map((list) => (list.title === title ? { ...list, open: true } : { ...getClosedList(list) }));
    if (filtersSource === 'main') {
      setLists(newLists);
    } else {
      setLists(getUncheckedItems(newLists));
    }
  };

  const onDropdownClose = (title: string): void => {
    const newLists = lists.map((list) => (list.title === title ? { ...getClosedList(list) } : list));
    if (filtersSource === 'main') {
      setLists(newLists);
    } else {
      setLists(getUncheckedItems(newLists));
    }
  };

  const areCheckedAnyFiltersOfMore = useMemo(() => {
    return (
      lists
        .filter((list: List) => !list.kind || !filtersToBeDisplayedInFeedAndFollowed.includes(list.kind))
        .map((list) => list.items.find((item) => item.checked))
        .filter((el) => el).length > 0
    );
  }, [lists]);

  return (
    <div className={styles.feedFilters}>
      <div className={styles.feedFilters__lists}>
        {lists
          .filter((list: List) => list.kind && filtersToBeDisplayedInFeedAndFollowed.includes(list.kind))
          .map((list: List) => (
            <Multiselect
              key={list.title}
              list={list}
              onDropdownOpen={onDropdownOpen}
              onDropdownClose={onDropdownClose}
              onNextPage={handleNextPage}
              onSearchValueChange={handleSearchValueChange}
              onItemToggle={handleItemToggle}
              onReset={handleReset}
            />
          ))}
      </div>
      <button
        className={clsx(styles.feedFilters__moreBtn, { [styles.withItemsChecked]: areCheckedAnyFiltersOfMore })}
        onClick={() => setIsMoreModalOpen(true)}
      >
        <span className={styles.moreBtnText}>More</span>
        <div className={styles.moreBtnIcon}>
          <CloseIcon />
        </div>
      </button>
      <Modal isOpen={isMoreModalOpen} close={() => setIsMoreModalOpen(false)}>
        <h2 className={styles.modalTitle}>More filters</h2>
        <div className={clsx(styles.feedFilters__lists, styles.filtersListsInModal)}>
          {lists
            .filter((list: List) => !list.kind || !filtersToBeDisplayedInFeedAndFollowed.includes(list.kind))
            .filter((list: List) => !list.dontShowInFeedFilters)
            .map((list: List) => (
              <Multiselect
                key={list.title}
                list={list}
                onDropdownOpen={onDropdownOpen}
                onDropdownClose={onDropdownClose}
                onNextPage={handleNextPage}
                onSearchValueChange={handleSearchValueChange}
                onItemToggle={handleItemToggle}
                onReset={handleReset}
              />
            ))}
        </div>
      </Modal>
    </div>
  );
}

const ObservedPostsFilters = observer(PostsFilters);

export default ObservedPostsFilters;
