import { RootStore } from 'RootStore';
import { getAPICredentials, saveAPICredentials } from 'api';
import { action, makeAutoObservable } from 'mobx';
import { LOGIN_STORAGE_PREFIX } from 'utils/constants';
import { IS_PROD } from 'utils/constants';

import * as requests from './loginRequests';
import { Auth } from './models';

class AuthStore {
  rootStore: RootStore;
  token: Auth | null = null;
  loading = false;
  isProdUiMode = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.checkIfAuthenticated();

    makeAutoObservable(this, {
      login: action.bound,
      logout: action.bound,
    });
  }

  get isAuthenticated(): boolean {
    return !!this.token;
  }

  get isProdUiVisible(): boolean {
    return IS_PROD || (!IS_PROD && this.isProdUiMode);
  }

  toggleProdUiMode = (): void => {
    this.isProdUiMode = !this.isProdUiMode;
  };

  deleteAPICredentials = (): void => {
    localStorage.removeItem(`${LOGIN_STORAGE_PREFIX}${location.hostname}`);
  };

  checkIfAuthenticated = (): void => {
    const apiCredentials = getAPICredentials();
    this.token = apiCredentials && JSON.parse(apiCredentials);
    this.loading = false;
  };

  login(username: string, password: string): Promise<void> {
    return requests
      .login(username, password)
      .then((res) => {
        const authData = saveAPICredentials(res.data.token);
        this.token = authData;
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          throw new Error('Invalid credentials');
        } else {
          throw new Error('Unknown error, please try again later');
        }
      });
  }

  logout(): Promise<void> {
    return new Promise((resolve) => resolve(null)).then(() => {
      this.token = null;
      this.deleteAPICredentials();
    });
  }
}

export default AuthStore;
