import clsx from 'clsx';
import ProfileName from 'profiles/ProfileName/ProfileName';
import ProfileParentSearch from 'profiles/ProfileParentSearch/ProfileParentSearch';
import React from 'react';
import { Profile } from 'utils/types/Profile';

import styles from './ProfileParent.module.scss';

interface ProfileParentProps {
  handleParent: (parent: Profile | null) => void;
  profile: Profile | null;
}

const ProfileParent = ({ handleParent, profile }: ProfileParentProps): JSX.Element => {
  return (
    <div className={styles.root}>
      {profile && <ProfileName {...profile} />}
      <div className={clsx(styles.actions, { [styles.withPadding]: !!profile })}>
        {profile && (
          <button type="button" className="btn-mini-reverted-error" onClick={() => handleParent(null)}>
            Remove
          </button>
        )}
        <ProfileParentSearch profile={profile} handleParent={handleParent} />
      </div>
    </div>
  );
};

export default ProfileParent;
