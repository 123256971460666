export const paths = {
  annotator: (id: string): string => `/annotators/${id}`,
  annotators: '/annotators',
  feed: '/feed',
  index: '/',
  login: '/login',
  misclassifications: '/misclassifications',
  misclassificationsAuthChecker: '/misclassifications/init',
  misclassificationsNoAccess: '/misclassifications/no-access',
  misclassificationsSession: (assignmentId: string): string => `/misclassifications/assignments/${assignmentId}`,
  profiles: '/profiles',
  profileType: (profileType: string): string => `/profiles/${profileType}`,
};
