import { ChangeEvent, KeyboardEvent } from 'react';
import { FieldValues } from 'react-hook-form';
import clsx from 'clsx';

import inputStyles from '../Input/Input.module.scss';
import baseStyles from '../base.module.scss';
import { InputProps, ReactHookFormCore } from '../types';

interface RHFInputProps<T> extends Omit<InputProps, 'name' | 'error'>, ReactHookFormCore<T> {}

function RHFInput<T extends FieldValues = FieldValues>({
  value,
  onChange,
  className = '',
  labelClassName = '',
  label,
  secondaryLabel,
  name,
  type,
  readOnly,
  min,
  step,
  register,
  registerOptions = {},
  errors,
  autoCompleteOff,
  placeholder,
  startIcon,
  endIcon,
  hiddenLabel = false,
  onKeyDown,
}: RHFInputProps<T>): JSX.Element {
  const handleChange = (value: string) => {
    onChange?.(value);
  };

  const controlledProps = onChange
    ? { value, onChange: (e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value) }
    : {};

  const error =
    errors && errors[name]
      ? errors[name].type === 'required'
        ? `${label} field is required`
        : errors[name].message
      : '';

  return (
    <div className={clsx(baseStyles.field, className, { [baseStyles.error]: error })}>
      <label
        className={clsx(baseStyles.label, labelClassName, { [baseStyles.hiddenLabel]: hiddenLabel })}
        htmlFor={name}
      >
        {label}
        {secondaryLabel && <span className={baseStyles.secondaryLabel}>{secondaryLabel}</span>}
      </label>
      <div className={baseStyles.inputBox}>
        {startIcon && <div className={clsx(inputStyles.iconBox, inputStyles.startIconBox)}>{startIcon}</div>}
        <input
          id={name}
          aria-invalid={error ? 'true' : 'false'}
          type={type}
          className={baseStyles.input}
          autoComplete={autoCompleteOff ? 'off' : 'on'}
          placeholder={placeholder}
          readOnly={readOnly}
          min={min}
          step={step}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e)}
          {...register(name, registerOptions)}
          {...controlledProps}
        />
        {endIcon && <div className={clsx(inputStyles.iconBox, inputStyles.endIconBox)}>{endIcon}</div>}
      </div>
      {error && (
        <p className={baseStyles.errorMessage} data-cy={`${name}-errorMessage`}>
          {error}
        </p>
      )}
    </div>
  );
}

export default RHFInput;
