import { dataWarehousingApi } from 'api';

import {
  AnnotatorAssignmentDashboardReturn,
  AnnotatorAssignmentReturn,
  AnnotatorWizardDto,
  AnnotatorWizardReturn,
  AnnotatorReturn,
  CreateAnnotatorAssignmentDto,
  CreateAnnotatorDto,
  FetchAnnotatorAssignmentsReturn,
  FetchAnnotatorsReturn,
  FetchItemCategoriesReturn,
} from './types';

export const fetchAnnotators = (pageSize: number, pageNumber: number, id?: string): FetchAnnotatorsReturn => {
  return dataWarehousingApi.get('/annotators', {
    params: {
      ...(id && { id }),
      pageSize,
      pageNumber,
    },
  });
};

export function createAnnotator(data: CreateAnnotatorDto): AnnotatorReturn {
  return dataWarehousingApi.put('/annotators', { data });
}

export function deleteAnnotator(id: string): AnnotatorReturn {
  return dataWarehousingApi.delete(`/annotators/${id}`);
}

export const fetchAnnotatorAssignments = (
  pageSize: number,
  pageNumber: number,
  annotatorId?: string
): FetchAnnotatorAssignmentsReturn => {
  return dataWarehousingApi.get('/annotators/assignments', {
    params: { pageSize, pageNumber, annotatorId },
  });
};

export function createAnnotatorAssignment(data: CreateAnnotatorAssignmentDto): AnnotatorAssignmentReturn {
  return dataWarehousingApi.put('/annotators/assignments', { data });
}

export const fetchAnnotatorAssignmentDashboard = (
  pageSize: number,
  pageNumber: number,
  id?: string
): AnnotatorAssignmentDashboardReturn => {
  return dataWarehousingApi.get('/annotators/assignments/dashboard', {
    params: { ...(id && { annotatorId: id }), pageSize, pageNumber },
  });
};

export function createAnnotatorWizard(data: AnnotatorWizardDto): AnnotatorWizardReturn {
  return dataWarehousingApi.post('/annotators/assignments/wizard', { ...data, name: data.name || null });
}

export function fetchItemCategories(): FetchItemCategoriesReturn {
  return dataWarehousingApi.get('/items/categories');
}
