import { useState } from 'react';
import clsx from 'clsx';
import { useStores } from 'RootStore';

import styles from './MisclassificationsManual.module.scss';
import manual1 from './images/manual1.png';
import manual2 from './images/manual2.png';
import manual3 from './images/manual3.png';
import manual4 from './images/manual4.png';

const images: ManualImageDetails[] = [
  { src: manual1, alt: 'read the article' },
  { src: manual2, alt: 'select a category' },
  { src: manual3, alt: 'confirm your choice' },
  { src: manual4, alt: 'mark the article' },
];

interface ManualImageDetails {
  src: string;
  alt: string;
}

interface MisclassificationsManualProps {
  toggleManual: () => void;
}

const MisclassificationsManual = ({ toggleManual }: MisclassificationsManualProps): JSX.Element => {
  const { misclassificationsStore } = useStores();
  const { allAssignmentsCount } = misclassificationsStore;

  const [currentImage, setCurrentImage] = useState<ManualImageDetails>(images[0]);

  const changePage = (pageNumber: number): void => {
    setCurrentImage(images[pageNumber]);
  };

  return (
    <div className={styles.root}>
      <div className={styles.manualContent}>
        <div className={styles.main}>
          <h1 className={styles.header}>Welcome to review page</h1>
          <img src={currentImage.src} className={styles.image} alt={currentImage.alt} />
          <div className={styles.buttonsBox}>
            <div className={styles.buttons}>
              {images.map((image, index) => {
                return (
                  <button
                    key={index}
                    className={clsx('btn-page-number', styles.buttonNumber, {
                      [styles.selectedButton]: image === currentImage,
                    })}
                    onClick={() => changePage(index)}
                  >
                    {index + 1}
                  </button>
                );
              })}
            </div>
          </div>
          <div className={styles.bottomContent}>
            <span>{allAssignmentsCount} articles to review</span>
            <button className={clsx('btn-primary', styles.startButton)} onClick={toggleManual}>
              Get started
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MisclassificationsManual;
