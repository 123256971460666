declare global {
  interface Window {
    env: { [key: string]: string };
  }
}

export type Env = {
  appEnvironment: string;
  appVersion: string;
  backendUrl: string;
  backendDataWarehousingUrl: string;
  backendTwitterUrl: string;
  publicUrl: string;
  secretKey: string;
};

function fetchEnv(name: string, defaultValue = '') {
  return process.env[`REACT_APP_${name}`] ?? (window['env'] ? window['env'][name] : null) ?? defaultValue;
}

const env: Env = {
  appEnvironment: fetchEnv('APP_ENVIRONMENT'),
  appVersion: fetchEnv('APP_VERSION'),
  backendUrl: fetchEnv('BASE_API_URL'),
  backendDataWarehousingUrl: fetchEnv('BASE_DATAWAREHOUSING_URL'),
  backendTwitterUrl: fetchEnv('BASE_TWITTER_URL'),
  publicUrl: fetchEnv('PUBLIC_URL'),
  secretKey: fetchEnv('TWITTER_SECRET_KEY'),
};

export default env;
