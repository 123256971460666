import { BrowserRouter, Routes, Route } from 'react-router-dom';
import env from 'utils/env';

import AuthChecker from './AuthChecker';
import BasicAuthChecker from './BasicAuthChecker';
import ScrollToTop from './ScrollToTop';
import { Route as RouteType } from './models';
import { routes } from './routes';

function Router(): JSX.Element {
  return (
    <BrowserRouter basename={env.publicUrl}>
      <ScrollToTop />
      <Routes>
        {Object.entries(routes).map(([key, route]: [string, RouteType]) => (
          <Route
            key={key}
            path={route.path}
            element={
              route.isMisclassifications ? (
                <BasicAuthChecker isPublic={route.public}>{route.element}</BasicAuthChecker>
              ) : (
                <AuthChecker isPublic={route.public}>{route.element}</AuthChecker>
              )
            }
          />
        ))}
        <Route path="*" element={<AuthChecker isInvalid />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
