import { useStores } from 'RootStore';
import { observer } from 'mobx-react-lite';
import Confirm from 'theme/atoms/confirm';
import Modal from 'theme/atoms/modal';

interface MarkAsSpamModalProps {
  confirmSpam: (isSpam: boolean) => void;
}

const MarkAsSpamModal = ({ confirmSpam }: MarkAsSpamModalProps): JSX.Element => {
  const { misclassificationsStore } = useStores();
  const { closeMarkAsSpamModal, isMarkAsSpamModalOpen, isSubmitting } = misclassificationsStore;

  return (
    <Modal isOpen={isMarkAsSpamModalOpen} close={closeMarkAsSpamModal} title="Mark as Spam">
      <Confirm
        yesText="Confirm"
        action={() => confirmSpam(true)}
        close={closeMarkAsSpamModal}
        submitting={isSubmitting}
        question="Are you sure that you want to mark this item as spam?"
      />
    </Modal>
  );
};

export default observer(MarkAsSpamModal);
