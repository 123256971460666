import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { toDate, fromUnixTime, isBefore } from 'date-fns';
import qs from 'qs';
import { Auth } from 'auth';
import env from 'utils/env';

import { getAPICredentials, getBasicAPICredentials } from './utils';

const createApi = (baseURL: string, secretKey?: string): AxiosInstance => {
  const apiInstance = axios.create({
    baseURL,
    paramsSerializer: function (params) {
      return qs.stringify(params, {
        arrayFormat: 'repeat',
      });
    },
  });

  apiInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      return new Promise((resolve) => {
        const apiCredentials = getAPICredentials();
        const basicAuthToken = getBasicAPICredentials();

        if (basicAuthToken) {
          resolve({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Basic ${basicAuthToken}`,
            },
          });
        } else if (apiCredentials) {
          const parsedApiCredentials: Auth = JSON.parse(apiCredentials);
          const isRefreshingToken = config.url?.includes('/auth');
          const isTokenValid =
            parsedApiCredentials?.user && isBefore(toDate(new Date()), fromUnixTime(parsedApiCredentials.user.exp));

          const addProperAuthHeader = secretKey
            ? { 'secret-key': secretKey }
            : {
                Authorization: (parsedApiCredentials && `Bearer ${parsedApiCredentials.token}`) || '',
              };

          if (isRefreshingToken || isTokenValid) {
            resolve({
              ...config,
              headers: {
                ...config.headers,
                ...addProperAuthHeader,
              },
            });
          } else {
            resolve({
              ...config,
            });
          }
        } else {
          resolve({
            ...config,
          });
        }
      });
    },
    (err: unknown) => Promise.reject(err)
  );

  apiInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
      // eslint-disable-next-line no-console
      console.error('Error: ', error);
      return Promise.reject(error);
    }
  );

  return apiInstance;
};

export const api = createApi(env.backendUrl);
export const dataWarehousingApi = createApi(env.backendDataWarehousingUrl);
export const twitterApi = createApi(env.backendTwitterUrl, env.secretKey);
