import { useStores } from 'RootStore';
import { metadataSectionKinds } from 'metadata';
import { Dispatch, SetStateAction } from 'react';
import { List } from 'theme/atoms/multiselect';

import handleRequestError from '../handleRequestError';
import includes from '../includes';

interface UsePaginatedMetadataListsProps {
  lists: List[];
  setLists: Dispatch<SetStateAction<List[]>>;
  onUpdate?: (lists: List[]) => void;
}

type HandleOpen = (title: string) => void;
type HandleClose = (title: string) => void;
type HandleReset = (title: string) => void;
type HandleItemChange = (listToChange: List, itemId: string) => void;
type HandleNextPage = (args: { listToChange: List; searchText: string }) => void;
type HandleSearchValueChange = (listToChange: List, searchValue: string) => void;

interface UsePaginatedMetadataListsReturn {
  handleOpen: HandleOpen;
  handleClose: HandleClose;
  handleReset: HandleReset;
  handleItemToggle: HandleItemChange;
  handleNextPage: HandleNextPage;
  handleSearchValueChange: HandleSearchValueChange;
}

const usePaginatedMetadataLists = ({
  lists,
  setLists,
  onUpdate,
}: UsePaginatedMetadataListsProps): UsePaginatedMetadataListsReturn => {
  const { metadataStore } = useStores();
  const { fetchMetadata } = metadataStore;

  const getClosedList = (list: List): List => {
    if (list.type === 'dynamic') {
      return {
        ...list,
        open: false,
        items: list.items.filter((item) => item.checked),
        fetchedItems: [],
        error: null,
        fetched: false,
        isLoading: false,
        isLoadingNextPage: true,
        page: 1,
      };
    } else {
      return { ...list, open: false, items: list.items.map((item) => ({ ...item, hide: false })) };
    }
  };

  const handleOpen = (title) => {
    setLists(lists.map((list) => (list.title === title ? { ...list, open: true } : { ...getClosedList(list) })));
  };

  const handleClose = (title: string) => {
    setLists(lists.map((list) => (list.title === title ? { ...getClosedList(list) } : list)));
  };

  const handleReset = (title: string) => {
    setLists(
      lists.map((list) =>
        list.title === title ? { ...list, items: list.items.map((item) => ({ ...item, checked: false })) } : list
      )
    );
  };

  const handleItemToggle: HandleItemChange = (listToChange, itemId) => {
    if (listToChange.type === 'dynamic') {
      const newLists: List[] = lists.map((list) => {
        if (list.title === listToChange.title) {
          const isInFetchedItems = list.fetchedItems.find((item) => item.id === itemId) !== undefined;

          const newItems = isInFetchedItems
            ? list.items.map((item) => (item.id === itemId ? { ...item, checked: !item.checked } : item))
            : list.items.filter((item) => item.id !== itemId);

          return {
            ...list,
            items: newItems,
          };
        }

        return list;
      });

      onUpdate && onUpdate(newLists);
      setLists(newLists);
    } else {
      const newLists = lists.map((list) =>
        list.title === listToChange.title
          ? {
              ...list,
              items: list.items.map((item) => (item.id === itemId ? { ...item, checked: !item.checked } : item)),
            }
          : list
      );
      onUpdate && onUpdate(newLists);
      setLists(newLists);
    }
  };

  const handleNextPage: HandleNextPage = ({ listToChange, searchText }) => {
    setLists(lists.map((list) => (list.title === listToChange.title ? { ...list, isLoadingNextPage: true } : list)));

    fetchMetadata({
      metadataTypes: listToChange.metadataType ? [listToChange.metadataType] : [],
      kinds: listToChange.kind ? [listToChange.kind] : [],
      searchText,
      page: listToChange.page,
    })
      .then((sections) => {
        setLists((currentLists) =>
          currentLists.map((list) => {
            if (list.title === listToChange.title) {
              const newFetchedItems = sections.map((section) => ({
                ...section,
                checked: false,
                hide: false,
              }));

              const newFetchedItemsWithoutPreviouslyChecked = newFetchedItems.filter((item) => {
                const isChecked = list.items.findIndex((it) => it.id === item.id && it.checked) !== -1;
                return !isChecked;
              });

              return {
                ...list,
                isLoadingNextPage: false,
                fetched: true,
                page: list.page !== undefined ? list.page + 1 : undefined,
                items: [...list.items, ...newFetchedItemsWithoutPreviouslyChecked],
                fetchedItems: [...list.fetchedItems, ...newFetchedItems],
              };
            }

            return list;
          })
        );
      })
      .catch((error) => {
        setLists((currentLists) =>
          currentLists.map((list) =>
            list.title === listToChange.title
              ? {
                  ...list,
                  isLoadingNextPage: false,
                  fetched: true,
                  error: handleRequestError({ error, defaultMessage: "Couldn't fetch data. Try again later" }),
                }
              : list
          )
        );
      });
  };

  const handleSearchValueChange: HandleSearchValueChange = (listToChange, searchValue) => {
    if (listToChange.type === 'static') {
      const searchedWords = searchValue.toLowerCase().split(' ');

      setLists(
        lists.map((list) => {
          return {
            ...list,
            items: list.items.map((item) => {
              const found: boolean[] = [];

              searchedWords.map((s) => {
                if (item.title?.toLowerCase().search(s) !== undefined && item.title?.toLowerCase().search(s) > -1) {
                  found.push(true);
                }
              });

              return { ...item, hide: found.length !== searchedWords.length };
            }),
          };
        })
      );
    } else {
      if (listToChange.fetched) {
        setLists(
          lists.map((list) => (list.title === listToChange.title ? { ...list, isLoading: true, page: 1 } : list))
        );

        fetchMetadata({
          metadataTypes: listToChange.metadataType ? [listToChange.metadataType] : [],
          kinds: includes(metadataSectionKinds, listToChange.kind) ? [listToChange.kind] : [],
          searchText: searchValue,
        })
          .then((sections) => {
            setLists((currentLists) =>
              currentLists.map((list) => {
                if (list.title === listToChange.title) {
                  const checkedItems = list.items.filter((item) => item.checked);

                  const newFetchedItems = sections.map((section) => ({
                    ...section,
                    checked: false,
                    hide: false,
                  }));

                  const newFetchedItemsWithoutPreviouslyChecked = newFetchedItems.filter((item) => {
                    const isChecked = checkedItems.findIndex((it) => it.id === item.id && it.checked) !== -1;
                    return !isChecked;
                  });

                  return {
                    ...list,
                    isLoading: false,
                    fetched: true,
                    page: list.page !== undefined ? list.page + 1 : undefined,
                    items: [...checkedItems, ...newFetchedItemsWithoutPreviouslyChecked],
                    fetchedItems: [...newFetchedItems],
                  };
                }

                return list;
              })
            );
          })
          .catch((error) => {
            const newLists: List[] = lists.map((list) =>
              list.title === listToChange.title
                ? {
                    ...list,
                    isLoading: false,
                    fetched: true,
                    error: handleRequestError({ error, defaultMessage: "Couldn't fetch data. Try again later" }),
                  }
                : list
            );

            setLists(newLists);
          });
      }
    }
  };

  return { handleOpen, handleClose, handleReset, handleItemToggle, handleNextPage, handleSearchValueChange };
};

export default usePaginatedMetadataLists;
