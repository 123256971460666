import clsx from 'clsx';
import React from 'react';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  bottom?: boolean;
  className?: string;
  left?: boolean;
  text: string;
  width?: number;
  wrap?: boolean;
}

const Tooltip = ({ className = '', text, bottom = false, left = false, width, wrap }: TooltipProps): JSX.Element => {
  return (
    <div
      className={clsx(styles.root, className, { [styles.left]: left, [styles.bottom]: bottom, [styles.wrap]: wrap })}
      style={{ width }}
    >
      <div className={clsx(styles.arrow, { [styles.arrowLeft]: left, [styles.arrowBottom]: bottom })} />
      {text}
    </div>
  );
};

export default Tooltip;
