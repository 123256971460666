import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'RootStore';
import { ReactComponent as Logo } from 'theme/svg/logo.svg';

import LoginForm from './LoginForm';
import styles from './Login.module.scss';
import { FormValues } from './models';

function Login(): JSX.Element {
  const { authStore } = useStores();
  const { login } = authStore;
  const [error, setError] = useState<{ message: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values: FormValues) => {
    setError(null);
    setIsLoading(true);

    login(values.email, values.password)
      .then(() => {
        navigate('/feed');
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  return (
    <div className={styles.login}>
      <div className={styles.loginCard}>
        <div className={styles.header}>
          <Logo className={styles.logo} />
        </div>
        <div className={styles.body}>
          <LoginForm onSubmit={handleSubmit} error={error} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
}

export default Login;
