import clsx from 'clsx';
import React from 'react';

import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  children?: React.ReactNode;
  className?: string;
  small?: boolean;
  title: string;
}

const PageHeader = ({ children, className = '', small = false, title }: PageHeaderProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className, { [styles.small]: small })}>
      {small ? <h2>{title}</h2> : <h1>{title}</h1>}
      {children}
    </div>
  );
};

export default PageHeader;
