import clsx from 'clsx';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  className?: string;
  small?: boolean;
}

function Spinner({ className = '', small = false }: SpinnerProps): JSX.Element {
  return <div className={clsx(styles.spinner, className, { [styles.small]: small })} />;
}

export default Spinner;
