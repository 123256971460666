const STORAGE_PREFIX = 'bvmsports__';

const getStorageKey = (key: string): string => {
  return `${STORAGE_PREFIX}${key}`;
};

// eslint-disable-next-line
const saveToStorage = (key: string, data: any): any => {
  window.sessionStorage.setItem(getStorageKey(key), JSON.stringify(data));
  return data;
};

// eslint-disable-next-line
const getFromStorage = (key: string): any | null => {
  const stringified = window.sessionStorage.getItem(getStorageKey(key)) as string;
  return stringified === null ? null : JSON.parse(stringified);
};

const removeFromStorage = (key: string): void => {
  window.sessionStorage.removeItem(key);
};

const removeItemStartWith = (key: string): number => {
  const allKey = Object.keys(window.sessionStorage);
  let removed = 0;
  allKey.forEach((item: string) => {
    if (item.startsWith(key)) {
      removeFromStorage(item);
      removed++;
    }
  });
  return removed;
};

const clearAllCache = (): void => {
  removeItemStartWith(STORAGE_PREFIX);
};

export { getStorageKey, saveToStorage, removeFromStorage, getFromStorage, clearAllCache, removeItemStartWith };
