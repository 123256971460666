import { fromUnixTime, isBefore } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { useStores } from 'RootStore';

import { routes } from './routes';

interface AuthCheckerProps {
  children?: JSX.Element;
  isPublic?: boolean;
  isInvalid?: boolean;
}

function AuthChecker({ children, isPublic = false, isInvalid = false }: AuthCheckerProps): JSX.Element {
  const { authStore } = useStores();

  const accountIsAuthenticated =
    authStore.token && authStore.token.user && isBefore(new Date(), fromUnixTime(authStore.token.user.exp));

  if (
    (!authStore.loading && accountIsAuthenticated && isPublic) ||
    (!authStore.loading && accountIsAuthenticated && isInvalid)
  ) {
    return <Navigate to={routes.feed.path} />;
  } else if (
    (!authStore.loading && !accountIsAuthenticated && !isPublic) ||
    (!authStore.loading && !accountIsAuthenticated && isInvalid)
  ) {
    return <Navigate to={routes.login.path} />;
  } else if (children) {
    return children;
  } else {
    throw new Error('Couldn`t finy any route to redirect');
  }
}

const ObservedAuthChecker = observer(AuthChecker);

export default ObservedAuthChecker;
