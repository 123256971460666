import clsx from 'clsx';
import { useState } from 'react';
import { ReactComponent as CloseIcon } from 'theme/svg/close.svg';

import styles from './Navigation.module.scss';

function Navigation(): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={clsx(styles.navigation, { [styles.active]: isOpen })}>
      <p className={styles.shortkeysText}>SHORTKEYS</p>
      <div className={styles.keyItem}>
        <div className={styles.key}>J</div>
        <p>Next post</p>
      </div>
      <div className={styles.keyItem}>
        <div className={styles.key}>K</div>
        <p>Previous post</p>
      </div>

      <button onClick={() => setIsOpen(false)} className={styles.closeBtn}>
        <CloseIcon className={styles.closeIcon} />
      </button>
    </div>
  );
}

export default Navigation;
