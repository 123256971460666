import rssLogo from 'theme/svg/rss.svg';
import { Post } from 'utils/types/Post';

import styles from './RSSPostHeader.module.scss';

interface RSSPostHeaderProps {
  post: Post;
}

function RSSPostHeader({ post }: RSSPostHeaderProps): JSX.Element {
  let time = '';

  if (post.publicationDate) {
    const currentTime = new Date().getTime();
    const postTime = new Date(post.publicationDate).getTime();

    const diff = currentTime - postTime;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    let days = 0;

    if (hours > 24) days = Math.floor(hours / 24);

    time = days === 0 ? `${hours}h ago` : `${days}d ago`;
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerContent}>
        {post.logoUrl && <img className={styles.logo} src={post.logoUrl} alt="Logo" />}
        <div>
          <div>
            <h3 className={styles.serviceName}>{post.feedTitle}</h3>
          </div>
          <div className={styles.postInformationBox}>
            {post.author && (
              <>
                <p>by {post.author}</p>
                <div className={styles.dot} />
              </>
            )}
            <p>{time}</p>
          </div>
        </div>
      </div>
      <img src={rssLogo} alt="Logo RSS" className={styles.rssLogo} />
    </div>
  );
}

export default RSSPostHeader;
