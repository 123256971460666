import { useStores } from 'RootStore';
import { observer } from 'mobx-react-lite';
import Spinner from 'theme/atoms/loaders/Spinner';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import scrollToTop from 'utils/scrollToTop';
import { Metadata, MetadataSection } from 'utils/types/Metadata';

import Navigation from './Navigation/Navigation';
import Post from './Post/Post';
import styles from './PostsList.module.scss';
import useKeyboardNavigation from './useKeyboardNavigation';

function PostsList(): JSX.Element {
  const { postsStore } = useStores();
  const {
    posts,
    fetchPosts,
    isLoadingPosts,
    postsError,
    shouldFetchNextPage,
    arePostsFetched,
    changeFilterClickedOnPost,
  } = postsStore;

  const handleLabelClick = (metadata: MetadataSection | Metadata, listTitle: string): void => {
    const filter = { id: metadata.id, title: metadata.title, listTitle };
    changeFilterClickedOnPost(filter);
    scrollToTop();
  };

  const { changeLastElement, isLoading } = useInfiniteScroll({
    callback: () => fetchPosts(),
    shouldRunCallback: shouldFetchNextPage,
  });
  const { selectedPost } = useKeyboardNavigation({ posts });

  return (
    <div className={styles.posts}>
      <Navigation />
      {arePostsFetched && isLoadingPosts && (
        <div className={styles.spinnerAboveOtherContent}>
          <Spinner />
        </div>
      )}
      {postsError ? (
        <div className={styles.errorNoDataLoaded}>
          Something went wrong. Please check your internet connection and{' '}
          <span onClick={() => fetchPosts(true)}>click here to reload</span> or try again later.
        </div>
      ) : arePostsFetched ? (
        <div>
          <div className={styles.list}>
            {posts.length ? (
              posts.map((post, i) => (
                <Post
                  ref={posts.length === i + 1 ? changeLastElement : undefined}
                  key={post.id}
                  post={post}
                  selected={selectedPost === post.id}
                  onLabelClick={handleLabelClick}
                />
              ))
            ) : (
              <div className={styles.noResults}>No results</div>
            )}
          </div>
          {isLoading && (
            <div className={styles.spinnerBox}>
              <Spinner />
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

const ObservedPostsList = observer(PostsList);

export default ObservedPostsList;
