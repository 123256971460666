import clsx from 'clsx';

import styles from './Skeleton.module.scss';

interface SkeletonProps {
  width?: number | string;
  height?: number | string;
  className?: string;
}

function Skeleton({ width = '100%', height = '20px', className = '' }: SkeletonProps): JSX.Element {
  return (
    <div
      style={{
        width,
        height,
      }}
      className={clsx(styles.skeleton, className)}
    />
  );
}

export default Skeleton;
