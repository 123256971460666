import { useStores } from 'RootStore';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import Modal from 'theme/atoms/modal';
import Multiselect, { List } from 'theme/atoms/multiselect';
import { filtersToBeDisplayedInFeedAndFollowed } from 'utils/constants';
import usePaginatedMetadataLists from 'utils/hooks/usePaginatedMetadataLists';
import scrollToTop from 'utils/scrollToTop';
import { Filter } from 'utils/types/Filter';

import styles from './ProfilesFilters.module.scss';

interface ProfilesFiltersProps {
  lists: List[];
  setLists: Dispatch<SetStateAction<List[]>>;
}

function ProfilesFilters({ lists, setLists }: ProfilesFiltersProps): JSX.Element {
  const { profilesStore } = useStores();
  const { updateFilters, filterClickedOnProfile, updateFiltersSource } = profilesStore;
  const { profileType } = useParams();

  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);

  const handleFiltersUpdate = (newLists: List[]): void => {
    const listsItems = newLists.flatMap((list) => list.items.filter((item) => item.checked));
    const newFilters: Filter[] = listsItems.map((item) => ({
      id: item.id,
      title: item.title,
    }));

    const shouldScrollToTop = updateFilters(newFilters, profileType ?? '');
    if (shouldScrollToTop) scrollToTop();
    updateFiltersSource('main');
  };

  const { handleItemToggle, handleNextPage, handleSearchValueChange } = usePaginatedMetadataLists({
    lists,
    setLists,
    onUpdate: handleFiltersUpdate,
  });

  useEffect(() => {
    if (filterClickedOnProfile) {
      const newLists = lists.map((list) => {
        if (list.title === filterClickedOnProfile.listTitle) {
          const isInItems = list.items.findIndex((item) => item.id === filterClickedOnProfile.id) !== -1;

          return {
            ...list,
            items: isInItems
              ? list.items.filter((item) => item.id !== filterClickedOnProfile.id)
              : [
                  ...list.items,
                  {
                    id: filterClickedOnProfile.id,
                    title: filterClickedOnProfile.title,
                    checked: true,
                    hide: false,
                  },
                ],
          };
        }

        return list;
      });

      setLists(newLists);

      const listsItems = newLists.flatMap((list) => list.items.filter((item) => item.checked));
      const newFilters: Filter[] = listsItems.map((item) => ({
        id: item.id,
        title: item.title,
      }));

      const shouldScrollToTop = updateFilters(newFilters, profileType ?? '');
      if (shouldScrollToTop) scrollToTop();
      updateFiltersSource('main');
    }
  }, [filterClickedOnProfile]);

  const getClosedList = (list: List): List => {
    return { ...list, open: false, items: list.items.map((item) => ({ ...item, hide: false })) };
  };

  const onDropdownOpen = (title: string): void => {
    setLists(lists.map((list) => (list.title === title ? { ...list, open: true } : { ...getClosedList(list) })));
  };

  const onDropdownClose = (title: string): void => {
    setLists(lists.map((list) => (list.title === title ? { ...getClosedList(list) } : list)));
  };

  const handleReset = (title: string): void => {
    const newLists = lists.map((list) =>
      list.title === title ? { ...list, items: list.items.map((item) => ({ ...item, checked: false })) } : list
    );
    setLists(newLists);
    handleFiltersUpdate(newLists);
  };

  return (
    <div className={styles.followedFilters}>
      <span className={styles.followedFiltersLabel}>Filters:</span>
      <div className={styles.lists}>
        {lists
          .filter((list: List) => list.title !== 'Level')
          .map((list: List) => (
            <Multiselect
              className={styles.filterMultiselect}
              key={list.title}
              list={list}
              onDropdownOpen={onDropdownOpen}
              onDropdownClose={onDropdownClose}
              onNextPage={handleNextPage}
              onSearchValueChange={handleSearchValueChange}
              onItemToggle={handleItemToggle}
              onReset={handleReset}
            />
          ))}
      </div>

      <Modal isOpen={isMoreModalOpen} close={() => setIsMoreModalOpen(false)} title="More filters">
        <div className={clsx(styles.followedFilters__lists, styles.filtersListsInModal)}>
          {lists
            .filter((list: List) => !list.kind || !filtersToBeDisplayedInFeedAndFollowed.includes(list.kind))
            .map((list: List) => (
              <Multiselect
                key={list.title}
                list={list}
                onDropdownOpen={onDropdownOpen}
                onDropdownClose={onDropdownClose}
                onNextPage={handleNextPage}
                onSearchValueChange={handleSearchValueChange}
                onItemToggle={handleItemToggle}
                onReset={handleReset}
              />
            ))}
        </div>
      </Modal>
    </div>
  );
}

export default observer(ProfilesFilters);
