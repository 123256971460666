import { useStores } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Checkbox from 'theme/atoms/inputs/Checkbox';
import Input from 'theme/atoms/inputs/Input';
import { ReactComponent as DropdownIcon } from 'theme/svg/dropdown.svg';
import { ReactComponent as Logo } from 'theme/svg/logo.svg';
import { ReactComponent as LogoutIcon } from 'theme/svg/logout.svg';
import { ReactComponent as SearchIcon } from 'theme/svg/search.svg';
import { ReactComponent as UserIcon } from 'theme/svg/user.svg';
import { IS_PROD } from 'utils/constants';
import env from 'utils/env';

import styles from './Layout.module.scss';
import LayoutSideMenu from './LayoutSideMenu';

interface LayoutProps {
  children: React.ReactNode | React.ReactNode[];
  searchConfig?: {
    value: string;
    onChange: (value: string) => void;
    view: 'posts' | 'profiles';
  };
}

const Layout = ({ children, searchConfig }: LayoutProps): JSX.Element => {
  const { authStore } = useStores();
  const { isAuthenticated, isProdUiMode, logout, toggleProdUiMode, token } = authStore;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const username = token?.user.wordpressUsername ?? '';

  const menuRef = useRef<HTMLDivElement>(null);
  const menuBtnRef = useRef<HTMLButtonElement>(null);

  const toggleMenu = (): void => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.root}>
      <aside className={styles.sidebar}>
        <Link to={paths.feed} className={styles.logoLink}>
          <Logo className={styles.logo} />
        </Link>

        {isAuthenticated && (
          <div className={styles.sidebarMain}>
            {searchConfig && (
              <Input
                className={styles.search}
                name="search"
                label="Search"
                hiddenLabel
                type="search"
                placeholder={searchConfig.view === 'profiles' ? 'Search feeds' : 'Search posts'}
                endIcon={<SearchIcon />}
                value={searchConfig.value}
                onChange={(value: string) => searchConfig.onChange(value)}
              />
            )}

            <LayoutSideMenu hasTopMargin={!searchConfig} />
          </div>
        )}

        {isAuthenticated && (
          <button className={styles.logout} onClick={logout}>
            <LogoutIcon />
            Logout
          </button>
        )}
      </aside>

      <main className={styles.main}>
        {children}
        {isAuthenticated && (
          <div className={styles.menuBox}>
            <div className={styles.appVersion}>
              Web version:
              <span>{env.appVersion}</span>
            </div>
            {!IS_PROD && (
              <Checkbox
                name="prodUI"
                label="Prod UI mode"
                onChange={toggleProdUiMode}
                className={styles.prodUiCheckbox}
                checked={isProdUiMode}
              />
            )}
            <span className={styles.menuBoxUsername}>Hi, {username}</span>
            <div className={styles.menuBoxContent}>
              <button className={styles.menuBtn} onClick={toggleMenu} ref={menuBtnRef}>
                <div className={styles.userIconBox}>
                  <UserIcon />
                </div>
                <DropdownIcon className={clsx(styles.arrowIcon, { [styles.arrowIconClosed]: !isMenuOpen })} />
              </button>
              <div className={clsx(styles.menu, { [styles.menuOpen]: isMenuOpen })} ref={menuRef}>
                <ul>
                  <li>
                    <button onClick={logout}>Logout</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {isMenuOpen && <div className={styles.lockedBgForMenu} />}
      </main>
    </div>
  );
};

export default observer(Layout);
