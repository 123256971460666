import { RootStore } from 'RootStore';
import { saveBasicAPICredentials, removeBasicAPICredentials, getFromStorage, saveToStorage } from 'api';
import debounce from 'lodash.debounce';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  assignmentsPageSize,
  categoryOrder,
  categorySource,
  DEFAULT_TARGET_CATEGORIES,
  POSSIBLE_VALUES_SESSION_STORAGE_NAME,
} from 'utils/constants';
import { decodeBasicAuthToken } from 'utils/decodeBasicAuthToken';
import { splitByUnderscore } from 'utils/generateHash';
import { isCategorySpamOrConfusing } from 'utils/isCategorySpamOrConfusing';
import { uuid4 } from 'uuid4';

import * as requests from './misclassificationsRequests';
import {
  Assignment,
  AssignedPost,
  AssignmentsResponse,
  Category,
  CategoryPossibleValuesResponse,
  CheckboxValue,
  Correction,
  CustomCategoryName,
  FetchCategoryPossibleValuesArgs,
  ItemCategory,
  PossibleValuesResults,
  TargetCategory,
} from './types';

const sortByCategoryOrder = <T extends TargetCategory | Category>(arr: T[]): T[] => {
  return arr.sort((a, b) => categoryOrder.indexOf(a.categoryName) - categoryOrder.indexOf(b.categoryName));
};

class MisclassificationsStore {
  rootStore: RootStore;
  basicAuthToken: string | null = null;

  assignments: Assignment[] = [];
  assignmentsCount = 0;
  allAssignmentsCount = 0;
  remainingAssignmentsCount = 0;
  loadingAssignments = false;

  isMisclassificationsSessionFinished = false;
  currentAssignmentIndex = 0;
  post: AssignedPost | undefined = undefined;
  annotatorId = '';
  pageNumber = 1;

  isItemMarkedAsConfusing = false;
  markAsConfusingTextAreaValue = '';

  isMarkAsSpamModalOpen = false;
  isMarkAsConfusingModalOpen = false;
  isSubmitting = false;

  categories: Category[] = [];
  currentCategory = '';

  checkboxValues: CheckboxValue[] = [];
  selectedCheckboxValues: CheckboxValue[] = [];

  textMatchedKeywords: string[] = [];
  titleMatchedKeywords: string[] = [];

  confusingCategoryId = '';
  spamCategoryId = '';

  allCategoriesPossibleValuesData: PossibleValuesResults = [];
  loadingPossibleValues = false;
  loadingSearchedPossibleValues = false;
  possibleValuesPageNumber = 1;
  query = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get currentCategoryValuesCount(): number {
    return this.allCategoriesPossibleValuesData.find((v) => v.categoryName === this.currentCategory)?.count ?? 0;
  }

  get confusingCorrection(): Correction {
    return { value: 'true', categoryId: this.confusingCategoryId };
  }

  get spamCorrection(): Correction {
    return { value: 'true', categoryId: this.spamCategoryId };
  }

  get loadingInitialData(): boolean {
    return this.loadingAssignments || this.loadingPossibleValues;
  }

  get keywords(): string[] {
    return this.filterByTargetCategories(this.assignments[this.currentAssignmentIndex]).map((ic) =>
      ic.selectedValue.toLowerCase()
    );
  }

  get matchedKeywords(): string[] {
    return Array.from(new Set(this.titleMatchedKeywords.concat(this.textMatchedKeywords))).map((keyword) =>
      keyword.toLowerCase()
    );
  }

  get corrections(): Correction[] {
    return this.selectedCheckboxValues.map((cv) => ({
      value: splitByUnderscore(cv.possibleValue.title),
      categoryId: cv.categoryId,
    }));
  }

  get currentCategorySelectedCheckboxValues(): CheckboxValue[] {
    const filteredItems = this.selectedCheckboxValues.filter((scv) => scv.categoryName === this.currentCategory);

    return Object.values(
      filteredItems.reduce((acc, item) => {
        if (!acc[item.possibleValue.title]) {
          acc[item.possibleValue.title] = { ...item };
        } else {
          acc[item.possibleValue.title] = {
            ...item,
            feedClassification: acc[item.possibleValue.title].feedClassification || item.feedClassification,
            mlClassification: acc[item.possibleValue.title].mlClassification || item.mlClassification,
          };
        }
        return acc;
      }, {})
    );
  }

  setCheckboxValues = (category: string): void => {
    this.currentCategory = category;

    const foundPossibleValues =
      this.allCategoriesPossibleValuesData.find((pv) => pv.categoryName === category)?.results ?? [];
    const foundCategory = this.assignments[this.currentAssignmentIndex].item.itemCategories.find(
      (c) => c.categoryName === category
    );
    const foundCategorySelectedValue = foundCategory?.selectedValue ?? '';
    const foundCategorySource = foundCategory?.source ?? '';
    const foundCategoryId =
      this.assignments[this.currentAssignmentIndex].targetCategories.find((c) => c.categoryName === category)?.id ?? '';

    runInAction(() => {
      this.checkboxValues = foundPossibleValues.map((fpv) => ({
        possibleValue: fpv,
        categoryName: category,
        categoryId: foundCategoryId,
        id: uuid4(),
        feedClassification: foundCategorySelectedValue === fpv.title && foundCategorySource === categorySource.apriori,
        mlClassification: foundCategorySelectedValue === fpv.title && foundCategorySource === categorySource.predicted,
      }));
    });
  };

  setSelectedCheckboxValues = (selectedValue: CheckboxValue): void => {
    this.selectedCheckboxValues = this.selectedCheckboxValues.find(
      (scv) => scv.possibleValue.title === selectedValue.possibleValue.title
    )
      ? this.selectedCheckboxValues.filter((scv) => scv.possibleValue.title !== selectedValue.possibleValue.title)
      : [...this.selectedCheckboxValues, selectedValue];
  };

  decodeAnnotatorId = (token: string): string => {
    return decodeBasicAuthToken(token).id;
  };

  setQuery = (query: string): void => {
    this.query = query;
  };

  setCurrentCategory = (category: string): void => {
    this.currentCategory = category;
  };

  filterByTargetCategories = ({ item, targetCategories }: Assignment): ItemCategory[] => {
    return item.itemCategories.filter((ic) =>
      this.removeSpamAndConfusingCategories(targetCategories).find((tc) => tc.categoryName === ic.categoryName)
    );
  };

  goToNextAssignment = (
    assignmentId: string,
    token: string,
    isMarkingSpam = false,
    navigateToAssignment?: (assignmentId: string) => void
  ): Promise<void> => {
    this.isSubmitting = true;

    const shouldConfusingBeSent = this.isItemMarkedAsConfusing
      ? [...this.corrections, this.confusingCorrection]
      : this.corrections;
    const correctionsToBeSent = isMarkingSpam ? [this.spamCorrection] : shouldConfusingBeSent;

    return requests
      .updatePost(assignmentId, {
        comment: this.markAsConfusingTextAreaValue || null,
        corrections: correctionsToBeSent.filter(
          (c, index, self) => index === self.findIndex((i) => i.value === c.value && i.categoryId === c.categoryId)
        ),
      })
      .then(() => {
        if (this.currentAssignmentIndex % assignmentsPageSize === assignmentsPageSize - 1) {
          this.pageNumber = this.pageNumber + 1;
          this.fetchAssignments(token).then(({ results }) => {
            navigateToAssignment?.(results[0].id);
          });
          this.currentAssignmentIndex = 0;
        } else {
          if (this.remainingAssignmentsCount === 1) this.finishMisclassificationsSession();
          else {
            navigateToAssignment?.(this.assignments[this.currentAssignmentIndex + 1].id);
            this.currentAssignmentIndex = this.currentAssignmentIndex + 1;
            this.post = this.assignments[this.currentAssignmentIndex].item;

            const sortedTargetCategories = sortByCategoryOrder<TargetCategory>(
              this.removeSpamAndConfusingCategories(this.assignments[this.currentAssignmentIndex].targetCategories)
            );

            if (this.currentAssignmentIndex < assignmentsPageSize) {
              this.setCheckboxValues(sortedTargetCategories[0].categoryName);
              this.allCategoriesPossibleValuesData = getFromStorage(POSSIBLE_VALUES_SESSION_STORAGE_NAME);

              this.selectedCheckboxValues = this.filterByTargetCategories(
                this.assignments[this.currentAssignmentIndex]
              ).map((ic) => ({
                possibleValue: { title: ic.selectedValue },
                categoryId:
                  this.assignments[this.currentAssignmentIndex].targetCategories.find(
                    (tc) => tc.categoryName === ic.categoryName
                  )?.id ?? '',
                id: uuid4(),
                categoryName: ic.categoryName,
                feedClassification: ic.source === categorySource.apriori,
                mlClassification: ic.source === categorySource.predicted,
              }));
            }
          }
        }

        this.remainingAssignmentsCount = this.remainingAssignmentsCount - 1;
        this.resetCorrectionsAndTextAreaValue();
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  finishMisclassificationsSession = (): void => {
    this.isMisclassificationsSessionFinished = true;
  };

  resetMisclassificationsSession = (): void => {
    this.currentAssignmentIndex = 0;
    this.isMisclassificationsSessionFinished = false;
  };

  findCustomCategoryId = (assignment: Assignment, categoryName: CustomCategoryName): string => {
    return assignment.targetCategories.find((c) => c.categoryName === categoryName)?.id ?? '';
  };

  fetchAssignments = (token: string): Promise<AssignmentsResponse> => {
    runInAction(() => (this.loadingAssignments = true));

    return requests
      .fetchAssignments({ annotatorId: this.decodeAnnotatorId(token), pageNumber: 1 })
      .then(({ data }) => {
        const { count, results } = data;

        if (!results.filter((r) => r.state === 'Assigned').length) {
          this.finishMisclassificationsSession();
          return data;
        }

        this.assignments = results;
        this.assignmentsCount = count;
        this.post = results[this.currentAssignmentIndex]?.item;

        this.fetchAllCategoriesPossibleValues(results[this.currentAssignmentIndex]).then(() => {
          const displayedCategories = this.getCategoriesToDisplay(
            results[this.currentAssignmentIndex].targetCategories
          );
          this.categories = displayedCategories;
          this.setCurrentCategory(displayedCategories.length > 0 ? displayedCategories[0].categoryName : '');
        });

        this.confusingCategoryId = this.findCustomCategoryId(
          results[this.currentAssignmentIndex],
          DEFAULT_TARGET_CATEGORIES.confusing
        );
        this.spamCategoryId = this.findCustomCategoryId(
          results[this.currentAssignmentIndex],
          DEFAULT_TARGET_CATEGORIES.spam
        );

        return data;
      })
      .finally(() => {
        runInAction(() => (this.loadingAssignments = false));
      });
  };

  openMarkAsConfusingModal = (): void => {
    this.isMarkAsConfusingModalOpen = true;
  };

  closeMarkAsConfusingModal = (): void => {
    this.isMarkAsConfusingModalOpen = false;
  };

  openMarkAsSpamModal = (): void => {
    this.isMarkAsSpamModalOpen = true;
  };

  closeMarkAsSpamModal = (): void => {
    this.isMarkAsSpamModalOpen = false;
  };

  markItemAsConfusing = (): void => {
    this.isItemMarkedAsConfusing = true;
  };

  revertMarkItemAsConfusing = (): void => {
    this.isItemMarkedAsConfusing = false;
    this.markAsConfusingTextAreaValue = '';
  };

  setMarkAsConfusingTextAreaValue = (value: string): void => {
    this.markAsConfusingTextAreaValue = value;
  };

  resetCorrectionsAndTextAreaValue = (): void => {
    this.markAsConfusingTextAreaValue = '';
    this.revertMarkItemAsConfusing();
  };

  saveBasicAuthToken = (token: string): void => {
    this.basicAuthToken = saveBasicAPICredentials(token);
  };

  removeBasicAuthToken = (): void => {
    removeBasicAPICredentials();
  };

  setAllAssignmentsCount = (count: number): void => {
    this.allAssignmentsCount = count;
  };

  setInitialRemainingAssignmentsCount = (count: number): void => {
    this.remainingAssignmentsCount = count;
  };

  highlightText = (text: string, textType: 'title' | 'text'): string => {
    const pattern = new RegExp(this.keywords.join('|'));
    const regex = new RegExp(pattern, 'gi');

    if (textType === 'title') this.titleMatchedKeywords = text.match(regex) || [];
    if (textType === 'text') this.textMatchedKeywords = text.match(regex) || [];

    return text.replace(regex, '<span style="background-color: yellow;">$&</span>');
  };

  fetchCategoryPossibleValues = ({
    categoryName,
    item,
    query = '',
    pageNo = 1,
    isSearch = false,
    isLoadingMore = false,
  }: FetchCategoryPossibleValuesArgs): Promise<CategoryPossibleValuesResponse> => {
    runInAction(() => {
      this.loadingSearchedPossibleValues = true;
      this.possibleValuesPageNumber = pageNo;
    });

    return requests
      .fetchCategoryPossibleValues({
        categoryName,
        categoryValue: query,
        pageNumber: pageNo,
      })
      .then(({ data }) => {
        const foundCategory = item.item.itemCategories.find((c) => c.categoryId === item.targetCategories[0].id);
        const foundCategorySelectedValue = foundCategory?.selectedValue ?? '';
        const foundCategorySource = foundCategory?.source ?? '';
        const foundCategoryId = item.targetCategories.find((c) => c.categoryName === categoryName)?.id ?? '';

        const rawData = data.results.map((r) => ({
          possibleValue: r,
          categoryName,
          categoryId: foundCategoryId,
          id: uuid4(),
          feedClassification: foundCategorySelectedValue === r.title && foundCategorySource === categorySource.apriori,
          mlClassification: foundCategorySelectedValue === r.title && foundCategorySource === categorySource.predicted,
        }));

        const newResults = isSearch ? rawData : [...this.checkboxValues, ...rawData];
        runInAction(() => (this.checkboxValues = newResults));

        this.allCategoriesPossibleValuesData = this.allCategoriesPossibleValuesData.map((item) => {
          if (item.categoryName === categoryName)
            return { ...item, results: isLoadingMore ? [...item.results, ...data.results] : data.results };

          return item;
        });

        return { ...data, categoryName };
      })
      .finally(() => {
        runInAction(() => (this.loadingSearchedPossibleValues = false));
      });
  };

  fetchAllCategoriesPossibleValues = (item: Assignment): Promise<void> => {
    runInAction(() => (this.loadingPossibleValues = true));

    const filteredTargetCategories = sortByCategoryOrder<TargetCategory>(
      this.removeSpamAndConfusingCategories(item.targetCategories)
    );

    return Promise.all(
      filteredTargetCategories.map((c) => this.fetchCategoryPossibleValues({ categoryName: c.categoryName, item }))
    )
      .then((response) => {
        const firstTargetCategoryName = filteredTargetCategories[0].categoryName;
        const foundCategory = item.item.itemCategories.find((c) => c.categoryName === firstTargetCategoryName);
        const foundCategorySelectedValue = foundCategory?.selectedValue ?? '';
        const foundCategorySource = foundCategory?.source ?? '';
        const foundCategoryId = item.targetCategories.find((c) => c.categoryName === firstTargetCategoryName)?.id ?? '';

        runInAction(() => {
          this.checkboxValues = response[0].results.map((r) => ({
            possibleValue: r,
            categoryId: foundCategoryId,
            categoryName: firstTargetCategoryName,
            id: uuid4(),
            feedClassification:
              foundCategorySelectedValue === r.title && foundCategorySource === categorySource.apriori,
            mlClassification:
              foundCategorySelectedValue === r.title && foundCategorySource === categorySource.predicted,
          }));
        });

        runInAction(() => {
          this.selectedCheckboxValues = this.filterByTargetCategories(item).map((ic) => ({
            possibleValue: { title: ic.selectedValue },
            categoryId: item.targetCategories.find((tc) => tc.categoryName === ic.categoryName)?.id ?? '',
            categoryName: ic.categoryName,
            id: uuid4(),
            feedClassification: ic.source === categorySource.apriori,
            mlClassification: ic.source === categorySource.predicted,
          }));
        });

        this.allCategoriesPossibleValuesData = response;
        saveToStorage(POSSIBLE_VALUES_SESSION_STORAGE_NAME, response);
      })
      .finally(() => {
        runInAction(() => (this.loadingPossibleValues = false));
      });
  };

  debouncedFetchCategoryPossibleValues = debounce(() => {
    return this.fetchCategoryPossibleValues({
      categoryName: this.currentCategory,
      item: this.assignments[this.currentAssignmentIndex],
      query: this.query,
      pageNo: 1,
      isSearch: true,
    }).then(({ count, results }) => {
      const mappedPossibleValues = this.allCategoriesPossibleValuesData.map((pv) => {
        if (pv.categoryName === this.currentCategory)
          return {
            categoryName: this.currentCategory,
            count,
            results,
          };

        return pv;
      });

      this.allCategoriesPossibleValuesData = mappedPossibleValues;
    });
  }, 300);

  searchCategoryPossibleValues = (categoryName: string, query: string, errorFn: () => void): void => {
    this.setQuery(query);
    this.debouncedFetchCategoryPossibleValues?.()?.catch(() => errorFn());
  };

  removeSpamAndConfusingCategories = (categories: TargetCategory[]): TargetCategory[] => {
    return categories.filter((c) => isCategorySpamOrConfusing(c.categoryName));
  };

  getCategoriesToDisplay = (targetCategories: TargetCategory[]): Category[] => {
    const categoriesWithClassificationsSet = this.removeSpamAndConfusingCategories(targetCategories).map(
      ({ categoryName, id }) => ({ categoryName, categoryId: id })
    );

    const groupedCategories: Category[][] = Object.values(
      categoriesWithClassificationsSet.reduce((group, cat) => {
        const { categoryName } = cat;
        group[categoryName] = group[categoryName] ?? [];
        group[categoryName].push(cat);
        return group;
      }, {})
    );

    const groupedReducedCategories: Category[] = groupedCategories.map((cats: Category[]) => {
      return cats.reduce((prev: Category) => ({ ...prev }), {
        categoryName: cats[0].categoryName,
        categoryId: cats[0].categoryId,
      });
    });

    return sortByCategoryOrder<Category>(groupedReducedCategories);
  };

  resetFetchPossibleValuesParams = (): void => {
    this.possibleValuesPageNumber = 1;
    this.query = '';
  };
}

export default MisclassificationsStore;
