import { createContext, useContext } from 'react';
import { AxiosError } from 'axios';
import { AuthStore } from 'auth';
import { AnnotatorsStore } from 'annotators';
import { MetadataStore } from 'metadata';
import { MisclassificationsStore } from 'misclassifications';
import { PostsStore } from 'posts';
import { ProfilesStore } from 'profiles';

export class RootStore {
  annotatorsStore: AnnotatorsStore;
  authStore: AuthStore;
  metadataStore: MetadataStore;
  misclassificationsStore: MisclassificationsStore;
  postsStore: PostsStore;
  profilesStore: ProfilesStore;

  constructor() {
    this.annotatorsStore = new AnnotatorsStore(this);
    this.authStore = new AuthStore(this);
    this.metadataStore = new MetadataStore(this);
    this.misclassificationsStore = new MisclassificationsStore(this);
    this.postsStore = new PostsStore(this);
    this.profilesStore = new ProfilesStore(this);
  }

  handleAxiosError = (err: AxiosError): Promise<void> | never => {
    if (err.response?.status === 401) this.authStore.logout();
    throw err;
  };
}

export const rootStore = new RootStore();

const RootStoreContext = createContext(rootStore);

export function useStores(): RootStore {
  const context = useContext(RootStoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }
  return context;
}

export default RootStoreContext;
