import { useState, useEffect, useRef } from 'react';
import { Post } from 'utils/types/Post';

interface UsePostNavigationProps {
  posts: Post[];
}

interface UsePostNavigationReturn {
  selectedPost: string | null;
}

const protectedTagNames = ['INPUT'];

const useKeyboardNavigation = ({ posts }: UsePostNavigationProps): UsePostNavigationReturn => {
  const [selectedPost, setSelectedPost] = useState<string | null>(null);
  const selectedPostRef = useRef<string | null>(null);
  const postsRef = useRef<Post[]>([]);

  const handleSelectedPostChange = (id: string) => {
    setSelectedPost(id);
    selectedPostRef.current = id;
    scrollTo(id);
  };

  const scrollTo = (id: string) => {
    const postElement = document.getElementById(id);

    if (postElement) {
      const { top } = postElement.getBoundingClientRect();
      window.scrollTo({ top: top + window.scrollY - 70 });
    }
  };

  const handlePress = (e: KeyboardEvent) => {
    const currentPosts = postsRef.current;

    if (e.target && !protectedTagNames.includes(e.target['tagName'])) {
      if ((e.code === 'KeyJ' || e.code === 'KeyK') && selectedPostRef.current === null && currentPosts.length > 0) {
        return handleSelectedPostChange(currentPosts[0].id);
      }

      const index = currentPosts.findIndex((post) => post.id === selectedPostRef.current);

      if (e.code === 'KeyK') {
        if (index > 0) {
          const newId = currentPosts[index - 1].id;
          handleSelectedPostChange(newId);
        }
      } else if (e.code === 'KeyJ') {
        if (index !== -1 && index < currentPosts.length - 1) {
          const newId = currentPosts[index + 1].id;
          handleSelectedPostChange(newId);
        }
      }
    }
  };

  useEffect(() => {
    postsRef.current = posts;
  }, [posts]);

  useEffect(() => {
    document.addEventListener('keypress', handlePress);
    return () => document.removeEventListener('keypress', handlePress);
  }, []);

  return { selectedPost };
};

export default useKeyboardNavigation;
