import { useStores } from 'RootStore';
import AnnotatorWizardCategoryMultiSelect from 'annotators/AnnotatorWizardCategoryMultiSelect';
import { CSVBulkItem } from 'annotators/types';
import clsx from 'clsx';
import { useToast } from 'contexts/ToastProvider';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import ButtonSpinner from 'theme/atoms/buttons/ButtonSpinner';
import Input from 'theme/atoms/inputs/Input';
import baseStyles from 'theme/atoms/inputs/base.module.scss';
import Spinner from 'theme/atoms/loaders/Spinner';

import styles from './AnnotatorWizardForm.module.scss';

export interface IFileInfo {
  name: string;
  size: number;
  type: string;
}

function AnnotatorWizardForm(): JSX.Element {
  const { addToast, toastMessages } = useToast();

  const [annotatorName, setAnnotatorName] = useState('');
  const [annotatorCount, setAnnotatorCount] = useState(0);
  const [csvData, setCsvData] = useState<CSVBulkItem[]>([]);
  const [fileInfo, setFileInfo] = useState<IFileInfo | undefined>(undefined);

  const { annotatorsStore } = useStores();
  const {
    addTargetCategory,
    availableCategories,
    closeAnnotatorWizardModal,
    createAnnotatorWizard,
    fetchItemCategories,
    isLoadingItemCategories,
    isWizardSubmitting,
    setTargetCategories,
    targetCategories,
  } = annotatorsStore;

  const papaparseOptions = {
    delimiter: ',',
    header: true,
    skipEmptyLines: true,
  };

  useEffect(() => {
    fetchItemCategories();
  }, []);

  const getCategoryKeys = (data: CSVBulkItem[], withError = false): string[] => {
    try {
      const categories = data.flatMap((item) =>
        JSON.parse(item.itemcategories).map((category) => category.categoryName)
      );
      return Array.from(new Set(categories));
    } catch (error) {
      if (withError) addToast(toastMessages.BULK_IMPORT.CSV_ERROR, 'error');
      return [];
    }
  };

  const handleAnnotatorName = (value: string): void => {
    setAnnotatorName(value);
  };

  const handleAnnotatorCountChange = (value: string): void => {
    setAnnotatorCount(+value);
  };

  const handleItemsFromCSV = (data: CSVBulkItem[], fileData: IFileInfo): void => {
    setCsvData(data);
    setFileInfo(fileData);
    setTargetCategories(getCategoryKeys(data));
  };

  const submit = (): void => {
    createAnnotatorWizard(annotatorName, annotatorCount, targetCategories, csvData)
      .then(() => {
        addToast(toastMessages.BULK_IMPORT.IMPORT_SUCCESS);
      })
      .catch((err) => {
        if (err.message === toastMessages.BULK_IMPORT.CSV_ERROR) addToast(toastMessages.BULK_IMPORT.CSV_ERROR, 'error');
        else addToast(toastMessages.BULK_IMPORT.IMPORT_ERROR, 'error');
      });
  };

  const isSubmitBtnDisabled = useMemo(() => {
    return !annotatorCount || !targetCategories.length || !csvData.length || isWizardSubmitting;
  }, [annotatorCount, targetCategories, csvData, isWizardSubmitting]);

  return (
    <div className={styles.root}>
      {isLoadingItemCategories ? (
        <div className={styles.loadingArea}>
          <Spinner />
        </div>
      ) : (
        <>
          <Input label="Name" name="name" type="text" onChange={handleAnnotatorName} className={styles.field} />

          <Input
            label="Annotator count"
            secondaryLabel="Specify the number of annotators"
            name="annotatorCount"
            type="number"
            onChange={handleAnnotatorCountChange}
            step="1"
            className={styles.field}
            required
          />

          <div className={styles.field}>
            <div>
              <span className={styles.importFromCSVTitle}>Import CSV</span>
              <span className={clsx(baseStyles.secondaryLabel, baseStyles.label)}>
                Having imported a CSV, target categories from the file will be loaded
              </span>
            </div>
            <div className={styles.fieldContainer}>
              <div className={clsx(styles.importFromCSVContainer)}>
                <label className="btn-primary">
                  Choose file...
                  <CSVReader
                    onFileLoaded={handleItemsFromCSV}
                    parserOptions={papaparseOptions}
                    cssClass={styles.importFromCSVComponent}
                  />
                </label>
              </div>
              {fileInfo && <div className={styles.importFromCSVFileName}>File name: {fileInfo.name}</div>}
            </div>
          </div>

          <AnnotatorWizardCategoryMultiSelect className={styles.field} selectedCategories={targetCategories} />

          <div className={styles.availableCategories}>
            <span className={clsx(styles.availableCategoriesLabel, baseStyles.label, baseStyles.secondaryLabel)}>
              Available categories:
            </span>
            {availableCategories.map(({ id, name }) => (
              <button className="btn-mini-reverted" key={id} onClick={() => addTargetCategory(name)}>
                {name}
              </button>
            ))}
          </div>

          <div className={styles.footer}>
            <button type="button" className="btn-transparent" onClick={closeAnnotatorWizardModal}>
              Cancel
            </button>
            <button className={clsx('btn-primary', styles.submitBtn)} disabled={isSubmitBtnDisabled} onClick={submit}>
              {isWizardSubmitting && <ButtonSpinner />}
              <span className={clsx(styles.submitBtnText, { [styles.loading]: isWizardSubmitting })}>OK</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default observer(AnnotatorWizardForm);
