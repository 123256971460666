import clsx from 'clsx';
import { ReactComponent as TickIcon } from 'theme/svg/tick-white.svg';
import { splitByUnderscore } from 'utils/generateHash';

import styles from './PanelCheckbox.module.scss';

interface PanelCheckboxProps {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
  region: string | null | undefined;
}

function PanelCheckbox({ checked, className = '', disabled, label, onClick, region }: PanelCheckboxProps): JSX.Element {
  const handleClick = (): void => {
    if (disabled) return;
    onClick?.();
  };

  const text = region ? `${splitByUnderscore(label)} (${region})` : splitByUnderscore(label);

  return (
    <div className={clsx(styles.root, className, { [styles.disabled]: disabled })} onClick={handleClick}>
      <div className={clsx(styles.checkbox, { [styles.checked]: checked })}>
        <TickIcon />
      </div>
      <span className={clsx(styles.label, { [styles.labelChecked]: checked })}>{text}</span>
    </div>
  );
}

export default PanelCheckbox;
