import { Auth } from 'auth';
import { BASIC_LOGIN_STORAGE_PREFIX, LOGIN_STORAGE_PREFIX } from 'utils/constants';

const LOCAL_STORAGE_AUTH_NAME = `${LOGIN_STORAGE_PREFIX}${location.hostname}`;
const LOCAL_STORAGE_BASIC_AUTH_NAME = `${BASIC_LOGIN_STORAGE_PREFIX}${location.hostname}`;

// eslint-disable-next-line
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export const getAPICredentials = (): string | null => {
  return localStorage.getItem(LOCAL_STORAGE_AUTH_NAME) || null;
};

export const saveAPICredentials = (token: string): Auth => {
  const user = parseJwt(token);
  const isBasicAuthed = getBasicAPICredentials();

  const newSerializedState: Auth = {
    token,
    user,
  };

  localStorage.setItem(LOCAL_STORAGE_AUTH_NAME, JSON.stringify(newSerializedState));

  if (isBasicAuthed) removeBasicAPICredentials();

  return newSerializedState;
};

export function removeAPICredentials(): void {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_NAME);
}

export function getBasicAPICredentials(): string | null {
  const urlToken = window.location.search.replace('?token=', '');
  return localStorage.getItem(LOCAL_STORAGE_BASIC_AUTH_NAME) || urlToken || null;
}

export const saveBasicAPICredentials = (token: string): string | null => {
  const isBearerAuthed = getAPICredentials();

  if (isBearerAuthed) removeAPICredentials();
  localStorage.setItem(LOCAL_STORAGE_BASIC_AUTH_NAME, token);

  return token;
};

export function removeBasicAPICredentials(): void {
  localStorage.removeItem(LOCAL_STORAGE_BASIC_AUTH_NAME);
}
