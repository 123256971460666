import { Navigate } from 'react-router-dom';
import { getBasicAPICredentials } from 'api';

import { routes } from './routes';

interface BasicAuthCheckerProps {
  children?: JSX.Element;
  isPublic?: boolean;
  isInvalid?: boolean;
}

function BasicAuthChecker({ children, isPublic = false, isInvalid = false }: BasicAuthCheckerProps): JSX.Element {
  const userIsAuthenticated = getBasicAPICredentials();

  if ((!userIsAuthenticated && !isPublic) || (!userIsAuthenticated && isInvalid)) {
    return <Navigate to={routes.misclassificationsNoAccess.path} />;
  } else if (children) {
    return children;
  } else {
    throw new Error('Couldn`t find any route to redirect');
  }
}

export default BasicAuthChecker;
