import { useStores } from 'RootStore';
import clsx from 'clsx';
import { useToast } from 'contexts/ToastProvider';
import { CheckboxValue } from 'misclassifications/types';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useRef } from 'react';
import Input from 'theme/atoms/inputs/Input';
import Spinner from 'theme/atoms/loaders/Spinner';
import Tooltip from 'theme/atoms/tooltip';
import { ReactComponent as FeedIcon } from 'theme/svg/feed.svg';
import { ReactComponent as MLIcon } from 'theme/svg/ml.svg';
import { ReactComponent as SearchIcon } from 'theme/svg/search.svg';
import { capitalize } from 'utils/capitalize';
import { filtersIcons } from 'utils/constants';
import { splitByUnderscore } from 'utils/generateHash';

import MisclassificationsPanelRow from '../MisclassificationsPanelRow';
import styles from './MisclassificationsPanel.module.scss';

type CategoryInteractionStatus = 'viewed' | 'edited' | 'todo';

interface CategoryInteraction {
  categoryName: string;
  status: CategoryInteractionStatus;
}

const INTERACTION_STATUS: Record<CategoryInteractionStatus, CategoryInteractionStatus> = {
  edited: 'edited',
  todo: 'todo',
  viewed: 'viewed',
};

const LEGEND_ITEMS = [
  {
    name: 'feed',
    label: 'Apriori',
    icon: <FeedIcon />,
    tooltipText: 'This was assigned because the Feed this articles was puiled from was assigned this value.',
  },
  {
    name: 'ml',
    label: 'ChatGPT',
    icon: <MLIcon />,
    tooltipText: 'This was assigned because GPT based algorithm assigned it.',
  },
];

const MisclassificationsPanel = (): JSX.Element => {
  const { misclassificationsStore } = useStores();
  const {
    assignments,
    categories,
    checkboxValues,
    currentCategory,
    currentAssignmentIndex,
    currentCategoryValuesCount,
    currentCategorySelectedCheckboxValues,
    fetchCategoryPossibleValues,
    loadingSearchedPossibleValues,
    query,
    possibleValuesPageNumber,
    resetFetchPossibleValuesParams,
    setCheckboxValues,
    selectedCheckboxValues,
    setSelectedCheckboxValues,
    setCurrentCategory,
    searchCategoryPossibleValues,
  } = misclassificationsStore;
  const [interactions, setInteractions] = useState<CategoryInteraction[]>([]);
  const { addToast, toastMessages } = useToast();
  const selectedCheckboxesContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    resetFetchPossibleValuesParams();
    return () => {
      resetFetchPossibleValuesParams();
    };
  }, [currentAssignmentIndex]);

  useEffect(() => {
    setInteractions(
      categories.map((c, i) => ({
        categoryName: c.categoryName,
        status: i === 0 ? INTERACTION_STATUS.viewed : INTERACTION_STATUS.todo,
      }))
    );
  }, [categories, currentAssignmentIndex]);

  function handleResize(): void {
    const height = selectedCheckboxesContainerRef.current?.clientHeight ?? 0;
    document.documentElement.style.setProperty('--selected-checkboxes-container-height', `${height}px`);
  }

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedCheckboxesContainerRef, handleResize]);

  const handleCategoryClick = (categoryName: string): void => {
    setCurrentCategory(categoryName);
    setCheckboxValues(categoryName);
    resetFetchPossibleValuesParams();

    setInteractions(
      interactions.map((interaction) => {
        return interaction.categoryName !== categoryName
          ? interaction
          : {
              ...interaction,
              status:
                interaction.status === INTERACTION_STATUS.edited
                  ? INTERACTION_STATUS.edited
                  : INTERACTION_STATUS.viewed,
            };
      })
    );
  };

  const handleCheckboxClick = (possibleValue: CheckboxValue): void => {
    setSelectedCheckboxValues(possibleValue);
    setInteractions(
      interactions.map((int) =>
        int.categoryName !== currentCategory
          ? int
          : {
              ...int,
              status: INTERACTION_STATUS.edited,
            }
      )
    );
  };

  const getTooltipText = (status: CategoryInteractionStatus): string => {
    switch (status) {
      case INTERACTION_STATUS.viewed:
        return 'Viewed';
      case INTERACTION_STATUS.edited:
        return 'Edited';
      default:
        return 'To do';
    }
  };

  const handleSearch = (value: string): void => {
    searchCategoryPossibleValues(currentCategory, value, () =>
      addToast(toastMessages.MISCLASSIFICATIONS.POSSIBLE_VALUES_SEARCH_ERROR, 'error')
    );
  };

  const loadMore = (): void => {
    fetchCategoryPossibleValues({
      categoryName: currentCategory,
      item: assignments[currentAssignmentIndex],
      query,
      pageNo: possibleValuesPageNumber + 1,
      isLoadingMore: true,
    }).catch(() => {
      addToast(toastMessages.MISCLASSIFICATIONS.POSSIBLE_VALUES_LOAD_MORE_ERROR, 'error');
    });
  };

  const loadingValues = loadingSearchedPossibleValues ? (
    <Spinner small />
  ) : (
    <>
      {checkboxValues.length < currentCategoryValuesCount ? (
        <button className="btn-mini-reverted" onClick={loadMore}>
          Load more
        </button>
      ) : null}
    </>
  );

  return (
    <div className={clsx(styles.root, styles.card)}>
      <div className={styles.header}>
        <h2 className={styles.title}>Classification</h2>
        <div className={styles.legend}>
          {LEGEND_ITEMS.map(({ icon, label, name, tooltipText }) => (
            <div key={name} className={clsx(styles.legendItem, styles[name])}>
              {icon}
              <span>{label}</span>
              <Tooltip text={tooltipText} bottom width={280} wrap />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.metadataContainer}>
        <div className={styles.labelSelection}>
          {categories
            ?.map((c) => c.categoryName)
            .map((c, i) => {
              const interaction = interactions[i];
              const Icon = filtersIcons[capitalize(c)] || filtersIcons.League;

              return (
                <div
                  key={c}
                  className={clsx(styles.labelContainer, { [styles.labelActive]: c === currentCategory })}
                  onClick={() => handleCategoryClick(c)}
                >
                  <div className={clsx(styles.label)}>
                    <Icon />
                    {c}
                  </div>
                  {interaction?.status && (
                    <div className={clsx(styles.interactionStatus, styles[interaction.status])}>
                      <Tooltip text={getTooltipText(interaction.status)} />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className={styles.labelValueColumn}>
          <div className={clsx(styles.innerContainer, styles.searchContainer)}>
            <Input
              className={styles.search}
              name="search"
              label="Search"
              hiddenLabel
              type="search"
              endIcon={<SearchIcon />}
              value={query}
              placeholder="Search values"
              onChange={handleSearch}
            />
          </div>
          <div
            ref={selectedCheckboxesContainerRef}
            className={clsx(
              styles.panelRows,
              styles.panelRowsSelected,
              styles.innerContainer,
              styles.showBorderBottom,
              {
                [styles.noSelected]: !currentCategorySelectedCheckboxValues.length,
              }
            )}
          >
            {currentCategorySelectedCheckboxValues.map((cv) => {
              return (
                <MisclassificationsPanelRow
                  key={cv.possibleValue.title}
                  possibleValue={cv.possibleValue}
                  checked
                  onClick={() => handleCheckboxClick(cv)}
                  isSuggestedFromFeed={cv.feedClassification}
                  isSuggestedFromML={cv.mlClassification}
                />
              );
            })}
          </div>
          <div className={clsx(styles.panelRows, styles.panelRowsToSelect, styles.innerContainer)}>
            {checkboxValues.length ? (
              checkboxValues.map((cv) => {
                const isSelected = !!selectedCheckboxValues.find(
                  (scv) =>
                    scv.categoryName === cv.categoryName &&
                    splitByUnderscore(scv.possibleValue.title) === splitByUnderscore(cv.possibleValue.title)
                );

                return (
                  <MisclassificationsPanelRow
                    key={cv.possibleValue.title}
                    possibleValue={cv.possibleValue}
                    className={clsx({ [styles.checkboxSelected]: isSelected })}
                    onClick={() => handleCheckboxClick(cv)}
                    checked={isSelected}
                    isSuggestedFromFeed={cv.feedClassification}
                    isSuggestedFromML={cv.mlClassification}
                  />
                );
              })
            ) : (
              <p className={styles.empty}>No results!</p>
            )}
            <div className={styles.loadMoreBtnContainer}>{loadingValues}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(MisclassificationsPanel);
