import { useStores } from 'RootStore';
import { observer } from 'mobx-react-lite';
import Confirm from 'theme/atoms/confirm';
import Modal from 'theme/atoms/modal';

interface ProfileArchiveModalProps {
  isOpen: boolean;
  close: () => void;
}

function ProfileArchiveModal({ isOpen, close }: ProfileArchiveModalProps): JSX.Element {
  const { profilesStore } = useStores();
  const { profile, deleteProfile, isSubmitting } = profilesStore;

  const handleDelete = (): undefined | void => {
    if (!profile) return;

    deleteProfile(profile.id, profile.source).then(() => {
      close();
    });
  };

  return (
    <Modal isOpen={isOpen} close={close} title="Archive feed">
      <Confirm
        yesText="Archive"
        action={handleDelete}
        close={close}
        submitting={isSubmitting}
        question="Are you sure that you want to archive this feed?"
      />
    </Modal>
  );
}

export default observer(ProfileArchiveModal);
