import { useStores } from 'RootStore';
import Layout from 'layout';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import styles from './Posts.module.scss';
import PostsFilters from './PostsFilters/PostsFilters';
import PostsList from './PostsList/PostsList';

function Posts(): JSX.Element {
  const { postsStore } = useStores();
  const { searchValue, changeSearchValue, clearFilters } = postsStore;

  useEffect(() => {
    clearFilters();
  }, []);

  return (
    <Layout
      searchConfig={{
        value: searchValue,
        onChange: (newValue: string) => changeSearchValue(newValue),
        view: 'posts',
      }}
    >
      <div className={styles.inner}>
        <div className={styles.content}>
          <PostsFilters />
          <PostsList />
        </div>
      </div>
    </Layout>
  );
}

export default observer(Posts);
