import { useStores } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import { useToast } from 'contexts/ToastProvider';
import Layout from 'layout';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ActionDropdown from 'theme/atoms/actionDropdown';
import { ActionDropdownOption } from 'theme/atoms/actionDropdown/ActionDropdown';
import Breadcrumbs from 'theme/atoms/breadcrumbs';
import LoadingTable from 'theme/atoms/loaders/LoadingTable';
import PageHeader from 'theme/atoms/pageHeader';
import Pagination from 'theme/atoms/pagination/Pagination';
import Tag from 'theme/atoms/tag';
import { calculateAnnotatorResults } from 'utils/calculateAnnotatorResults';
import { filtersIcons } from 'utils/constants';
import { exportToCSV } from 'utils/exportToCsv';
import { isCategorySpamOrConfusing } from 'utils/isCategorySpamOrConfusing';

import AnnotatorDeleteModal from './AnnotatorDeleteModal';
import AnnotatorName from './AnnotatorName';
import AnnotatorProgressBar from './AnnotatorProgressBar';
import AnnotatorWizardModal from './AnnotatorWizardModal';
import styles from './Annotators.module.scss';
import { Annotator } from './types';

const exportHeaders = [
  { label: 'Name', value: 'name' },
  {
    label: 'Assigned',
    value: 'assigned',
  },
  {
    label: 'Spam %',
    value: 'spam',
  },
  {
    label: 'Confusing %',
    value: 'confusing',
  },
  {
    label: 'Done %',
    value: 'done',
  },
  {
    label: 'Categories',
    value: 'categories',
  },
];

const Annotators = (): JSX.Element => {
  const { addToast, toastMessages } = useToast();
  const { annotatorsStore } = useStores();
  const {
    annotators,
    annotatorsCount,
    changePageNumber,
    changePageSize,
    closeAnnotatorWizardModal,
    fetchAnnotators,
    generateAnnotatorMisclassificationsLink,
    isAnnotatorWizardModalOpen,
    isLoadingAnnotators,
    openAnnotatorDeleteModal,
    openAnnotatorWizardModal,
    pageNumber,
    pageSize,
    resetPagination,
  } = annotatorsStore;

  const [selectedAnnotatorId, setSelectedAnnotatorId] = useState('');

  const exportData = annotators.map((annotator) => {
    const { ratio } = calculateAnnotatorResults(annotator);

    return {
      ...annotator,
      assigned: annotator.dashboard.totalItems,
      categories: annotator.dashboard.targetCategories
        .filter((c) => isCategorySpamOrConfusing(c.categoryName))
        .map((c) => c.categoryName)
        .join(', '),
      confusing: (ratio.confusing * 100).toFixed(2),
      spam: (ratio.spam * 100).toFixed(2),
      done: (ratio.done * 100).toFixed(2),
    };
  });

  const exportAnnotatorsList = (): void => {
    exportToCSV<Annotator>({
      data: exportData,
      headers: exportHeaders,
      fileName: 'Annotators-list',
    });
  };

  useEffect(() => {
    return () => resetPagination();
  }, []);

  useEffect(() => {
    fetchAnnotators(pageSize, pageNumber + 1).catch(() => {
      addToast(toastMessages.ANNOTATORS.FETCH_ERROR, 'error');
    });
  }, [pageNumber, pageSize]);

  const handleAnnotatorDeleteModal = (annotatorId: string): void => {
    openAnnotatorDeleteModal();
    setSelectedAnnotatorId(annotatorId);
  };

  const actionDropdownOptions = (annotatorId: string): ActionDropdownOption[] => [
    { action: () => handleAnnotatorDeleteModal(annotatorId), label: 'Delete' },
  ];

  return (
    <Layout>
      <Breadcrumbs ancestors={[{ title: 'Dashboard', to: paths.feed }]} current="Annotators" />
      <PageHeader title="Annotators">
        <div className={styles.buttonsContainer}>
          <button className="btn-primary" onClick={exportAnnotatorsList}>
            Export list
          </button>
          <button className="btn-primary" onClick={openAnnotatorWizardModal}>
            Bulk Import
          </button>
        </div>
      </PageHeader>

      <LoadingTable loading={isLoadingAnnotators} className={styles.main}>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Assigned</th>
              <th>Results</th>
              <th>Categories</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {annotators.map((a) => (
              <tr key={a.id}>
                <td data-th="Name">
                  <AnnotatorName
                    name={a.name}
                    url={generateAnnotatorMisclassificationsLink(a.dashboard.annotatorToken)}
                    urlText="Copy URL for reviewer"
                  />
                </td>
                <td data-th="Assigned">{a.dashboard.totalItems || 0}</td>
                <td data-th="Results">
                  <AnnotatorProgressBar annotator={a} />
                </td>
                <td data-th="Categories">
                  <div>
                    {a.dashboard.targetCategories
                      ?.filter((tc) => isCategorySpamOrConfusing(tc.categoryName))
                      ?.map((tc) => {
                        const Icon = filtersIcons[tc.categoryName] || filtersIcons.League;
                        return <Tag key={tc.id} icon={<Icon />} text={tc.categoryName} />;
                      })}
                  </div>
                </td>
                <td>
                  <div>
                    <Link to={paths.annotator(a.id)}>Show details</Link>
                    <ActionDropdown options={actionDropdownOptions(a.id)} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          <Pagination
            activePage={pageNumber}
            activePageItemCount={annotators.length}
            allItemCount={annotatorsCount}
            disabled={isLoadingAnnotators}
            goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
            pageSize={pageSize}
            onPageSizeChange={changePageSize}
          />
        }
      </LoadingTable>

      <AnnotatorWizardModal isOpen={isAnnotatorWizardModalOpen} close={closeAnnotatorWizardModal} />
      <AnnotatorDeleteModal annotatorId={selectedAnnotatorId} />
    </Layout>
  );
};

export default observer(Annotators);
