const handleAdditionalFilters = (additionalFiltersNames: string[]): Record<string, string | boolean> => {
  let additionalFilters = {};
  if (additionalFiltersNames.includes('autoPosting') && !additionalFiltersNames.includes('manualPosting')) {
    additionalFilters = { ...additionalFilters, isAutopostable: true };
  } else if (additionalFiltersNames.includes('manualPosting') && !additionalFiltersNames.includes('autoPosting')) {
    additionalFilters = { ...additionalFilters, isAutopostable: false };
  }
  if (additionalFiltersNames.includes('backloading')) {
    additionalFilters = { ...additionalFilters, isBackload: true };
  }
  if (additionalFiltersNames.includes('twitter') && !additionalFiltersNames.includes('rss')) {
    additionalFilters = { ...additionalFilters, sourceKind: 'twitter' };
  } else if (additionalFiltersNames.includes('rss') && !additionalFiltersNames.includes('twitter')) {
    additionalFilters = { ...additionalFilters, sourceKind: 'rss' };
  }
  if (additionalFiltersNames.includes('National') && !additionalFiltersNames.includes('Canada')) {
    additionalFilters = { ...additionalFilters, country: 'National' };
  } else if (additionalFiltersNames.includes('Canada') && !additionalFiltersNames.includes('National')) {
    additionalFilters = { ...additionalFilters, country: 'Canada' };
  }
  return additionalFilters;
};

export default handleAdditionalFilters;
