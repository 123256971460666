import { Dispatch, SetStateAction } from 'react';
import Multiselect, { List } from 'theme/atoms/multiselect';
import usePaginatedMetadataLists from 'utils/hooks/usePaginatedMetadataLists';

import styles from './ProfileFormSections.module.scss';

interface ProfileFormSectionsProps {
  disabled: boolean;
  lists: List[];
  setLists: Dispatch<SetStateAction<List[]>>;
}

function ProfileFormSections({ disabled, lists, setLists }: ProfileFormSectionsProps): JSX.Element {
  const { handleOpen, handleClose, handleReset, handleItemToggle, handleNextPage, handleSearchValueChange } =
    usePaginatedMetadataLists({ lists, setLists });

  return (
    <div className={styles.lists}>
      {lists.map((list: List) => {
        return (
          <Multiselect
            disabled={disabled}
            key={list.title}
            list={list}
            useFullWidthForButton
            onDropdownOpen={handleOpen}
            onDropdownClose={handleClose}
            onSearchValueChange={handleSearchValueChange}
            onNextPage={handleNextPage}
            onItemToggle={handleItemToggle}
            onReset={handleReset}
          />
        );
      })}
    </div>
  );
}

export default ProfileFormSections;
