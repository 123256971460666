import clsx from 'clsx';
import React from 'react';
import { ReactComponent as ChevronLeftDoubleIcon } from 'theme/svg/chevron-left-double.svg';
import { ReactComponent as ChevronLeftIcon } from 'theme/svg/chevron-left.svg';
import { ReactComponent as ChevronRightDoubleIcon } from 'theme/svg/chevron-right-double.svg';
import { ReactComponent as ChevronRightIcon } from 'theme/svg/chevron-right.svg';

import PageSizeSelector from '.././pageSizeSelector';
import styles from './Pagination.module.scss';

interface PaginationProps {
  activePage: number;
  activePageItemCount: number;
  allItemCount: number;
  disabled: boolean;
  goToPage: (page: number) => void;
  pageSize: number;
  onPageSizeChange?: (newPageSize: number) => void;
}

const pagesSubstr = [-3, -2, -1, 0, 1];
const firstPagesSubstr = [0, 1, 2, 3, 4];
const lastPagesSubstr = [-3, -2, -1, 0, 1];

const Pagination = ({
  activePage,
  activePageItemCount,
  allItemCount,
  disabled,
  goToPage,
  pageSize,
  onPageSizeChange,
}: PaginationProps): JSX.Element => {
  const pages = Math.ceil(allItemCount / pageSize);
  const backDisabled = disabled || activePage === 0;
  const nextDisabled = disabled || activePage === pages - 1;

  const visiblePages = Array(5)
    .fill(0)
    .map((_, i) => {
      if (activePage + 3 > pages) return activePage + lastPagesSubstr[i];
      if (activePage > 3) return activePage + pagesSubstr[i];
      return firstPagesSubstr[i];
    })
    .filter((p) => p >= 0 && p < pages);

  const lastPageVisible = visiblePages.some((p) => p === pages - 1);
  const morePages = visiblePages[visiblePages.length - 1] + 3;

  if (pages < 2 && !onPageSizeChange) return <div />;

  return (
    <div className={styles.root}>
      {onPageSizeChange ? (
        <PageSizeSelector
          pageSize={pageSize}
          onChange={(option) => {
            const newPageSize = parseInt(option.value, 10);
            onPageSizeChange(newPageSize);
          }}
        />
      ) : null}
      <p className={styles.info}>
        Showing <strong>{activePageItemCount}</strong> of <strong>{allItemCount}</strong>{' '}
        {activePageItemCount === 1 ? 'result' : 'results'}
      </p>
      <div className={styles.buttons}>
        <button
          className={clsx(styles.btn, styles.arrowBtnLeft, {
            [styles.btnDisabled]: backDisabled,
          })}
          onClick={() => !backDisabled && goToPage(0)}
          disabled={backDisabled}
        >
          <ChevronLeftDoubleIcon />
        </button>
        <button
          className={clsx(styles.btn, styles.arrowBtnLeft, {
            [styles.btnDisabled]: backDisabled,
          })}
          onClick={() => !backDisabled && goToPage(activePage - 1)}
          disabled={backDisabled}
        >
          <ChevronLeftIcon />
        </button>
        {visiblePages.map((page) => (
          <button
            className={clsx(styles.btn, { [styles.activePage]: activePage === page })}
            disabled={disabled}
            key={page}
            onClick={() => goToPage(page)}
          >
            {page + 1}
          </button>
        ))}
        {!lastPageVisible && activePage < pages - 3 && (
          <button
            className={styles.btn}
            disabled={disabled}
            onClick={() => goToPage(morePages < pages - 1 ? morePages : pages - 1)}
          >
            ...
          </button>
        )}
        <button
          className={clsx(styles.btn, styles.arrowBtnRight, {
            [styles.btnDisabled]: backDisabled,
          })}
          onClick={() => !nextDisabled && goToPage(activePage + 1)}
          disabled={nextDisabled}
        >
          <ChevronRightIcon />
        </button>
        <button
          className={clsx(styles.btn, styles.arrowBtnRight, {
            [styles.btnDisabled]: nextDisabled,
          })}
          onClick={() => !nextDisabled && goToPage(pages - 1)}
          disabled={nextDisabled}
        >
          <ChevronRightDoubleIcon />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
