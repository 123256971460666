import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { ReactComponent as CopyIcon } from 'theme/svg/copy.svg';

import styles from './CopyToClipboard.module.scss';

type CopyToClipboardProps = {
  className?: string;
  labelClassName?: string;
  iconMode?: boolean;
  limit?: number;
  text?: string;
  url: string;
};

const SLICE_THRESHOLD = 50;
const LAST_STRING_LENGTH = 17;

export const sliceText = (str: string | undefined): string => {
  if (!str) return '';

  const firstStringLimit = 13;

  if (str.length < firstStringLimit) {
    return str;
  }

  const regex = /([^/]+)\/?$/;
  const matches = str.match(regex);
  const rightString = matches?.[1] || '';
  const lastString = rightString.slice(-LAST_STRING_LENGTH);

  return str.length < SLICE_THRESHOLD ? str : `${str.slice(0, firstStringLimit)}[...]/${lastString}`;
};

const CopyToClipboard = ({
  className = '',
  labelClassName = '',
  iconMode,
  text,
  url,
}: CopyToClipboardProps): JSX.Element => {
  const [copied, setCopied] = useState(false);

  const copyUrl = (): void => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => setCopied(false), 3000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <div className={clsx(styles.root, className, { [styles.alignedLeft]: !!text })} onClick={copyUrl}>
      {!iconMode && <span>{text || sliceText(url)}</span>}
      <div className={styles.iconContainer}>
        <CopyIcon />
        {copied && <div className={clsx(styles.copyLabel, labelClassName)}>Copied to clipboard!</div>}
      </div>
    </div>
  );
};

export default CopyToClipboard;
