import { ChangeEvent, KeyboardEvent, forwardRef } from 'react';
import clsx from 'clsx';

import inputStyles from './Input.module.scss';
import baseStyles from '../base.module.scss';
import { InputProps } from '../types';

function Input(
  {
    autoCompleteOff,
    className = '',
    endIcon,
    error,
    hiddenLabel = false,
    inputBoxClassName = '',
    label,
    labelClassName = '',
    name,
    onChange,
    onKeyDown,
    placeholder,
    readOnly,
    secondaryLabel,
    startIcon,
    type,
    value,
    required = false,
  }: InputProps,
  ref
): JSX.Element {
  const handleChange = (value: string) => {
    onChange && onChange(value);
  };

  const controlledProps = onChange
    ? { value, onChange: (e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.value) }
    : {};

  return (
    <div
      className={clsx(baseStyles.field, className, {
        [baseStyles.error]: error,
      })}
    >
      {label ? (
        <label
          className={clsx(baseStyles.label, labelClassName, { [baseStyles.hiddenLabel]: hiddenLabel })}
          htmlFor={name}
        >
          <span className={clsx({ [baseStyles.required]: required })}>{label}</span>
          {secondaryLabel && <span className={baseStyles.secondaryLabel}>{secondaryLabel}</span>}
        </label>
      ) : null}
      <div className={clsx(baseStyles.inputBox, inputBoxClassName, { [baseStyles.inputSearchBox]: type === 'search' })}>
        {startIcon && <span className={clsx(inputStyles.iconBox, inputStyles.startIconBox)}>{startIcon}</span>}
        <input
          id={name}
          aria-invalid={error ? 'true' : 'false'}
          type={type}
          ref={ref}
          className={baseStyles.input}
          autoComplete={autoCompleteOff ? 'off' : 'on'}
          placeholder={placeholder}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onKeyDown?.(e)}
          readOnly={readOnly}
          {...controlledProps}
        />
        {endIcon && <span className={clsx(inputStyles.iconBox, inputStyles.endIconBox)}>{endIcon}</span>}
      </div>
      {error && <p className={baseStyles.errorMessage}>{error.message}</p>}
    </div>
  );
}

export default forwardRef(Input);
