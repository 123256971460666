interface DecodedTokenKeys {
  id: string;
  password: string;
}

function decodeBase64(base64: string): string {
  return atob(base64);
}

export function decodeBasicAuthToken(token: string): DecodedTokenKeys {
  const decodedToken = decodeBase64(token).split(':');
  return {
    id: decodedToken[0],
    password: decodedToken[1],
  };
}
