import React, { useState, useRef } from 'react';
import { ReactComponent as MoreIcon } from 'theme/svg/more.svg';
import ActionButton, { ActionButtonType } from 'theme/atoms/buttons/ActionButton';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import styles from './ActionDropdown.module.scss';

type ClickAction = () => void;

export interface ActionDropdownOption {
  action: ClickAction;
  label: ActionButtonType;
}

interface ActionDropdownProps {
  options: ActionDropdownOption[];
}

const ActionDropdown = ({ options }: ActionDropdownProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) });

  const handleAction = (action: ClickAction): void => {
    action();
    setIsOpen(false);
  };

  return (
    <div className={styles.root}>
      <button onClick={() => setIsOpen(!isOpen)}>
        <MoreIcon />
      </button>
      {isOpen && (
        <div ref={dropdownRef} className={styles.dropdown}>
          <ul>
            {options.map(({ action, label }, i) => (
              <li key={i}>
                <ActionButton type={label} action={() => handleAction(action)} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionDropdown;
