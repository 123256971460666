import { useStores } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useMemo, ReactElement, FunctionComponent, SVGProps } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as DropdownIcon } from 'theme/svg/dropdown.svg';
import { ReactComponent as EditIcon } from 'theme/svg/edit.svg';
import { ReactComponent as FolderIcon } from 'theme/svg/folder.svg';
import { ReactComponent as HomeIcon } from 'theme/svg/home.svg';

import styles from './LayoutSideMenu.module.scss';

interface SubNavLink {
  name: string;
  to: string;
}

interface NavLink {
  name: string;
  to: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>> | ReactElement;
  sublinks?: SubNavLink[];
}

interface LayoutSideMenuProps {
  hasTopMargin: boolean;
}

const SUBLINKS: SubNavLink[] = [
  { name: 'RSS', to: paths.profileType('rss') },
  { name: 'Twitter', to: paths.profileType('twitter') },
  { name: 'Sitemap', to: paths.profileType('sitemap') },
];

const PROD_LINKS = [
  { name: 'My feed', to: paths.feed, icon: <HomeIcon /> },
  {
    name: 'Browsing feeds',
    to: paths.profiles,
    icon: <FolderIcon />,
    sublinks: SUBLINKS,
  },
];

const STG_LINKS = [
  { name: 'My feed', to: paths.feed, icon: <HomeIcon /> },
  {
    name: 'Browsing feeds',
    to: paths.profiles,
    icon: <FolderIcon />,
    sublinks: SUBLINKS,
  },
  { name: 'Annotators', to: paths.annotators, icon: <EditIcon /> },
];

const LayoutSideMenu = ({ hasTopMargin }: LayoutSideMenuProps): JSX.Element => {
  const location = useLocation();
  const params = useParams();
  const { authStore } = useStores();
  const { isProdUiVisible } = authStore;

  const LINKS: NavLink[] = useMemo(() => {
    return isProdUiVisible ? PROD_LINKS : STG_LINKS;
  }, [isProdUiVisible]);

  const getLinkClasses = (className: string, link: NavLink | SubNavLink): string => {
    return clsx({
      [styles[className]]: params.profileType ? location.pathname === link.to : location.pathname.includes(link.to),
    });
  };

  return (
    <div className={clsx(styles.layoutSideMenu, { [styles.marginTop]: hasTopMargin })}>
      <ul>
        {LINKS.map((l) => (
          <li key={l.name} className={styles.link}>
            <Link to={l.to} className={getLinkClasses('linkActive', l)}>
              {l.icon}
              <span>{l.name}</span>
              {l.sublinks && (
                <div className={styles.dropdownMenuIcon}>
                  <DropdownIcon />
                </div>
              )}
            </Link>
            <ul>
              {l.sublinks?.map((s) => {
                return (
                  <li className={styles.sublink} key={s.name}>
                    <Link to={s.to} className={getLinkClasses('sublinkActive', s)}>
                      <span>{s.name}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(LayoutSideMenu);
