import { CustomCategoryName } from 'misclassifications/types';
import { List } from 'theme/atoms/multiselect';
import { ReactComponent as CategoryIcon } from 'theme/svg/feed.svg';
import { ReactComponent as AthleteIcon } from 'theme/svg/filtersIcons/athlete.svg';
import { ReactComponent as GenderIcon } from 'theme/svg/filtersIcons/gender.svg';
import { ReactComponent as LeagueIcon } from 'theme/svg/filtersIcons/league.svg';
import { ReactComponent as PostingIcon } from 'theme/svg/filtersIcons/posting.svg';
import { ReactComponent as RegionIcon } from 'theme/svg/filtersIcons/region.svg';
import { ReactComponent as SourceIcon } from 'theme/svg/filtersIcons/source.svg';
import { ReactComponent as SportIcon } from 'theme/svg/filtersIcons/sport.svg';
import { ReactComponent as TeamIcon } from 'theme/svg/filtersIcons/team.svg';
import { ReactComponent as YourLabelsIcon } from 'theme/svg/tag.svg';
import env from 'utils/env';

export const filtersIcons = {
  Athlete: AthleteIcon,
  Category: CategoryIcon,
  Conference: CategoryIcon,
  Division: CategoryIcon,
  Subdivision: CategoryIcon,
  Gender: GenderIcon,
  League: LeagueIcon,
  Level: LeagueIcon,
  Posting: PostingIcon,
  Region: RegionIcon,
  Source: SourceIcon,
  Sport: SportIcon,
  Team: TeamIcon,
  YourLabels: YourLabelsIcon,
};

export const LOGIN_STORAGE_PREFIX = 'bvmsports__auth_';
export const BASIC_LOGIN_STORAGE_PREFIX = 'bvmsports__basic_auth_';
export const POSSIBLE_VALUES_SESSION_STORAGE_NAME = 'misclassifications_possible_values';

export const feedFiltersDelay = 500;
export const filtersToBeDisplayedInFeedAndFollowed = ['Sport', 'League', 'Team', 'Region'];

export const profilesPerPage = 10;

export const countryObj = {
  title: 'Country',
  items: [
    { id: 'National', title: 'National', checked: false, hide: false },
    { id: 'Canada', title: 'Canada', checked: false, hide: false },
  ],
  open: false,
  fetched: true,
  fetchedItems: [],
  icon: filtersIcons.Region,
  type: 'static',
};

export const postingObj: List = {
  title: 'Posting',
  items: [
    { id: 'autoPosting', title: 'Autoposting', checked: false, hide: false },
    { id: 'manualPosting', title: 'Manual Posting', checked: false, hide: false },
    { id: 'backloading', title: 'Backloading', checked: false, hide: false },
  ],
  open: false,
  dontShowInFeedFilters: true,
  fetched: true,
  fetchedItems: [],
  icon: filtersIcons.Posting,
  type: 'static',
};

const sourceObj: List = {
  title: 'Source',
  items: [
    { id: 'twitter', title: 'Twitter', checked: false, hide: false },
    { id: 'rss', title: 'RSS', checked: false, hide: false },
    { id: 'sitemap', title: 'Sitemap', checked: false, hide: false },
  ],
  open: false,
  fetched: true,
  fetchedItems: [],
  icon: filtersIcons.Source,
  type: 'static',
};

const defaultFilterLists: List[] = [{ ...countryObj, type: 'static' }, postingObj];

export const additionalFilterLists = (profileType: string | undefined): List[] => {
  return profileType ? defaultFilterLists : [...defaultFilterLists, sourceObj];
};

export const additionalFilters = [
  'twitter',
  'rss',
  'autoPosting',
  'manualPosting',
  'backloading',
  'National',
  'Canada',
];

export const assignmentsPageSize = 10;
export const categoryPossibleValuesPageSize = 20;

export const categoryOrder = ['Gender', 'Sport', 'Level', 'League', 'Region', 'Team'];
export const categorySource: Record<string, string> = {
  apriori: 'Apriori',
  predicted: 'ChatGPT',
};

export const DEFAULT_TARGET_CATEGORIES: Record<string, CustomCategoryName> = {
  spam: 'Spam',
  confusing: 'Confusing',
};

export const IS_PROD = env.appEnvironment === 'production';
