import Select, { Option } from '../select';
import styles from './PageSizeSelector.module.scss';

interface PageSizeSelectorProps {
  pageSize: number;
  onChange: (option: Option) => void;
}

function PageSizeSelector({ onChange, pageSize }: PageSizeSelectorProps): JSX.Element {
  const options = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const selectedOption = options.find((option) => parseInt(option.value, 10) === pageSize);

  return (
    <div className={styles.main}>
      Rows per page:
      <Select
        label={selectedOption ? selectedOption.label : options[0].label}
        value={selectedOption || options[0]}
        up={true}
        options={options}
        onChange={onChange}
        className={styles.select}
      />
    </div>
  );
}

export default PageSizeSelector;
