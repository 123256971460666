import { RootStore } from 'RootStore';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { makeAutoObservable } from 'mobx';
import { additionalFilters } from 'utils/constants';
import handleAdditionalFilters from 'utils/handleAdditionalFilters';
import { ColumnSearch } from 'utils/types/ColumnSearch';
import { Filter } from 'utils/types/Filter';
import { Location } from 'utils/types/Location';
import { Profile } from 'utils/types/Profile';
import { SortDirection } from 'utils/types/ProfilesList';

import * as requests from './profilesRequests';
import { mapProfile } from './profilesStoreHelpers';

class ProfilesStore {
  rootStore: RootStore;

  areProfilesFetched = false;
  profiles: Profile[] = [];
  searchedParentProfiles: Profile[] = [];
  isSubmitting = false;
  isFetchingProfiles = true;

  totalProfileCount = 0;
  page = 0;
  size = 10;

  sortColumn = 'createdat';
  sortDirection: SortDirection = 'desc';
  filters: Filter[] = [];
  filtersSource: 'main' | 'sidebar' = 'main';

  textSearchValue = '';
  sourceSearchValue = '';
  createdAtSearchValue: Date | undefined = undefined;

  isProfileFormOpen = false;
  isParentProfileOpen = false;
  isProfileArchiveModalOpen = false;
  isRSSLastErrorModalOpen = false;
  profile: Profile | null = null;

  searchValue = '';

  latestId = '';
  newlyCreated = false;

  filterClickedOnProfile: Filter | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  updateSortByAndRequest = (val: string, profileType: string): boolean => {
    this.fetchProfiles(true, false, profileType);
    return false;
  };

  updatePageAndRequest = (val: number, profileType: string): boolean => {
    this.page = val;
    this.isFetchingProfiles = true;
    this.fetchProfiles(false, true, profileType);

    return false;
  };

  updatePageSizeAndRequest = (val: number, profileType: string): boolean => {
    this.size = val;
    this.fetchProfiles(true, true, profileType);

    return false;
  };

  updateColumnSearchAndRequest = (column: ColumnSearch, val: string | Date, profileType: string): boolean => {
    this[column + 'SearchValue'] = val;
    this.isFetchingProfiles = false;
    this.debouncedFetchProfiles(profileType ?? '');

    return false;
  };

  fetchProfiles = (resetPreviousData?: boolean, shouldScrollToTop?: boolean, profileType?: string): Promise<void> => {
    if (resetPreviousData) {
      this.isFetchingProfiles = true;
      this.page = 0;
    }

    const filtersIds = this.filters.map((filter) => filter.id);
    const metadataFiltersIds = filtersIds.filter((fId) => !additionalFilters.includes(fId));
    const otherFiltersIds = filtersIds.filter((fId) => additionalFilters.includes(fId));

    const handleSourceKind = (): Record<string, string> | undefined => {
      if (profileType === 'rss') return { sourceKind: 'RSS' };
      else return;
    };

    return requests
      .fetchProfiles(
        {
          textSearch: this.textSearchValue || this.searchValue,
          sortBy: this.sortColumn + this.sortDirection,
          metadataId: metadataFiltersIds,
          size: this.size,
          page: this.page + 1,
          source: this.sourceSearchValue,
          ...(this.createdAtSearchValue && { createdAt: format(this.createdAtSearchValue, 'yyyy-MM-dd') }),
          ...handleSourceKind(),
          ...handleAdditionalFilters(otherFiltersIds),
        },
        profileType ?? ''
      )
      .then((res) => {
        const profilesRaw = res.data.data;

        this.totalProfileCount = res.data.total;
        this.areProfilesFetched = true;

        if (shouldScrollToTop) window.scrollTo(0, 0);

        this.profiles = [...profilesRaw.map((profile) => mapProfile(profile))];
        this.isFetchingProfiles = false;
        this.areProfilesFetched = true;
      })
      .catch(this.rootStore.handleAxiosError);
  };

  fetchSearchedParentProfiles = (searchText: string, profileType?: string): Promise<void> => {
    this.isFetchingProfiles = true;

    return requests
      .fetchProfiles(
        {
          metadataId: [],
          page: 1,
          size: 20,
          sortBy: 'titleasc',
          textSearch: '',
          title: searchText,
          ...(profileType && { sourceKind: profileType === 'twitter' ? 'Twitter' : 'RSS' }),
        },
        profileType ?? ''
      )
      .then(({ data }) => {
        const profilesRaw = data.data;
        this.searchedParentProfiles = [...profilesRaw.map((profile) => mapProfile(profile))];
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => (this.isFetchingProfiles = false));
  };

  resetSearchedParentProfiles = (): void => {
    this.searchedParentProfiles = [];
  };

  debouncedFetchProfiles = debounce((profileType: string) => {
    this.fetchProfiles(true, false, profileType);
  }, 500);

  updateFilters = (newFilters: Filter[], profileType?: string): boolean => {
    let isChange = false;

    if (
      this.filters.length === newFilters.length &&
      this.filters.every((filter, i) => filter.id === newFilters[i].id)
    ) {
      isChange = false;
    } else {
      this.filters = newFilters;
      this.debouncedFetchProfiles(profileType ?? '');
      isChange = true;
    }

    return isChange;
  };

  updateFiltersSource = (source: 'main' | 'sidebar'): void => {
    this.filtersSource = source;
  };

  clearFilters = (): boolean => {
    let shouldScrollToTop = false;

    if (this.filters.length || this.searchValue) {
      this.filters = [];
      this.searchValue = '';
      shouldScrollToTop = true;
    }

    return shouldScrollToTop;
  };

  changeSearchValue = (newValue: string, profileType: string): void => {
    this.searchValue = newValue;
    this.debouncedFetchProfiles(profileType ?? '');
  };

  fetchProfile = (id: string, source: string): Promise<Profile | void> => {
    return requests
      .fetchProfile(id, source)
      .then((data) => mapProfile(data))
      .catch(this.rootStore.handleAxiosError);
  };

  addProfile = (data: requests.ProfileDto, profileType: string): Promise<void> => {
    this.isSubmitting = true;

    return requests
      .addProfile(data, profileType)
      .then((response) => {
        this.latestId = response.data.id;
        return this.fetchProfiles(true, false, profileType);
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  updateProfile = (id: string, data: requests.ProfileDto): Promise<void> => {
    this.isSubmitting = true;

    return requests
      .updateProfile(id, data)
      .then(() => {
        this.fetchProfile(id, data.source)
          .then((profile) => {
            if (!profile) return;

            const profileIndex = this.profiles.findIndex((p) => p.id === id);
            if (profileIndex !== -1) {
              this.profiles[profileIndex] = profile;
            }
          })
          .catch(this.rootStore.handleAxiosError);
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  deleteProfile = (id: string, source: string): Promise<void> => {
    this.isSubmitting = true;

    return requests
      .deleteProfile(id, source)
      .then(() => {
        this.profiles = this.profiles.filter((profile) => profile.id !== id);
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  openProfileForm = (profile?: Profile | null, isParentProfile?: boolean): void => {
    this.isProfileFormOpen = true;
    this.profile = profile || null;

    if (isParentProfile) this.isParentProfileOpen = true;
  };

  closeProfileForm = (): void => {
    this.isProfileFormOpen = false;
    this.profile = null;

    if (this.isParentProfileOpen) this.isParentProfileOpen = false;
  };

  openProfileArchiveModal = (profile?: Profile): void => {
    this.isProfileArchiveModalOpen = true;
    this.profile = profile || null;
  };

  closeProfileArchiveModal = (): void => {
    this.isProfileArchiveModalOpen = false;
    this.profile = null;
  };

  openRSSLastErrorModal = (profile?: Profile): void => {
    this.isRSSLastErrorModalOpen = true;
    this.profile = profile || null;
  };

  closeRSSLastErrorModal = (): void => {
    this.isRSSLastErrorModalOpen = false;
    this.profile = null;
  };

  changeFilterClickedOnProfile = (filter: Filter): void => {
    this.filterClickedOnProfile = filter;
  };

  fetchLocation = (postalCode: string): Promise<Location> => {
    return requests.fetchLocation(postalCode).then((res) => res.data);
  };

  resetSortBy = (): void => {
    this.sortColumn = 'createdat';
  };

  setSortColumn = (column: string): void => {
    this.sortColumn = column;
  };

  setSortDirection = (dir: SortDirection): void => {
    this.sortDirection = dir;
  };

  setNewlyCreated = (value: boolean): void => {
    this.newlyCreated = value;
  };
}

export default ProfilesStore;
