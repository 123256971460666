import React from 'react';
import clsx from 'clsx';
import { ReactComponent as ErrorIcon } from 'theme/svg/icon-cross-grey.svg';
import { ReactComponent as SuccessIcon } from 'theme/svg/icon-tick-green.svg';

import styles from './MessageBox.module.scss';

type MessageBoxType = 'success' | 'error';

interface MessageBoxProps {
  children: React.ReactNode;
  type?: MessageBoxType;
}

const MessageBox = ({ children, type = 'success' }: MessageBoxProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <div className={clsx(styles.message, styles[type])}>
        <div className={styles.iconBox}>{type === 'error' ? <ErrorIcon /> : <SuccessIcon />}</div>
        {children}
      </div>
    </div>
  );
};

export default MessageBox;
