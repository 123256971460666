import clsx from 'clsx';
import React from 'react';
import CopyToClipboard from 'theme/atoms/copyToClipboard/CopyToClipboard';
import { ReactComponent as EnterArrow } from 'theme/svg/enter-arrow.svg';
import { ReactComponent as ImageIcon } from 'theme/svg/image.svg';
import { ReactComponent as QuestionIcon } from 'theme/svg/question-mark-circle.svg';
import { ReactComponent as RssIcon } from 'theme/svg/rss.svg';
import { ReactComponent as SitemapIcon } from 'theme/svg/sitemap.svg';
import { ReactComponent as TwitterIcon } from 'theme/svg/twitter.svg';
import { Profile, ProfileNameInfoLabel } from 'utils/types/Profile';
import { SourceKind } from 'utils/types/SourceKind';

import styles from './ProfileName.module.scss';

type ProfileNameProps = {
  imageUrl?: string;
  infoLabels?: ProfileNameInfoLabel[];
  isCurrentParent?: boolean;
  isSearchListElement?: boolean;
  onWarningLabelClick?: () => void;
  source: string;
  sourceKind: SourceKind;
  title?: string;
  twitterUsername?: string;
  parent?: Profile | null;
};

const ProfileName = ({
  imageUrl,
  infoLabels = [],
  isCurrentParent,
  isSearchListElement,
  onWarningLabelClick,
  parent,
  source,
  sourceKind,
  title,
  twitterUsername,
}: ProfileNameProps): JSX.Element => {
  const renderIcon = (): JSX.Element | null => {
    if (twitterUsername) return <TwitterIcon />;
    else if (sourceKind === 'RSS') return <RssIcon />;
    else if (sourceKind === 'Sitemap') return <SitemapIcon />;
    else return null;
  };

  return (
    <div
      className={clsx(styles.root, {
        [styles.searchListElement]: isSearchListElement,
        [styles.clickable]: isSearchListElement && !isCurrentParent,
      })}
    >
      <div className={styles.imgContainer}>
        <div
          className={clsx(styles.imgCircle, { [styles.fallbackAvatar]: !imageUrl })}
          style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
        >
          {!imageUrl ? <ImageIcon /> : null}
        </div>
      </div>
      <div className={styles.feedNameAndSourceContainer}>
        <span className={clsx(styles.feedName, { [styles.feedNameSmall]: isSearchListElement })}>{title || ''}</span>
        <div className={clsx(styles.sourceContainer, { [styles.forceNewLine]: twitterUsername })}>
          <div
            className={clsx(styles.sourceKindContainer, {
              [styles.sourceKindContainerSmall]: isSearchListElement,
            })}
          >
            {renderIcon()}
            {twitterUsername ? (
              <span className={clsx(styles.twitterName, { [styles.twitterNameSmall]: isSearchListElement })}>
                {twitterUsername}
              </span>
            ) : null}
          </div>
          <div className={clsx(styles.textCell, styles.copyToClipboardCell)}>
            <CopyToClipboard url={source} />{' '}
          </div>
        </div>
        {parent?.source && parent?.title ? (
          <div className={styles.parentLinkRow}>
            <EnterArrow className={styles.enterArrow} />
            <a className={styles.parentLink} href={parent.source}>
              {parent.title}
            </a>
          </div>
        ) : null}
        {infoLabels.length > 0 && (
          <div className={styles.infoLabelsContainer}>
            {infoLabels.map(({ text, type }, i) => (
              <div
                key={i}
                className={clsx(styles.infoLabel, styles[`infoLabel${type}`])}
                onClick={() => {
                  if (type === 'Warning') onWarningLabelClick?.();
                }}
              >
                {text}
                {type === 'Warning' && <QuestionIcon />}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileName;
