import { RootStore } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import { makeAutoObservable } from 'mobx';
import { DEFAULT_TARGET_CATEGORIES } from 'utils/constants';
import env from 'utils/env';
import { isAxiosError } from 'utils/isAxiosError';
import { isCategorySpamOrConfusing, isConfusing, isSpam } from 'utils/isCategorySpamOrConfusing';
import { toastMessages } from 'utils/toastMessages';

import * as requests from './annotatorsRequests';
import {
  Annotator,
  AnnotatorAssignment,
  AnnotatorAssignmentDashboard,
  AnnotatorAssignmentStatus,
  AnnotatorWizard,
  BulkItem,
  CSVBulkItem,
  RawItemCategory,
} from './types';

class AnnotatorsStore {
  rootStore: RootStore;

  isLoadingAnnotatorAssignmentDashboard = false;
  isLoadingAnnotatorAssignments = false;
  isLoadingAnnotator = false;
  isLoadingAnnotators = false;
  isWizardSubmitting = false;
  isLoadingItemCategories = false;

  annotatorAssignmentDashboard: AnnotatorAssignmentDashboard[] = [];
  annotatorAssignmentDashboardCount = 0;

  annotatorAssignments: AnnotatorAssignment[] = [];
  annotatorAssignmentCount = 0;

  annotator: Annotator | null = null;
  annotators: Annotator[] = [];
  annotatorsCount = 0;

  isAnnotatorWizardModalOpen = false;
  isAnnotatorDeleteModalOpen = false;
  isSubmitting = false;

  pageSize = 10;
  pageNumber = 0;

  itemCategories: RawItemCategory[] = [];
  targetCategories: string[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchItemCategories = (): Promise<RawItemCategory[] | void> => {
    this.isLoadingItemCategories = true;

    return requests
      .fetchItemCategories()
      .then(({ data }) => {
        this.itemCategories = data;
        return data;
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isLoadingItemCategories = false;
      });
  };

  addTargetCategory = (category: string): void => {
    this.targetCategories = [...this.targetCategories, category];
  };

  removeTargetCategory = (category: string): void => {
    this.targetCategories = this.targetCategories.filter((c) => c !== category);
  };

  setTargetCategories = (categories: string[]): void => {
    this.targetCategories = categories;
  };

  get availableCategories(): RawItemCategory[] {
    return this.itemCategories
      .filter((ic) => isCategorySpamOrConfusing(ic.name))
      .filter((ic) => !this.targetCategories.find((tc) => tc === ic.name));
  }

  fetchAnnotator = (id: string): Promise<Annotator | void> => {
    this.isLoadingAnnotator = true;

    return Promise.all([requests.fetchAnnotators(1, 1, id), requests.fetchAnnotatorAssignmentDashboard(1, 1, id)])
      .then(([annotatorResponse, dashboardResponse]) => {
        const annotator = annotatorResponse.data.results[0];
        const dashboard = dashboardResponse.data.results[0];

        this.annotator = { ...annotator, dashboard };
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isLoadingAnnotator = false;
      });
  };

  fetchAnnotators = (pageSize: number, pageNumber: number): Promise<void> => {
    this.isLoadingAnnotators = true;

    return Promise.all([
      requests.fetchAnnotators(pageSize, pageNumber),
      requests.fetchAnnotatorAssignmentDashboard(pageSize, pageNumber),
    ])
      .then(([annotatorsResponse, dashboardsResponse]) => {
        this.annotators = annotatorsResponse.data.results.map((annotator, index) => ({
          ...annotator,
          dashboard: dashboardsResponse.data.results[index],
        }));
        this.annotatorsCount = annotatorsResponse.data.count;
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isLoadingAnnotators = false;
      });
  };

  deleteAnnotator = (id: string): Promise<void> => {
    this.isSubmitting = true;

    return requests
      .deleteAnnotator(id)
      .then(() => {
        this.fetchAnnotators(this.pageSize, this.pageNumber + 1);
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  detectState = (assignment: AnnotatorAssignment): AnnotatorAssignmentStatus => {
    const annotatedCategories = assignment.annotatedItemCategories;

    if (assignment.state === 'Assigned') {
      return 'to do';
    } else if (assignment.state === 'Corrected') {
      if (annotatedCategories.find(isSpam)) return 'spam';
      if (annotatedCategories.find(isConfusing)) return 'confusing';
    }
    return 'done';
  };

  fetchAnnotatorAssignments = (
    pageSize: number,
    pageNumber: number,
    annotatorId?: string
  ): Promise<AnnotatorAssignment[] | void> => {
    this.isLoadingAnnotatorAssignments = true;

    return requests
      .fetchAnnotatorAssignments(pageSize, pageNumber, annotatorId)
      .then(({ data }) => {
        this.annotatorAssignmentCount = data.count;
        this.annotatorAssignments = data.results.map((r) => {
          return { ...r, status: this.detectState(r) };
        });

        return data.results;
      })
      .catch(this.rootStore.handleAxiosError)
      .finally(() => (this.isLoadingAnnotatorAssignments = false));
  };

  formatCSVDataIntoItems = (items: CSVBulkItem[]): BulkItem[] => {
    try {
      return items.map(
        ({
          content,
          contentUrl,
          externalid,
          externalsystemname,
          keyPoints,
          imageurl,
          itemcategories,
          openaiResponseJson,
          title,
        }) => ({
          contentUrl: contentUrl.trim(),
          externalId: externalid.trim(),
          externalSystemName: externalsystemname.trim(),
          keyPoints: keyPoints ? JSON.parse(keyPoints) : [],
          imageUrl: imageurl.trim(),
          itemCategories: JSON.parse(itemcategories),
          openaiResponseJson: openaiResponseJson ? openaiResponseJson.trim() : '',
          text: content.trim(),
          title: title.trim(),
        })
      );
    } catch (error) {
      throw new Error(toastMessages.BULK_IMPORT.CSV_ERROR);
    }
  };

  createAnnotatorWizard = async (
    annotatorName: string,
    annotatorCount: number,
    targetCategories: string[],
    items: CSVBulkItem[]
  ): Promise<AnnotatorWizard | void> => {
    this.isWizardSubmitting = true;

    try {
      await requests.createAnnotatorWizard({
        annotatorCount,
        assignmentMethod: 'AllToAll',
        name: annotatorName,
        targetCategories: targetCategories.length
          ? targetCategories.concat(Object.values(DEFAULT_TARGET_CATEGORIES))
          : null,
        items: this.formatCSVDataIntoItems(items),
      });

      this.pageNumber = 0;
      this.fetchAnnotators(this.pageSize, 1);
      this.closeAnnotatorWizardModal();
    } catch (err) {
      if (isAxiosError(err)) this.rootStore.handleAxiosError(err);
      throw err;
    } finally {
      this.isWizardSubmitting = false;
    }
  };

  closeAnnotatorWizardModal = (): void => {
    this.isAnnotatorWizardModalOpen = false;
    this.targetCategories = [];
  };

  openAnnotatorWizardModal = (): void => {
    this.isAnnotatorWizardModalOpen = true;
  };

  closeAnnotatorDeleteModal = (): void => {
    this.isAnnotatorDeleteModalOpen = false;
  };

  openAnnotatorDeleteModal = (): void => {
    this.isAnnotatorDeleteModalOpen = true;
  };

  changePageSize = (newPageSize: number): void => {
    if (newPageSize !== this.pageSize) this.pageSize = newPageSize;
  };

  changePageNumber = (newPageNumber: number): void => {
    if (newPageNumber !== this.pageNumber) this.pageNumber = newPageNumber;
  };

  resetPagination = (): void => {
    this.pageNumber = 0;
    this.pageSize = 10;
  };

  generateAnnotatorMisclassificationsLink = (token: string): string => {
    const base = env.appEnvironment === 'staging' ? `${location.origin}/app` : location.origin;
    return `${base}${paths.misclassificationsAuthChecker}?token=${token}`;
  };
}

export default AnnotatorsStore;
