import { Annotator } from 'annotators/types';
import clsx from 'clsx';
import React, { useEffect, CSSProperties } from 'react';
import Tooltip from 'theme/atoms/tooltip';
import { calculateAnnotatorResults } from 'utils/calculateAnnotatorResults';
import { capitalize } from 'utils/capitalize';
import { roundToDecimal } from 'utils/roundToDecimal';

import styles from './AnnotatorProgressBar.module.scss';

const PROGRESS_BAR_WIDTH = 157;

interface CountBarProps {
  style: CSSProperties;
  tooltipText: string;
  type: string;
  visiblePartPxWidth: number;
}

const CountBar = ({ style, tooltipText, type, visiblePartPxWidth }: CountBarProps): JSX.Element => {
  return (
    <div className={clsx(styles.bar, styles.countBar, styles[type])} style={style}>
      <div className={styles.countBarInner} style={{ width: `${visiblePartPxWidth}px` }}>
        <Tooltip text={tooltipText} />
      </div>
    </div>
  );
};

interface AnnotatorProgressBarProps {
  annotator: Annotator;
  lightTrackColor?: boolean;
}

const AnnotatorProgressBar = ({ annotator, lightTrackColor = false }: AnnotatorProgressBarProps): JSX.Element => {
  const { count, ratio } = calculateAnnotatorResults(annotator);

  const countArray = Object.entries(count)
    .map(([key, value]) => ({
      key,
      value,
      pxWidth: roundToDecimal(ratio[key] * PROGRESS_BAR_WIDTH, 2),
    }))
    .filter((item) => item.key !== 'all');

  const doneIndex = countArray.findIndex((obj) => obj.key === 'done');
  const doneElement = countArray.splice(doneIndex, 1)[0];
  const sortedCountArray = [doneElement, ...countArray];

  const countTotalPercentBarWidth = (index: number, value: number): number => {
    const totalPrevBarsPercentWidth = sortedCountArray
      .filter((item, itemIndex) => itemIndex < index)
      .reduce((a, b) => a + b.value, 0);

    return totalPrevBarsPercentWidth + value;
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--progress-bar-width', `${PROGRESS_BAR_WIDTH}px`);
  }, []);

  return (
    <div className={clsx(styles.bar, styles.root, { [styles.lightTrack]: lightTrackColor })}>
      {sortedCountArray.map(({ key, value, pxWidth }, i) => {
        const tooltipText = `${capitalize(key)} ${roundToDecimal((value / count.all) * 100, 2)}%`;

        return (
          <CountBar
            key={i}
            style={{
              width: `${(countTotalPercentBarWidth(i, value) * 100) / count.all}%`,
              zIndex: sortedCountArray.length - i,
            }}
            tooltipText={tooltipText}
            type={key}
            visiblePartPxWidth={pxWidth}
          />
        );
      })}
    </div>
  );
};

export default AnnotatorProgressBar;
