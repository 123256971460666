import { useStores } from 'RootStore';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ProfileName from 'profiles/ProfileName/ProfileName';
import React, { useState, useRef, useEffect } from 'react';
import Input from 'theme/atoms/inputs/Input';
import SvgSpinner from 'theme/atoms/loaders/SvgSpinner';
import { ReactComponent as SearchIcon } from 'theme/svg/search.svg';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { Profile } from 'utils/types/Profile';

import styles from './ProfileParentSearch.module.scss';

interface ProfileParentSearchProps {
  handleParent: (parent: Profile) => void;
  profile: Profile | null;
}

const ProfileParentSearch = ({ handleParent, profile }: ProfileParentSearchProps): JSX.Element => {
  const { profilesStore } = useStores();
  const {
    fetchSearchedParentProfiles,
    isFetchingProfiles,
    isSubmitting,
    resetSearchedParentProfiles,
    searchedParentProfiles,
  } = profilesStore;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { profileType } = useParams();

  const closeDropdown = (): void => {
    if (searchedParentProfiles.length > 0) resetSearchedParentProfiles();

    setIsDropdownOpen(false);
    setSearchText('');
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useOnClickOutside({ ref: dropdownRef, handler: () => closeDropdown() });

  const handleSearch = (value: string): void => {
    if (!value) resetSearchedParentProfiles();
    else
      fetchSearchedParentProfiles(value, profileType ?? '').catch(() =>
        setErrorMessage('Ooops! Something went wrong when fetching feeds.')
      );

    setSearchText(value);
  };

  const selectParent = (parent: Profile): void => {
    handleParent(parent);
    closeDropdown();
  };

  useEffect(() => {
    if (isDropdownOpen && inputRef.current) inputRef.current.focus();
  }, [isDropdownOpen]);

  return (
    <div className={styles.root}>
      <button
        type="button"
        className={clsx(styles.btn, 'btn-mini-reverted')}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {profile ? 'Select another' : 'Select Parent'}
      </button>

      {isDropdownOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={styles.searchBox}>
            <Input
              className={styles.searchInput}
              name="searchFilters"
              type="text"
              ref={inputRef}
              value={searchText}
              placeholder="Search feeds"
              startIcon={<SearchIcon />}
              onChange={(val: string) => handleSearch(val)}
            />
          </div>

          {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

          <div className={styles.profileList}>
            {isFetchingProfiles ? (
              <div className={styles.profileListLoaderBox}>
                <SvgSpinner className={styles.loader} />
              </div>
            ) : (
              <ul>
                {searchedParentProfiles.map((p) => (
                  <li key={p.id} className={styles.profileListItem} onClick={() => selectParent(p)}>
                    <ProfileName {...p} isSearchListElement />
                  </li>
                ))}
              </ul>
            )}
          </div>

          {isSubmitting && (
            <div className={styles.loaderBox}>
              <SvgSpinner className={styles.loader} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ProfileParentSearch);
