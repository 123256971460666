import { metadataSectionKinds } from 'metadata';
import { List, ListItem } from 'theme/atoms/multiselect';
import { filtersIcons } from 'utils/constants';
import { countryObj } from 'utils/constants';
import { Country } from 'utils/types/Country';
import { MetadataSectionGroup } from 'utils/types/Metadata';

const getDefaultSectionsLists = (sectionsGroups: MetadataSectionGroup[], country: Country | undefined): List[] => {
  return [
    ...metadataSectionKinds.map((sectionKind): List => {
      const sectionGroup = sectionsGroups.find((sectionGroup) => sectionGroup.kind === sectionKind);

      let items: ListItem[] = [];
      if (sectionGroup) {
        items = sectionGroup.items.map(({ id, title, region, level }) => ({
          id,
          title,
          checked: true,
          hide: false,
          region: region || '',
          level: level || '',
        }));
      }

      const icon = filtersIcons[sectionKind] || filtersIcons.League;

      return {
        title: sectionKind,
        kind: sectionKind,
        items,
        fetchedItems: [],
        open: false,
        fetched: false,
        page: 1,
        isLoading: false,
        isLoadingNextPage: true,
        error: null,
        type: 'dynamic',
        icon,
      };
    }),
    {
      ...countryObj,
      type: 'static',
      items: countryObj.items.map((item) => {
        if (country && item.id === country) {
          return { ...item, checked: true };
        } else return item;
      }),
    },
  ];
};

export default getDefaultSectionsLists;
