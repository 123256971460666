import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import Input from 'theme/atoms/inputs/Input';
import SvgSpinner from 'theme/atoms/loaders/SvgSpinner';
import { ReactComponent as SearchIcon } from 'theme/svg/search.svg';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';

import { List, ListItem } from '../types';
import styles from './Dropdown.module.scss';
import FilterItem from './FilterItem/FilterItem';

interface DropdownProps {
  list: List;
  searchText: string;
  onChangeSearchText: (value: string) => void;
  toggleFilterCheck: (item: ListItem) => void;
  onNextPage: () => void;
  wide?: boolean;
}

const Dropdown = (
  { list, searchText, onChangeSearchText, toggleFilterCheck, onNextPage, wide }: DropdownProps,
  ref
): JSX.Element => {
  const checkedItems = list.items.filter((el) => el.checked);
  const nonCheckedItems = list.items.filter((el) => !el.checked);
  const [lastElement, setLastElement] = useState('');

  const { changeLastElement } = useInfiniteScroll({
    callback: (id: string) => setLastElement(id),
    shouldRunCallback: list.fetchedItems.length % 10 === 0,
  });

  useEffect(() => {
    if (lastElement && list.page) onNextPage();
  }, [lastElement]);

  useEffect(() => {
    if (list.page) onNextPage();
  }, []);

  return (
    <div className={clsx(styles.filterDropdown, { [styles.wide]: wide })}>
      <div className={styles.searchBox}>
        <Input
          name="searchFilters"
          type="text"
          value={searchText}
          placeholder="Search"
          startIcon={<SearchIcon />}
          onChange={(val: string) => onChangeSearchText(val)}
        />
      </div>
      <div className={styles.belowSearchBox} ref={ref}>
        {list.error ? (
          <p className={styles.errorMessage}>{list.error.message}</p>
        ) : (
          <>
            {list.fetched && (
              <>
                {!list.items.length ? (
                  <div className={styles.recentTitle}>NO RESULTS</div>
                ) : (
                  <>
                    {checkedItems.length ? (
                      <div className={styles.checkedItems}>
                        {checkedItems
                          .filter((it: ListItem) => !it.hide)
                          .map((it, i) => (
                            <FilterItem
                              key={it.id}
                              i={i}
                              item={it}
                              toggleFilterCheck={toggleFilterCheck}
                              wide={wide}
                              kind={list.kind}
                            />
                          ))}
                      </div>
                    ) : null}
                    {nonCheckedItems.filter((it) => !it.hide).length ? (
                      <div className={styles.recentTitle}>RECENT</div>
                    ) : null}
                    <div className={styles.recentItems}>
                      {list.isLoading && <div className={styles.itemsLoader} />}
                      {nonCheckedItems
                        .filter((it) => !it.hide)
                        .map((item, i) => (
                          <FilterItem
                            key={`${item.id}${i}`}
                            i={i}
                            item={item}
                            toggleFilterCheck={toggleFilterCheck}
                            ref={nonCheckedItems.length === i + 1 ? changeLastElement : undefined}
                            wide={wide}
                            kind={list.kind}
                          />
                        ))}
                    </div>
                  </>
                )}
              </>
            )}
            {!list.isLoading && list.isLoadingNextPage && (
              <div className={styles.loaderBox}>
                <SvgSpinner className={styles.loader} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default forwardRef(Dropdown);
