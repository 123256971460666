import { useStores } from 'RootStore';
import { clearAllCache } from 'api';
import { paths } from 'app/routes/paths.const';
import clsx from 'clsx';
import { useToast } from 'contexts/ToastProvider';
import MisclassificationsPost from 'misclassifications/MisclassificationsPost/MisclassificationsPost';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IconButton from 'theme/atoms/buttons/IconButton';
import Spinner from 'theme/atoms/loaders/Spinner';
import MessageBox from 'theme/atoms/messageBox';
import { ReactComponent as BlockIcon } from 'theme/svg/block.svg';
import { ReactComponent as InfoIcon } from 'theme/svg/info.svg';
import { ReactComponent as Logo } from 'theme/svg/logo.svg';
import { ReactComponent as SadIcon } from 'theme/svg/sad.svg';
import { ReactComponent as TickIcon } from 'theme/svg/tick-white.svg';
import { assignmentsPageSize } from 'utils/constants';

import MarkAsConfusingModal from './MarkAsConfusingModal';
import MarkAsSpamModal from './MarkAsSpamModal';
import styles from './Misclassifications.module.scss';
import MisclassificationsManual from './MisclassificationsManual';
import MisclassificationsPanel from './MisclassificationsPanel';

const TOAST_TIMEOUT = 2500;

const Misclassifications = (): JSX.Element => {
  const navigate = useNavigate();
  const { assignmentId } = useParams();
  const [searchParams] = useSearchParams();
  const { addToast, toastMessages } = useToast();
  const [isManualShown, setIsManualShown] = useState(false);

  const { misclassificationsStore } = useStores();
  const {
    allAssignmentsCount,
    closeMarkAsSpamModal,
    currentAssignmentIndex,
    fetchAssignments,
    finishMisclassificationsSession,
    goToNextAssignment,
    isItemMarkedAsConfusing,
    isMisclassificationsSessionFinished,
    isSubmitting,
    loadingInitialData,
    openMarkAsConfusingModal,
    openMarkAsSpamModal,
    pageNumber,
    post,
    remainingAssignmentsCount,
    removeBasicAuthToken,
    resetMisclassificationsSession,
    revertMarkItemAsConfusing,
    setAllAssignmentsCount,
    setInitialRemainingAssignmentsCount,
  } = misclassificationsStore;

  const authToken = searchParams.get('token');

  const toggleManual = (): void => {
    setIsManualShown(!isManualShown);
  };

  const navigateToAssignment = (assignmentId: string): void => {
    navigate(`${paths.misclassificationsSession(assignmentId)}?token=${authToken}`);
  };

  const finishSession = (): void => {
    if (remainingAssignmentsCount === 1) {
      finishMisclassificationsSession();
      navigate(`${paths.misclassifications}?token=${authToken}`);
    }
  };

  const submitAssignment = (isSpam: boolean): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    if (authToken) {
      goToNextAssignment(assignmentId ?? '', authToken, isSpam, navigateToAssignment)
        .then(() => {
          addToast(toastMessages.MISCLASSIFICATIONS.SUBMIT_SUCCESS, 'success', TOAST_TIMEOUT);
          finishSession();

          if (isSpam) closeMarkAsSpamModal();
        })
        .catch(() => {
          addToast(toastMessages.MISCLASSIFICATIONS.SUBMIT_ERROR, 'error', TOAST_TIMEOUT);
        });
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchAssignments(authToken)
        .then(({ count, results }) => {
          if (!!results && results.length > 0) navigateToAssignment(results[0].id);
          setAllAssignmentsCount(count);
          setInitialRemainingAssignmentsCount(count);
        })
        .catch(() => {
          addToast(toastMessages.ASSIGNMENTS.FETCH_ERROR, 'error');
        });
    } else {
      navigate(paths.misclassificationsNoAccess);
    }

    return () => {
      resetMisclassificationsSession();
      removeBasicAuthToken();
      clearAllCache();
    };
  }, []);

  const currentIndex = assignmentsPageSize * (pageNumber - 1) + currentAssignmentIndex + 1;

  const postView = (
    <>
      <div className={styles.actionBar}>
        <div className={clsx(styles.actionBarContent, styles.container)}>
          <div className={styles.postCountBox}>
            {loadingInitialData ? (
              <Spinner small />
            ) : (
              <h2 className={styles.title}>
                Post {currentIndex} of {allAssignmentsCount}
              </h2>
            )}
          </div>

          <div className={styles.buttons}>
            <IconButton
              text="Mark as SPAM"
              icon={<BlockIcon />}
              disabled={loadingInitialData}
              loading={isSubmitting}
              type="error"
              action={openMarkAsSpamModal}
            />
            <IconButton
              className={clsx({ [styles.isConfusing]: isItemMarkedAsConfusing })}
              text={isItemMarkedAsConfusing ? 'Marked as CONFUSING' : 'Mark as CONFUSING'}
              icon={<SadIcon />}
              disabled={loadingInitialData}
              type="warning"
              action={isItemMarkedAsConfusing ? revertMarkItemAsConfusing : openMarkAsConfusingModal}
            />
            <IconButton
              className={styles.markAsCheckedBtn}
              text="Mark as CHECKED"
              disabled={loadingInitialData || isSubmitting}
              loading={isSubmitting}
              icon={<TickIcon />}
              action={() => submitAssignment(false)}
            />
          </div>
        </div>
      </div>
      <div className={clsx(styles.container, styles.main)}>
        {post && <MisclassificationsPost post={post} />}
        <MisclassificationsPanel />
      </div>
    </>
  );

  const mainView = isMisclassificationsSessionFinished ? (
    <MessageBox>
      <span>That's all. Thank you!</span>
    </MessageBox>
  ) : (
    postView
  );

  const loadingMainView = loadingInitialData ? (
    <div className={styles.loadingArea}>
      <Spinner />
    </div>
  ) : (
    mainView
  );

  return (
    <div className={styles.root}>
      <Logo className={styles.logo} />
      <InfoIcon className={styles.infoIcon} onClick={toggleManual} />

      {isManualShown ? <MisclassificationsManual toggleManual={toggleManual} /> : loadingMainView}

      <MarkAsConfusingModal />
      <MarkAsSpamModal confirmSpam={submitAssignment} />
    </div>
  );
};

export default observer(Misclassifications);
