import clsx from 'clsx';
import { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import { ReactComponent as CloseIcon } from 'theme/svg/close-xl.svg';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import styles from './Modal.module.scss';

interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  close: () => void;
  className?: string;
  title?: string;
}

function Modal({ children, isOpen, close, className = '', title }: ModalProps): JSX.Element | null {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside({ ref: modalRef, handler: close });

  if (isOpen) {
    const component = (
      <RemoveScroll>
        <div className={styles.overlay}>
          <div
            className={clsx(styles.modal, className, { [styles.active]: isOpen })}
            ref={modalRef}
            aria-modal="true"
            role="dialog"
          >
            <button onClick={close} className={styles.closeBtn}>
              <CloseIcon />
            </button>

            {title && <h2 className={styles.title}>{title}</h2>}
            {children}
          </div>
        </div>
      </RemoveScroll>
    );

    return ReactDOM.createPortal(component, document.body);
  }

  return null;
}

export default Modal;
