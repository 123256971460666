import { Category } from 'annotators/types';
import { DEFAULT_TARGET_CATEGORIES } from 'utils/constants';

export const isSpam = (category: Category): boolean => category.categoryName === DEFAULT_TARGET_CATEGORIES.spam;
export const isConfusing = (category: Category): boolean =>
  category.categoryName === DEFAULT_TARGET_CATEGORIES.confusing;

export const isCategorySpamOrConfusing = (categoryName: string): boolean => {
  return categoryName !== DEFAULT_TARGET_CATEGORIES.spam && categoryName !== DEFAULT_TARGET_CATEGORIES.confusing;
};
