import React from 'react';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import baseStyles from 'theme/atoms/inputs/base.module.scss';

import styles from './AnnotatorWizardCategoryMultiSelect.module.scss';

type AnnotatorWizardCategoryMultiSelectProps = {
  className?: string;
  selectedCategories: string[];
};

const AnnotatorWizardCategoryMultiSelect = ({
  className = '',
  selectedCategories,
}: AnnotatorWizardCategoryMultiSelectProps): JSX.Element => {
  const { annotatorsStore } = useStores();

  const handleRemoveCategory = (e: React.MouseEvent<HTMLDivElement>, category: string): void => {
    e.stopPropagation();
    annotatorsStore.removeTargetCategory(category);
  };

  return (
    <div className={clsx(styles.root, className)}>
      <span className={clsx(styles.label, baseStyles.label)}>Target categories</span>
      <div className={styles.selectedCategoriesBox}>
        {selectedCategories.map((sc) => (
          <div key={sc} className={styles.categoryLabel} onClick={(e) => handleRemoveCategory(e, sc)}>
            {sc}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AnnotatorWizardCategoryMultiSelect;
