import sanitizeHtml from 'sanitize-html';
import { Post } from 'utils/types/Post';
import { MLResultPost } from 'utils/types/MLResultPost';

import styles from './RSSPost.module.scss';
import RSSPostHeader from './RSSPostHeader/RSSPostHeader';

interface RSSPostProps {
  post: Post | MLResultPost;
}

function RSSPost({ post }: RSSPostProps): JSX.Element {
  const getResource = (): JSX.Element | null => {
    if (!post.imageUrl) return null;

    const withoutQueryParams = post.imageUrl.split('?')[0];
    const extension = withoutQueryParams.split('.').pop();

    if (extension === 'mp3') {
      return (
        <audio controls src={post.imageUrl} className={styles.audio}>
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      );
    } else {
      return (
        <div
          className={styles.photo}
          style={{
            backgroundImage: `url(${post.imageUrl})`,
          }}
        />
      );
    }
  };

  return (
    <div className={styles.rssPost}>
      <RSSPostHeader post={post} />
      <div className={styles.body}>
        <h2 className={styles.title}>{post.title}</h2>
        <p
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.content, { allowedTags: [] }) }}
        />
        {post.url && (
          <a className={styles.readMoreBtn} href={post.url} target="_blank" rel="noreferrer">
            Read full story
          </a>
        )}

        <div>{getResource()}</div>
      </div>
    </div>
  );
}

export default RSSPost;
