import { api, twitterApi } from 'api';
import { AxiosResponse, AxiosInstance } from 'axios';
import { Country } from 'utils/types/Country';
import { Location } from 'utils/types/Location';
import { Profile, ProfileFlags, ProfileRawBEData } from 'utils/types/Profile';
import { SourceKind } from 'utils/types/SourceKind';

export const profilesPerPage = 10;

const API_TO_USE = (isTwitter: boolean): AxiosInstance => (isTwitter ? twitterApi : api);
const getFeedUrl = (isSitemap: boolean): string => `/feed/${isSitemap ? 'sitemap' : 'config'}`;

interface FetchProfilesProps {
  country?: Country;
  createdAt?: string;
  isAutopostable?: boolean;
  isBackload?: boolean;
  metadataId: string[];
  mlCategorization?: boolean;
  page: number;
  size?: number;
  sortBy: string;
  source?: string;
  sourceKind?: SourceKind;
  teamIdentification?: boolean;
  textSearch: string;
  title?: string;
}

type FetchProfilesReturn = Promise<AxiosResponse<{ data: ProfileRawBEData[]; total: number }>>;

export function fetchProfiles(
  {
    country,
    createdAt,
    isAutopostable,
    isBackload,
    metadataId,
    page,
    size = profilesPerPage,
    sortBy,
    source,
    textSearch,
    title,
  }: FetchProfilesProps,
  profileType: string
): FetchProfilesReturn {
  const reqApi = API_TO_USE(profileType === 'twitter');

  const handleSourceKind = (): Record<string, SourceKind> | undefined => {
    if (profileType === 'rss') return { sourceKind: 'RSS' };
    else if (profileType === 'twitter') return { sourceKind: 'Twitter' };
    else return;
  };

  return reqApi.get(getFeedUrl(profileType === 'sitemap'), {
    params: {
      country,
      createdAt,
      isAutopostable,
      isBackload,
      metadataId,
      number: page,
      size,
      sortBy,
      source,
      ...handleSourceKind(),
      textSearch,
      title,
    },
  });
}

export interface ProfileDto {
  country?: string;
  flags?: ProfileFlags;
  latitude?: number;
  longitude?: number;
  parent?: string | null;
  postalCode: string | null;
  sectionIds: string[];
  source: string;
  userLabels: string[];
}

export function fetchProfile(id: string, source: string): Promise<ProfileRawBEData> {
  const reqApi = API_TO_USE(source.includes('twitter'));
  return reqApi.get(`${getFeedUrl(source === 'Sitemap')}?id=${id}`).then(({ data }) => data.data[0]);
}

export function addProfile(data: ProfileDto, profileType: string): Promise<AxiosResponse<Profile>> {
  const reqApi = API_TO_USE(profileType === 'twitter');
  return reqApi.post(getFeedUrl(profileType === 'sitemap'), data);
}

export function updateProfile(id: string, data: ProfileDto): Promise<AxiosResponse<Profile>> {
  const reqApi = API_TO_USE(data.source.includes('twitter'));
  return reqApi.patch(`${getFeedUrl(data.source === 'Sitemap')}/${id}`, data);
}

export function deleteProfile(id: string, source: string): Promise<AxiosResponse<Profile>> {
  const reqApi = API_TO_USE(source.includes('twitter'));
  return reqApi.delete(`${getFeedUrl(source === 'Sitemap')}/${id}`);
}

export function fetchLocation(postalCode: string): Promise<AxiosResponse<Location>> {
  return api.get(`/postalcode/${postalCode}`);
}
