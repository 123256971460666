import Annotators from 'annotators';
import AnnotatorDetails from 'annotators/AnnotatorDetails';
import Login from 'auth/login';
import Misclassifications from 'misclassifications/Misclassifications';
import MisclassificationsAuthChecker from 'misclassifications/MisclassificationsAuthChecker';
import MisclassificationsNoAccess from 'misclassifications/MisclassificationsNoAccess';
import Posts from 'posts';
import { Profiles } from 'profiles';

import { BasicAuthRoutes, PublicRoutes, PrivateRoutes, Routes } from './models';
import { paths } from './paths.const';

export const publicRoutes: PublicRoutes = {
  login: {
    path: paths.login,
    element: <Login />,
    public: true,
  },
  misclassificationsAuthChecker: {
    path: paths.misclassificationsAuthChecker,
    public: true,
    element: <MisclassificationsAuthChecker />,
    isMisclassifications: true,
  },
  misclassificationsNoAccess: {
    path: paths.misclassificationsNoAccess,
    element: <MisclassificationsNoAccess />,
    public: true,
    isMisclassifications: true,
  },
};

export const basicAuthRoutes: BasicAuthRoutes = {
  misclassifications: {
    path: paths.misclassifications,
    public: false,
    element: <Misclassifications />,
    isMisclassifications: true,
  },
  misclassificationsSession: {
    path: paths.misclassificationsSession(':assignmentId'),
    element: <Misclassifications />,
    public: false,
    isMisclassifications: true,
  },
};

export const privateRoutes: PrivateRoutes = {
  annotator: {
    path: paths.annotator(':id'),
    element: <AnnotatorDetails />,
    public: false,
  },
  annotators: {
    path: paths.annotators,
    element: <Annotators />,
    public: false,
  },
  feed: {
    path: paths.feed,
    element: <Posts />,
    public: false,
  },
  profiles: {
    path: paths.profiles,
    element: <Profiles />,
    public: false,
  },
  profileType: {
    path: paths.profileType(':profileType'),
    element: <Profiles />,
    public: false,
  },
};

export const routes: Routes = {
  ...basicAuthRoutes,
  ...publicRoutes,
  ...privateRoutes,
};
