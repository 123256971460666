import React, { useEffect, ReactElement, ReactNode, ReactPortal } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { useToast } from 'contexts/ToastProvider';
import { ReactComponent as TickIcon } from 'theme/svg/tick-white.svg';
import { ReactComponent as SadIcon } from 'theme/svg/sad-white.svg';
import { ReactComponent as WarningIcon } from 'theme/svg/exclamation-mark.svg';

import styles from './ToastContainer.module.scss';

export type ToastType = 'success' | 'error' | 'warning';

interface ToastProps {
  children: ReactNode;
  id: string;
  key: string;
  timeout?: number;
  type?: ToastType;
}

const ICON_MAP = {
  error: <SadIcon />,
  success: <TickIcon />,
  warning: <WarningIcon />,
};

function ToastComponent({ children, id, timeout = 6000, type = 'success' }: ToastProps): ReactElement {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => removeToast(id), timeout);
    return () => clearTimeout(timer);
  }, [id, removeToast, timeout]);

  return (
    <div className={clsx(styles.toast, styles[type])}>
      {ICON_MAP[type]}
      <div className={styles.textContainer}>{children}</div>
    </div>
  );
}

export interface Toast {
  content: string;
  id: string;
  timeout?: number;
  type?: ToastType;
}

interface ToastContainerProps {
  toasts: Array<Toast>;
}

const ToastContainer = ({ toasts }: ToastContainerProps): ReactPortal => {
  return createPortal(
    <div className={styles.root}>
      {toasts.map(({ id, content, type, timeout }) => (
        <ToastComponent key={id} id={id} type={type} timeout={timeout}>
          {content}
        </ToastComponent>
      ))}
    </div>,
    document.body
  );
};

export default ToastContainer;
