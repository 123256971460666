import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import Tag from 'theme/atoms/tag';
import { ReactComponent as TagIcon } from 'theme/svg/tag.svg';
import { filtersIcons } from 'utils/constants';
import { Metadata, MetadataSection } from 'utils/types/Metadata';
import { Post as PostType } from 'utils/types/Post';

import styles from './Post.module.scss';
import RSSPost from './RSSPost/RSSPost';
import TwitterPost from './TwitterPost/TwitterPost';

interface PostProps {
  onLabelClick: (item: MetadataSection | Metadata, listTitle: string) => void;
  post: PostType;
  selected: boolean;
}

const Post = ({ onLabelClick, post, selected }: PostProps, ref): JSX.Element => {
  const { id, sectionsGroups, sourceKind, userLabels } = post;
  const areThereFilters = useMemo(
    () => userLabels?.length > 0 || sectionsGroups.some((sg) => sg.items.length > 0),
    [userLabels, sectionsGroups]
  );

  return (
    <div id={id} className={clsx(styles.root, { [styles.selected]: selected })} ref={ref}>
      <div className={styles.content}>
        <div className={clsx(styles.filters, { [styles.areThereFilters]: areThereFilters })}>
          <div className={styles.filtersList}>
            {userLabels?.map((u) => (
              <div key={u.id} className={styles.tagBox}>
                <Tag icon={<TagIcon />} text={u.title} onTagClick={() => onLabelClick(u, 'Your Labels')} />
              </div>
            ))}
            {sectionsGroups.map((sg) => {
              const Icon = filtersIcons[sg.kind] || filtersIcons.League;

              return sg.items.map((item) => (
                <div key={item.id} className={styles.tagBox}>
                  <Tag icon={<Icon />} text={item.title} onTagClick={() => onLabelClick(item, item.kind)} />
                </div>
              ));
            })}
          </div>
        </div>
        {sourceKind === 'RSS' ? <RSSPost post={post} /> : <TwitterPost post={post} />}
      </div>
    </div>
  );
};

export default observer(Post, { forwardRef: true });
