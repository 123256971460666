import { ChangeEvent } from 'react';
import clsx from 'clsx';

import styles from './Checkbox.module.scss';
import { CheckboxProps } from '../types';
import baseStyles from '../base.module.scss';

function Checkbox({
  checked,
  className = '',
  disabled,
  error,
  label,
  labelClassName = '',
  name,
  onChange,
}: CheckboxProps): JSX.Element {
  const handleChange = (value: boolean) => {
    onChange?.(value);
  };

  const controlledProps = onChange
    ? { checked, onChange: (e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked) }
    : {};

  return (
    <div className={className}>
      <div className={styles.formCheckbox}>
        <input
          id={name}
          aria-invalid={error ? 'true' : 'false'}
          type="checkbox"
          className={clsx(styles.checkbox, { [styles.disabled]: disabled })}
          disabled={disabled}
          {...controlledProps}
        />
        <span className={styles.formCheckboxCheckmark} />
        <label htmlFor={name} id={name} className={clsx(styles.label, labelClassName, { [styles.disabled]: disabled })}>
          {label}
        </label>
      </div>
      {error && <p className={baseStyles.errorMessage}>{error}</p>}
    </div>
  );
}

export default Checkbox;
