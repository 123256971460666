import clsx from 'clsx';
import { PossibleValue } from 'misclassifications/types';
import React from 'react';
import { ReactComponent as FeedIcon } from 'theme/svg/feed.svg';
import { ReactComponent as MLIcon } from 'theme/svg/ml.svg';

import PanelCheckbox from '../PanelCheckbox';
import styles from './MisclassificationsPanelRow.module.scss';

type ClassificationLabelType = 'feed' | 'ml';

interface ClassificationLabelProps {
  onClick?: () => void;
  type: ClassificationLabelType;
}

const ClassificationLabel = ({ onClick, type }: ClassificationLabelProps): JSX.Element => {
  return (
    <div className={clsx(styles.classificationLabel, styles[type])} onClick={onClick}>
      {type === 'feed' ? <FeedIcon /> : <MLIcon />}
    </div>
  );
};

interface MisclassificationsPanelRowProps {
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  isSuggestedFromFeed?: boolean;
  isSuggestedFromML?: boolean;
  onClick: () => void;
  possibleValue: PossibleValue;
}

const MisclassificationsPanelRow = ({
  checked = false,
  disabled = false,
  className = '',
  isSuggestedFromFeed,
  isSuggestedFromML,
  onClick,
  possibleValue,
}: MisclassificationsPanelRowProps): JSX.Element => {
  return (
    <div className={clsx(styles.root, className)}>
      <PanelCheckbox
        label={possibleValue.title}
        region={possibleValue.region}
        checked={checked}
        onClick={onClick}
        className={styles.panelCheckbox}
        disabled={disabled}
      />
      {isSuggestedFromFeed && <ClassificationLabel type="feed" />}
      {isSuggestedFromML && <ClassificationLabel type="ml" />}
    </div>
  );
};

export default MisclassificationsPanelRow;
