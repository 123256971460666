import clsx from 'clsx';
import { ReactComponent as Spinner } from 'theme/svg/spinner.svg';

import styles from './SvgSpinner.module.scss';

interface SvgSpinnerProps {
  className?: string;
}

function SvgSpinner({ className = '' }: SvgSpinnerProps): JSX.Element {
  return <Spinner className={clsx(styles.spinner, className)} />;
}

export default SvgSpinner;
