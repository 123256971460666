import { Error } from 'utils/types/Error';

import { FormData, ValidationErrors } from '../profileFormTypes';

export const validateLocation = ({
  latitude,
  longitude,
}: {
  latitude: string;
  longitude: string;
}): {
  isError: boolean;
  errors: {
    latitude?: Error;
    longitude?: Error;
  };
} => {
  let isError = false;
  let errors = {};

  if (latitude !== '' || longitude !== '') {
    if (latitude === '') {
      errors = {
        ...errors,
        latitude: {
          message: 'Latitude is required',
        },
      };

      isError = true;
    } else if (Number(latitude) < -90 || Number(latitude) > 90) {
      errors = {
        ...errors,
        latitude: {
          message: 'Latitude must be between -90 and 90',
        },
      };

      isError = true;
    }

    if (longitude === '') {
      errors = {
        ...errors,
        longitude: {
          message: 'Longitude is required',
        },
      };

      isError = true;
    } else if (Number(longitude) < -180 || Number(longitude) > 180) {
      errors = {
        ...errors,
        longitude: {
          message: 'Longitude must be between -180 and 180',
        },
      };

      isError = true;
    }
  }

  return {
    isError,
    errors,
  };
};

const validateProfileForm = (formData: FormData): { isError: boolean; errors: ValidationErrors } => {
  let isError = false;
  let errors: ValidationErrors = {};

  if (formData.source === '') {
    errors = {
      ...errors,
      source: {
        message: 'Source is required',
      },
    };

    isError = true;
  }

  const { isError: isLocationError, errors: locationErrors } = validateLocation({
    latitude: formData.latitude,
    longitude: formData.longitude,
  });

  if (isLocationError) isError = true;

  errors = {
    ...errors,
    ...locationErrors,
  };

  return {
    isError,
    errors,
  };
};

export default validateProfileForm;
