import { ChangeEvent, useRef } from 'react';
import clsx from 'clsx';

import styles from './TextArea.module.scss';
import baseStyles from '../base.module.scss';
import { TextAreaProps } from '../types';

const TextArea = ({
  className,
  // color,
  disabled,
  disableEnter,
  error,
  forwardRef,
  helperText,
  label,
  maxLength,
  messageClassName,
  name,
  onChange,
  onEnter,
  onKeyUp,
  placeholder,
  readOnly,
  rows = 3,
  // size,
  textFieldClassName,
  value,
  ...rest
}: TextAreaProps): JSX.Element => {
  const ref = forwardRef || useRef<HTMLTextAreaElement | null>(null);

  const fitContent = (): void => {
    if (ref.current) {
      ref.current.style.height = `auto`;
      ref.current.style.height = `${ref.current.scrollHeight + 5}px`;
    }

    //using setTimeout because of a bug on chromium that doesn't get the right height immediately
    setTimeout(() => {
      if (ref.current) {
        //setting height to auto and changing it to pixels is on purpose, to get correct scrollHeight
        ref.current.style.height = `auto`;
        ref.current.style.height = `${ref.current.scrollHeight + 5}px`;
      }
    }, 100);
  };

  const onRender = (el: HTMLTextAreaElement | null): void => {
    if (el) {
      ref.current = el;
      fitContent();
      window.addEventListener('resize', fitContent);
    }
  };

  const handleChange = (value: string): void => {
    disableEnter && (value = value.replace(/[\r\n]/gm, ''));
    onChange && onChange(value);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter' && onEnter && value) onEnter();
    onKeyUp && onKeyUp(e);
  };

  const controlledProps = onChange
    ? {
        value,
        onChange: (e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e.target.value),
      }
    : {};

  const message = error ? error.message : helperText ? helperText : '';

  return (
    <div className={clsx(baseStyles.field, baseStyles.inputBox, error && baseStyles.error, className)}>
      <textarea
        ref={onRender}
        id={name}
        aria-label={label}
        aria-invalid={error ? 'true' : 'false'}
        className={clsx(baseStyles.input, styles.textarea, textFieldClassName)}
        rows={rows}
        placeholder={placeholder}
        spellCheck={false}
        maxLength={maxLength}
        disabled={disabled}
        onKeyUp={handleKeyUp}
        readOnly={readOnly}
        {...rest}
        {...controlledProps}
      />
      {message && <p className={clsx(baseStyles.message, error && baseStyles.error, messageClassName)}>{message}</p>}
    </div>
  );
};

export default TextArea;
