import { observer } from 'mobx-react-lite';
import Modal from 'theme/atoms/modal';

import AnnotatorWizardForm from './AnnotatorWizardForm';

interface AnnotatorWizardModalProps {
  isOpen: boolean;
  close: () => void;
}

function AnnotatorWizardModal({ isOpen, close }: AnnotatorWizardModalProps): JSX.Element {
  return (
    <Modal isOpen={isOpen} close={close} title="Import data">
      <AnnotatorWizardForm />
    </Modal>
  );
}

export default observer(AnnotatorWizardModal);
