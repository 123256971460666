import clsx from 'clsx';
import { useRef, useState } from 'react';
import { ReactComponent as ArrowUpIcon } from 'theme/svg/arrow-up.svg';
import { ReactComponent as TickIcon } from 'theme/svg/tick.svg';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import styles from './Select.module.scss';
import { Option } from './types';

interface SelectProps {
  options: Option[];
  value: Option;
  onChange: (option: Option) => void;
  label: string;
  className?: string;
  up?: boolean;
}

function Select({ options, value, onChange, label, className = '', up }: SelectProps): JSX.Element {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const multiselectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: multiselectRef, handler: () => setIsDropdownOpen(false) });

  const handleChange = (option: Option) => {
    setIsDropdownOpen(false);
    onChange(option);
  };

  return (
    <div className={clsx(styles.wrapper, className)} ref={multiselectRef}>
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className={clsx(styles.button, { [styles.active]: value, [styles.open]: isDropdownOpen })}
      >
        <div className={styles.title}>
          <div className={styles.text}>{label}</div>
        </div>

        <div className={clsx(styles.iconOpen, { [styles.iconClose]: !isDropdownOpen })}>
          <ArrowUpIcon />
        </div>
      </button>
      {isDropdownOpen && (
        <div className={clsx(styles.dropdown, { [styles.hoistedDropdown]: up })}>
          {options.map((option) => (
            <div
              key={option.value}
              className={clsx(styles.option, { [styles.selected]: value?.value === option.value })}
              onClick={() => handleChange(option)}
            >
              <span>{option.label}</span>
              {value?.value === option.value ? <TickIcon /> : null}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Select;
