import axios from 'axios';

import { CustomError } from './types/CustomError';

const handleRequestError = ({
  error,
  defaultMessage,
  config,
}: {
  error: unknown;
  defaultMessage: string;
  config?: { [key: number]: string };
}): CustomError => {
  const customError: CustomError = {
    message: defaultMessage,
  };

  if (axios.isAxiosError(error)) {
    if (error.message === 'Network Error') {
      customError.message = 'Error. No internet connection';
    } else if (config && error.response?.status) {
      const { status } = error.response;
      const statusMessage = config[status];
      if (statusMessage) customError.message = statusMessage;
    }
  }

  return customError;
};

export default handleRequestError;
