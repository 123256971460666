export type ToastMessages = Record<string, Record<string, string>>;

export const toastMessages: ToastMessages = {
  ANNOTATOR: {
    DELETE_ERROR: 'Failed to delete the annotator.',
    DELETE_SUCCESS: 'The annotator has been successfully deleted.',
    FETCH_ERROR: 'Failed to fetch annotator details.',
  },
  ANNOTATORS: {
    FETCH_ERROR: 'Failed to fetch annotators.',
  },
  ASSIGNMENTS: {
    FETCH_ERROR: 'Failed to fetch annotator assignments.',
  },
  BULK_IMPORT: {
    CSV_ERROR: 'Unable to process the provided CSV file. Please check it for potential errors and try again.',
    IMPORT_ERROR: 'Oops! Something went wrong when trying to import the data.',
    IMPORT_SUCCESS: 'The data have been successfully imported.',
  },
  MISCLASSIFICATIONS: {
    SUBMIT_ERROR: 'Failed to submit the item.',
    SUBMIT_SUCCESS: 'The item has been successfully submitted.',
    TOKEN_ERROR: 'Invalid token',
    POSSIBLE_VALUES_SEARCH_ERROR: 'Failed to search values.',
    POSSIBLE_VALUES_LOAD_MORE_ERROR: 'Failed to load more values.',
  },
  FEEDS: {
    CREATE_SUCCESS: 'The new feed has been created',
    UPDATE_SUCCESS: 'The feed has been updated',
    SAVE_ERROR: 'Oops! Something went wrong!',
    FEED_ALREADY_EXISTS: 'A profile with the provided source already exists',
  },
};
