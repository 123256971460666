import { useStores } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import { useToast } from 'contexts/ToastProvider';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import Confirm from 'theme/atoms/confirm';
import Modal from 'theme/atoms/modal';

interface AnnotatorDeleteModalProps {
  annotatorId: string;
  isAnnotatorDetailsView?: boolean;
}

function AnnotatorDeleteModal({ annotatorId, isAnnotatorDetailsView = false }: AnnotatorDeleteModalProps): JSX.Element {
  const navigate = useNavigate();

  const { addToast, toastMessages } = useToast();
  const { annotatorsStore } = useStores();
  const { deleteAnnotator, isSubmitting, closeAnnotatorDeleteModal, isAnnotatorDeleteModalOpen } = annotatorsStore;

  const handleDelete = (): void => {
    deleteAnnotator(annotatorId)
      .then(() => {
        addToast(toastMessages.ANNOTATOR.DELETE_SUCCESS);
        closeAnnotatorDeleteModal();

        if (isAnnotatorDetailsView) navigate(paths.annotators);
      })
      .catch(() => {
        addToast(toastMessages.ANNOTATOR.DELETE_ERROR, 'error');
      });
  };

  return (
    <Modal isOpen={isAnnotatorDeleteModalOpen} close={closeAnnotatorDeleteModal} title="Delete Annotator">
      <Confirm
        yesText="Delete"
        action={handleDelete}
        close={closeAnnotatorDeleteModal}
        submitting={isSubmitting}
        question="Are you sure that you want to delete this annotator?"
        isDelete
      />
    </Modal>
  );
}

export default observer(AnnotatorDeleteModal);
