import { api } from 'api';
import { AxiosResponse } from 'axios';
import { MetadataRawBEData } from 'utils/types/Metadata';

type FetchMetadataProps = {
  metadataTypes: string[];
  kinds: string[];
  size: number;
  number: number;
  // sortBy: 'ItemCountAsc' | 'ItemCountDesc';
  textSearch?: string;
};

type FetchMetadataReturn = Promise<AxiosResponse<MetadataRawBEData[]>>;

export function fetchMetadata({
  metadataTypes,
  kinds,
  size,
  number,
  // sortBy,
  textSearch,
}: FetchMetadataProps): FetchMetadataReturn {
  const moveCategoryToMetadataTypes = () => {
    if (kinds.includes('Category')) {
      return {
        metadataTypes: [...metadataTypes, 'Category'],
        kinds: kinds.filter((k) => k !== 'Category'),
      };
    } else
      return {
        metadataTypes,
        kinds,
      };
  };

  return api.get('/feed/metadata', {
    params: {
      ...moveCategoryToMetadataTypes(),
      size,
      number,
      // sortBy,
      textSearch,
    },
  });
}
