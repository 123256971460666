import React from 'react';
import { ReactComponent as Logo } from 'theme/svg/logo.svg';
import MessageBox from 'theme/atoms/messageBox';

import styles from './MisclassificationsNoAccess.module.scss';

const MisclassificationsNoAccess = (): JSX.Element => {
  return (
    <div>
      <Logo className={styles.logo} />
      <MessageBox type="error">
        <span>
          Post not found.
          <br />
          Please check if the URL is correct.
        </span>
      </MessageBox>
    </div>
  );
};

export default MisclassificationsNoAccess;
