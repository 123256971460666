import { dataWarehousingApi } from 'api';
import { AxiosResponse } from 'axios';
import { assignmentsPageSize, categoryPossibleValuesPageSize } from 'utils/constants';
import { generateHash, UNDERSCORE } from 'utils/generateHash';

import {
  AssignedPost,
  AssignmentsResponse,
  CategoryPossibleValuesResponse,
  Correction,
  FetchCategoryPossibleValuesParams,
} from './types';

type FetchAssignmentsParams = {
  annotatorId: string;
  pageNumber: number;
};

export function fetchAssignments({
  annotatorId,
  pageNumber,
}: FetchAssignmentsParams): Promise<AxiosResponse<AssignmentsResponse>> {
  return dataWarehousingApi.get('/annotators/assignments', {
    params: { annotatorId, pageNumber, pageSize: assignmentsPageSize, state: 'Assigned' },
  });
}

export function updatePost(
  assignmentId: string,
  data: {
    comment: string | null;
    corrections: Correction[];
  }
): Promise<AxiosResponse<AssignedPost[]>> {
  return dataWarehousingApi.post(`/annotators/assignments/${assignmentId}/corrections`, data);
}

export function fetchCategoryPossibleValues({
  categoryName,
  categoryValue,
  pageNumber,
}: FetchCategoryPossibleValuesParams): Promise<AxiosResponse<CategoryPossibleValuesResponse>> {
  return dataWarehousingApi
    .get('/items/categories/possibleValues', {
      params: {
        categoryName,
        categoryValue,
        pageNumber,
        pageSize: categoryPossibleValuesPageSize,
      },
    })
    .then((response) => ({
      ...response,
      data: {
        ...response.data,
        results: response.data.results.map((result) => ({
          ...result,
          title: `${result.title}${UNDERSCORE}${generateHash()}`,
        })),
      },
    }));
}
