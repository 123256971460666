import { useStores } from 'RootStore';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import ProfileParent from 'profiles/ProfileParent/ProfileParent';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonSpinner from 'theme/atoms/buttons/ButtonSpinner';
import Checkbox from 'theme/atoms/inputs/Checkbox';
import Input from 'theme/atoms/inputs/Input';
import { List } from 'theme/atoms/multiselect';
import { ReactComponent as RssIcon } from 'theme/svg/rss.svg';
import { ReactComponent as SitemapIcon } from 'theme/svg/sitemap.svg';
import { ReactComponent as TwitterIcon } from 'theme/svg/twitter.svg';
import { Error } from 'utils/types/Error';
import { Profile } from 'utils/types/Profile';

import { FormData, ValidationErrors } from '../profileFormTypes';
import LabelsInput from './LabelsInput/LabelsInput';
import styles from './ProfileForm.module.scss';
import ProfileFormSections from './ProfileFormSections/ProfileFormSections';
import getDefaultSectionsLists from './getDefaultSectionsLists';
import validateProfileForm, { validateLocation } from './validateProfileForm';

interface ProfileFormProps {
  error: Error | null;
  isLoading: boolean;
  isParentDetails?: boolean;
  onSubmit: (data: FormData & { sectionsLists: List[] }) => void;
  profile: Profile | null;
}

function ProfileForm({ error, isLoading, isParentDetails = false, onSubmit, profile }: ProfileFormProps): JSX.Element {
  const { profilesStore } = useStores();
  const { fetchLocation } = profilesStore;

  const { profileType: type } = useParams();

  const isTwitter = type === 'twitter' || profile?.sourceKind === 'Twitter';
  const isRSS = type === 'rss' || profile?.sourceKind === 'RSS';
  const isSitemap = type === 'sitemap' || profile?.sourceKind === 'Sitemap';

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [selectedParent, setSelectedParent] = useState<Profile | null>(null);
  const [postalCode, setPostalCode] = useState('');

  const handleParent = (parent: Profile | null): void => {
    setSelectedParent(parent);
    setFormData(
      (state): FormData => ({
        ...state,
        parent: parent ? parent.id : null,
      })
    );
  };

  const [formData, setFormData] = useState<FormData>({
    latitude: profile && profile.latitude ? profile.latitude.toString() : '',
    longitude: profile && profile.longitude ? profile.longitude.toString() : '',
    postalCode: postalCode === '' ? null : postalCode,
    source: profile ? profile.source : '',
    userLabels: profile ? profile.userLabels.map((userLabel) => userLabel.title) : [],
    ...(!isTwitter && {
      flags: {
        autoPosting: profile?.autoPosting || false,
        backload: profile?.backload || false,
        disableImage: profile?.disableImage || false,
        isDisabled: profile?.isDisabled || false,
        mlCategorization: profile?.mlCategorization || false,
        teamIdentification: profile?.teamIdentification || false,
        useProxy: profile?.useProxy || false,
        useKeyPoints: profile?.useKeyPoints || false,
        useOpenaiMetadata: profile?.useOpenaiMetadata || false,
        useOpenaiSports: profile?.useOpenaiSports || false,
        useSeoTitle: profile?.useSeoTitle || false,
        useShortDescription: profile?.useShortDescription || false,
      },
      parent: selectedParent ? selectedParent.id : null,
    }),
  });

  const [geolocationError, setGeolocationError] = useState('');
  const [sectionsLists, setSectionsLists] = useState<List[]>(
    getDefaultSectionsLists(profile ? profile.sectionsGroups : [], profile?.country)
  );

  useEffect(() => {
    if (profile?.parent) handleParent(profile.parent);
  }, [profile?.parent]);

  useEffect(() => {
    if (profile?.postalCode) setPostalCode(profile.postalCode);
  }, [profile?.postalCode]);

  const onLabelsChange = (userLabels: string[]): void => {
    setFormData({ ...formData, userLabels });
  };

  const validatePostalCode = () => {
    const pc = postalCode.trim();

    if (/^[A-Za-z0-9]*$/.test(pc) && !/^\d+$/.test(pc) && pc.length === 6) {
      const canadianPostalCodeWithSpace = pc.slice(0, 3) + ' ' + pc.slice(3, 6);
      setPostalCode(canadianPostalCodeWithSpace);
      return canadianPostalCodeWithSpace;
    } else if (
      (/^\d+$/.test(pc) && pc.length === 5) ||
      (/^[A-Za-z0-9\s]*$/.test(pc) && pc.slice(3, 4) === ' ' && pc.length === 7)
    ) {
      return pc;
    }
  };

  const handleLocationFetch = () => {
    const properPostalCode = validatePostalCode();

    if (!properPostalCode) return setGeolocationError('Incorrect postal code');

    setFormData({
      ...formData,
      latitude: '',
      longitude: '',
    });
    setValidationErrors({
      ...validationErrors,
      latitude: undefined,
      longitude: undefined,
    });

    fetchLocation(properPostalCode)
      .then((location) => {
        const newLocationData = {
          latitude: location.latitude.toString(),
          longitude: location.longitude.toString(),
        };
        setFormData(
          (state): FormData => ({
            ...state,
            ...newLocationData,
          })
        );

        const { errors } = validateLocation({
          latitude: newLocationData.latitude,
          longitude: newLocationData.longitude,
        });

        setValidationErrors(
          (state): ValidationErrors => ({
            ...state,
            ...errors,
          })
        );
      })
      .catch(() => {
        setGeolocationError("Couldn't find location of this postal code. Fill in latitude and longitude manually.");
      });
  };

  const handleSubmit = (e): void | undefined => {
    e.preventDefault();
    setGeolocationError('');

    const { isError, errors } = validateProfileForm(formData);
    setValidationErrors(errors);
    if (isError) return;

    onSubmit({
      ...formData,
      sectionsLists,
    });
  };

  const handleChangePostalCode = (code: string): void => {
    setPostalCode(code);
    setFormData(
      (state): FormData => ({
        ...state,
        postalCode: code,
      })
    );
    setGeolocationError('');
  };

  const renderIcon = (): JSX.Element | null => {
    if (isRSS) return <RssIcon className={styles.sourceIcon} />;
    else if (isTwitter) return <TwitterIcon className={styles.sourceIcon} />;
    else if (isSitemap) return <SitemapIcon className={styles.sourceIcon} />;
    else return null;
  };

  const profileUrlPlaceholder = type === 'twitter' ? 'https://twitter.com/Twitter' : 'https://someurl.com';

  const handleProfileFlagDto = (name: string): void | undefined => {
    if (!formData.flags) return;

    setFormData({
      ...formData,
      flags: {
        ...formData.flags,
        [name]: formData.flags[name] === undefined ? false : !formData.flags[name],
      },
    });
  };

  return (
    <form>
      <Input
        name="source"
        label="Profile URL"
        type="text"
        placeholder={profileUrlPlaceholder}
        value={formData.source}
        onChange={(value) => setFormData({ ...formData, source: value })}
        error={validationErrors?.source}
        endIcon={renderIcon()}
        inputBoxClassName={styles.sourceInput}
        readOnly={isParentDetails}
        autoCompleteOff
      />
      <div className={styles.checkboxes}>
        <div>
          {!isTwitter && (
            <>
              <Checkbox
                name="autoposting"
                label="Autoposting"
                onChange={() => handleProfileFlagDto('autoPosting')}
                className={styles.checkbox}
                checked={formData.flags?.autoPosting}
                disabled={isParentDetails}
              />
              <Checkbox
                name="mlCategorization"
                label="Apply auto categorization"
                onChange={() => handleProfileFlagDto('mlCategorization')}
                className={styles.checkbox}
                checked={formData.flags?.mlCategorization}
                disabled={isParentDetails}
              />
            </>
          )}
          <Checkbox
            name="teamIdentification"
            label="Apply team identification"
            onChange={() => handleProfileFlagDto('teamIdentification')}
            className={styles.checkbox}
            checked={formData.flags?.teamIdentification}
            disabled={isParentDetails}
          />
          {!isTwitter && (
            <Checkbox
              name="backload"
              label="Backloading"
              onChange={() => handleProfileFlagDto('backload')}
              className={styles.checkbox}
              checked={formData.flags?.backload}
              disabled={isParentDetails}
            />
          )}
          {(isRSS || isSitemap) && (
            <Checkbox
              name="isDisabled"
              label="Disabled"
              onChange={() => handleProfileFlagDto('isDisabled')}
              className={styles.checkbox}
              checked={formData.flags?.isDisabled}
              disabled={isParentDetails}
            />
          )}
          {isRSS && (
            <Checkbox
              name="disableImage"
              label="Disable Image"
              onChange={() => handleProfileFlagDto('disableImage')}
              className={styles.checkbox}
              checked={formData.flags?.disableImage}
              disabled={isParentDetails}
            />
          )}
        </div>
        {(isRSS || isSitemap) && (
          <div>
            <Checkbox
              name="useProxy"
              label="Use proxy"
              onChange={() => handleProfileFlagDto('useProxy')}
              className={styles.checkbox}
              checked={formData.flags?.useProxy}
              disabled={isParentDetails}
            />
            <Checkbox
              name="useKeyPoints"
              label="Use key points"
              onChange={() => handleProfileFlagDto('useKeyPoints')}
              className={styles.checkbox}
              checked={formData.flags?.useKeyPoints}
              disabled={isParentDetails}
            />
            <Checkbox
              name="useOpenaiMetadata"
              label="Use OpenAI metadata"
              onChange={() => handleProfileFlagDto('useOpenaiMetadata')}
              className={styles.checkbox}
              checked={formData.flags?.useOpenaiMetadata}
              disabled={isParentDetails}
            />
            <Checkbox
              name="useOpenaiSports"
              label="Use OpenAI sports"
              onChange={() => handleProfileFlagDto('useOpenaiSports')}
              className={styles.checkbox}
              checked={formData.flags?.useOpenaiSports}
              disabled={isParentDetails}
            />
            <Checkbox
              name="useSeoTitle"
              label="Use SEO title"
              onChange={() => handleProfileFlagDto('useSeoTitle')}
              className={styles.checkbox}
              checked={formData.flags?.useSeoTitle}
              disabled={isParentDetails}
            />
            <Checkbox
              name="useShortDescription"
              label="Use short description"
              onChange={() => handleProfileFlagDto('useShortDescription')}
              className={styles.checkbox}
              checked={formData.flags?.useShortDescription}
              disabled={isParentDetails}
            />
          </div>
        )}
      </div>

      <div className={styles.fields}>
        <div className={styles.field}>
          <p className={styles.fieldTitle}>
            Geolocation <span>pick a location - provide latitude and longitude by postal code or manually</span>
          </p>
          <div className={styles.geolocationFieldsBox}>
            <Input
              name="postalCode"
              label="Postal code"
              type="text"
              placeholder="12345 / A1A 1A1"
              value={postalCode}
              onChange={(value) => handleChangePostalCode(value)}
              error={validationErrors?.postalCode}
              readOnly={isParentDetails}
              autoCompleteOff
            />
            <div className={styles.fetchLocationBtnBox}>
              <button
                className={styles.fetchLocationBtn}
                type="button"
                onClick={handleLocationFetch}
                disabled={isParentDetails}
              >
                Fill in by postal code
              </button>
            </div>

            <Input
              name="latitude"
              label="Latitude"
              type="number"
              placeholder="82.135"
              value={formData.latitude}
              onChange={(value) => setFormData({ ...formData, latitude: value })}
              error={validationErrors?.latitude}
              readOnly={isParentDetails}
              autoCompleteOff
            />
            <Input
              name="longitude"
              label="Longitude"
              type="number"
              placeholder="172.673"
              value={formData.longitude}
              onChange={(value) => setFormData({ ...formData, longitude: value })}
              error={validationErrors?.longitude}
              readOnly={isParentDetails}
              autoCompleteOff
            />
          </div>
          {geolocationError && <p className={styles.errorMessage}>{geolocationError}</p>}
        </div>
        {!isTwitter && (
          <div className={styles.field}>
            <p className={styles.fieldTitle}>Parent</p>
            <ProfileParent profile={selectedParent} handleParent={handleParent} />
          </div>
        )}
        <div className={styles.field}>
          <p className={styles.fieldTitle}>Sport / Team</p>
          <ProfileFormSections lists={sectionsLists} setLists={setSectionsLists} disabled={isParentDetails} />
        </div>
        <div className={styles.field}>
          <p className={styles.fieldTitle}>
            Labels <span>separate with commas or the Enter key.</span>
          </p>
          <LabelsInput
            name="userLabels"
            value={formData.userLabels}
            onChange={onLabelsChange}
            readOnly={isParentDetails}
          />
        </div>
      </div>

      {error && <p className={styles.errorMessage}>{error.message}</p>}

      {!isParentDetails && (
        <div className={styles.footer}>
          <button className={styles.submitBtn} type="submit" disabled={isLoading} onClick={handleSubmit}>
            {isLoading && <ButtonSpinner />}
            <span className={clsx(styles.submitBtnText, { [styles.loading]: isLoading })}>
              {profile ? 'Save' : 'Add feed'}
            </span>
          </button>
        </div>
      )}
    </form>
  );
}

export default observer(ProfileForm);
