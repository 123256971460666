import { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import Skeleton from 'theme/atoms/loaders/Skeleton';
import { Post } from 'utils/types/Post';

import styles from './TwitterPost.module.scss';

interface TwitterPostProps {
  post: Post;
}

function TwitterPost({ post }: TwitterPostProps): JSX.Element {
  const tweetRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.twttr.widgets.load(tweetRef.current).then(() => setIsLoading(false));
  }, []);

  return (
    <div className={styles.twitterPost}>
      {isLoading && (
        <div className={styles.skeletonsBox}>
          <Skeleton width="70%" />
          <Skeleton />
          <Skeleton height={300} />
        </div>
      )}
      <div
        ref={tweetRef}
        dangerouslySetInnerHTML={{ __html: post.tweetEmbed || '' }}
        className={clsx({ [styles.hideTweet]: isLoading })}
      />
    </div>
  );
}

export default TwitterPost;
