import { ReactComponent as CloseIcon } from 'theme/svg/close.svg';
import clsx from 'clsx';

import styles from './Tag.module.scss';

interface TagProps {
  text: string;
  subText?: string;
  icon?: JSX.Element;
  onRemove?: (text: string) => void;
  onTagClick?: (event?) => void;
}

function Tag({ icon, text, subText, onRemove, onTagClick }: TagProps): JSX.Element {
  return (
    <div className={clsx(styles.tag, { [styles.isClickable]: onTagClick })} onClick={(e) => onTagClick?.(e)}>
      {icon && <span className={styles.customIconBox}>{icon}</span>}
      <span className={styles.text}>{text}</span>
      {subText && <span className={styles.subText}>{subText}</span>}
      {onRemove && (
        <button className={styles.closeBtn} onClick={() => onRemove(text)}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      )}
    </div>
  );
}

export default Tag;
