import { useStores } from 'RootStore';
import clsx from 'clsx';
import { AssignedPost } from 'misclassifications/types';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useRemark } from 'react-remark';

import styles from './MisclassificationsPost.module.scss';

const MD_BLOCK_TAG = '<md-block>';

interface MisclassificationsPostProps {
  post: AssignedPost;
}

function MisclassificationsPost({ post }: MisclassificationsPostProps): JSX.Element | null {
  if (!post) return null;
  const { imageUrl, openaiResponseJson, contentUrl, keyPoints, text, title } = post;

  const [reactContent, setMarkdownSource] = useRemark();
  const { misclassificationsStore } = useStores();
  const { highlightText } = misclassificationsStore;

  const getResource = (): JSX.Element | null => {
    if (!imageUrl) return null;
    return <div className={styles.photo} style={{ backgroundImage: `url(${imageUrl})` }} />;
  };

  const parsedJSON = JSON.parse(JSON.parse(openaiResponseJson));

  useEffect(() => {
    if (parsedJSON.contentHtml?.includes(MD_BLOCK_TAG)) setMarkdownSource(parsedJSON.contentHtml);
  }, [parsedJSON.contentHtml]);

  const renderContentHtml = (): JSX.Element | null => {
    if (!parsedJSON) return null;

    const renderedContent = reactContent ? (
      <div>{reactContent}</div>
    ) : (
      <p dangerouslySetInnerHTML={{ __html: parsedJSON.contentHtml }} />
    );

    const isThereAnythingToRender = !!parsedJSON.contentHtml;

    return (
      <>
        {isThereAnythingToRender && (
          <div className={styles.card}>
            <h2 className={styles.openaiResponseJsonTitle}>Content HTML generated by ChatGPT</h2>
            <div className={styles.openaiResponseJsonSummary}>
              <h3 dangerouslySetInnerHTML={{ __html: parsedJSON.seoTitle }} />
              {renderedContent}
            </div>
          </div>
        )}
        <div className={clsx(styles.card, styles.openaiResponseJson)}>
          <h3>OpenAI Response JSON</h3>
          <JsonView data={parsedJSON} />
        </div>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <div className={clsx(styles.card, styles.contentUrl)}>
        <strong>Content URL:</strong>{' '}
        {contentUrl && (
          <a href={contentUrl} target="_blank" rel="noreferrer">
            {contentUrl}
          </a>
        )}
      </div>
      <div className={clsx(styles.card, styles.post)}>
        <div className={styles.body}>
          <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: highlightText(title, 'title') }} />
          <div>{getResource()}</div>
          {keyPoints?.length > 0 && (
            <div className={styles.keypoints}>
              <strong>Key Points:</strong>
              <ul>
                {keyPoints.map((keyPoint, i) => (
                  <li key={i}>{keyPoint}</li>
                ))}
              </ul>
            </div>
          )}

          <p className={styles.message} dangerouslySetInnerHTML={{ __html: highlightText(text, 'text') }} />
        </div>
      </div>
      {renderContentHtml()}
    </div>
  );
}

export default observer(MisclassificationsPost);
