import { api } from 'api';
import { AxiosResponse } from 'axios';

type LoginReturn = Promise<
  AxiosResponse<{
    token: string;
  }>
>;

export function login(username: string, password: string): LoginReturn {
  return api.post(`/auth/login`, { username, password });
}
