import React, { ReactNode, useState, useContext, useCallback } from 'react';
import ToastContainer from 'theme/atoms/toastContainer';
import { Toast, ToastType } from 'theme/atoms/toastContainer/ToastContainer';
import { toastMessages, ToastMessages } from 'utils/toastMessages';

export type ToastContextProps = {
  addToast: (content: string, type?: ToastType, timeout?: number) => void;
  removeToast: (id: string) => void;
  toastMessages: ToastMessages;
};

const ToastContext = React.createContext<ToastContextProps | null>(null);
const generateToastId = (): string => Math.random().toString(36).slice(-5);

interface Props {
  children: ReactNode;
}

const ToastProvider = ({ children }: Props): JSX.Element => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = useCallback(
    (content: string, type?: ToastType, timeout?: number): void => {
      setToasts((toasts) => [...toasts, { id: generateToastId(), content, type, timeout }]);
    },
    [setToasts]
  );

  const removeToast = (id: string): void => {
    setToasts((toasts) => toasts.filter((t) => t.id !== id));
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast, toastMessages }}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextProps => {
  return useContext(ToastContext) as ToastContextProps;
};

export { ToastContext, useToast };
export default ToastProvider;
