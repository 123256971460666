import { Annotator } from 'annotators/types';

const findSpamOrConfusingItemCount = (annotator: Annotator, categoryName: string): number => {
  return annotator.dashboard.totalItemPerCategory.find((item) => item.categoryName === categoryName)?.number ?? 0;
};

const findDoneItemCount = (annotator: Annotator): number => {
  const correctedItemCount =
    annotator.dashboard.totalItemsPerState.find((item) => item.state === 'Corrected')?.number ?? 0;

  return (
    correctedItemCount -
    findSpamOrConfusingItemCount(annotator, 'Spam') -
    findSpamOrConfusingItemCount(annotator, 'Confusing')
  );
};

interface AnnotatorResults {
  count: { all: number; confusing: number; done: number; spam: number };
  ratio: {
    confusing: number;
    done: number;
    spam: number;
  };
}

export const calculateAnnotatorResults = (annotator: Annotator): AnnotatorResults => {
  const totalItems = annotator.dashboard.totalItems;
  const confusingItems = findSpamOrConfusingItemCount(annotator, 'Confusing');
  const doneItems = findDoneItemCount(annotator);
  const spamItems = findSpamOrConfusingItemCount(annotator, 'Spam');

  const count = {
    all: totalItems,
    confusing: confusingItems,
    done: doneItems,
    spam: spamItems,
  };

  const ratio = {
    confusing: confusingItems / totalItems,
    done: doneItems / totalItems,
    spam: spamItems / totalItems,
  };

  return { count, ratio };
};
