import clsx from 'clsx';
import { MetadataSectionKind } from 'metadata';
import { forwardRef } from 'react';
import Checkbox from 'theme/atoms/inputs/Checkbox';

import { ListItem } from '../../types';
import styles from './FilterItem.module.scss';

interface FilterItemProps {
  i: number;
  item: ListItem;
  kind?: MetadataSectionKind;
  toggleFilterCheck: (item: ListItem) => void;
  wide?: boolean;
}

const FilterItem = ({ i, item, kind, toggleFilterCheck, wide }: FilterItemProps, ref): JSX.Element => {
  const { level, title, id, checked, region } = item;

  const renderLabel = (): string => (kind === 'Team' ? `${title}, ${region}, ${level}` : title);

  const label = (
    <div className={styles.filterLabel}>
      <span className={clsx(styles.filterLabelName, { [styles.filterLabelNameWide]: wide })} title={title}>
        {renderLabel()}
      </span>
    </div>
  );

  return (
    <div className={styles.filterItem} ref={ref} id={`${id}${i}`}>
      <Checkbox name={id} label={label} checked={checked} onChange={() => toggleFilterCheck(item)} />
    </div>
  );
};

export default forwardRef(FilterItem);
