import { useEffect, useRef, useState } from 'react';

interface UseInfiniteScrollProps {
  callback: (id: string) => void;
  shouldRunCallback?: boolean;
}

interface UseInfiniteScrollReturn {
  changeLastElement: (node: any) => void;
  isLoading: boolean;
}

const useInfiniteScroll = ({ callback, shouldRunCallback = true }: UseInfiniteScrollProps): UseInfiniteScrollReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [lastElement, setLastElement] = useState<any>(null);
  const shouldRunCallbackRef = useRef(shouldRunCallback);

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && isLoading === false && shouldRunCallbackRef.current) {
          setIsLoading(true);
          const id = entries[0].target.id || entries[0].target.getAttribute('data-infinite-scroll-id') || '';
          callback(id);
        }
      },
      { threshold: 0.5 }
    )
  );

  useEffect(() => {
    shouldRunCallbackRef.current = shouldRunCallback;
  }, [shouldRunCallback]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return {
    changeLastElement: setLastElement,
    isLoading,
  };
};

export default useInfiniteScroll;
