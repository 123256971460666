import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { paths } from 'app/routes/paths.const';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStores } from 'RootStore';
import Spinner from 'theme/atoms/loaders/Spinner';

import styles from './MisclassificationsAuthChecker.module.scss';

const MisclassificationsAuthChecker = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { misclassificationsStore } = useStores();
  const { saveBasicAuthToken } = misclassificationsStore;

  const authToken = searchParams.get('token');

  const navigateToMisclassifications = (): void => {
    navigate(`${paths.misclassifications}?token=${authToken}`);
  };

  useEffect(() => {
    if (authToken) {
      saveBasicAuthToken(authToken);
      navigateToMisclassifications();
    }
  }, []);

  return (
    <div className={styles.root}>
      <Spinner />
    </div>
  );
};

export default observer(MisclassificationsAuthChecker);
