import { parse } from 'json2csv';

export type CSVHeader<T> = {
  label: string;
  value?: string | ((row: T) => string | number | null);
};

export interface ExportToCsvParams<T> {
  data?: T[] | undefined;
  headers: Array<string | CSVHeader<T>>;
  fileName: string;
}

export const exportToCSV = <T>({ data, headers, fileName }: ExportToCsvParams<T>): string | null => {
  if (data?.length) {
    const opts = {
      fields: headers,
    };

    const csv = parse(data, opts);

    try {
      const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(link);

      link.click();
      return csv;
    } catch (err) {
      return null;
    }
  } else return null;
};
