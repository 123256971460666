import { metadataSectionKinds } from 'metadata';
import { List } from 'theme/atoms/multiselect';
import { additionalFilterLists, filtersIcons } from 'utils/constants';

const getFiltersLists = (profileType?: string | undefined): List[] => {
  return [
    ...metadataSectionKinds.map(
      (sectionKind): List => ({
        title: sectionKind,
        kind: sectionKind,
        items: [],
        icon: filtersIcons[sectionKind],
        fetchedItems: [],
        open: false,
        fetched: false,
        page: 1,
        isLoading: false,
        isLoadingNextPage: true,
        error: null,
        type: 'dynamic',
      })
    ),
    ...additionalFilterLists(profileType),
    {
      title: 'Your Labels',
      metadataType: 'UserLabel',
      items: [],
      fetchedItems: [],
      icon: filtersIcons['YourLabels'],
      open: false,
      fetched: false,
      page: 1,
      isLoading: false,
      isLoadingNextPage: true,
      error: null,
      type: 'dynamic',
    },
  ];
};

export default getFiltersLists;
