import RootStoreContext, { rootStore } from 'RootStore';
import ToastProvider from 'contexts/ToastProvider';

import Router from './routes/Router';

function App(): JSX.Element {
  return (
    <RootStoreContext.Provider value={rootStore}>
      <ToastProvider>
        <Router />
      </ToastProvider>
    </RootStoreContext.Provider>
  );
}

export default App;
