import { api } from 'api';
import { AxiosResponse } from 'axios';
import { Country } from 'utils/types/Country';
import { PostRawBEData } from 'utils/types/Post';
import { SourceKind } from 'utils/types/SourceKind';

export const postsPerPage = 10;

interface FetchPostsProps {
  metadataId: string[];
  textSearch: string;
  page: number;
  size?: number;
  sourceKind?: SourceKind;
  country?: Country;
}
type FetchPostsReturn = Promise<AxiosResponse<PostRawBEData[]>>;

export function fetchPosts({
  textSearch,
  metadataId,
  page,
  size = postsPerPage,
  sourceKind,
  country,
}: FetchPostsProps): FetchPostsReturn {
  return api.get(`/feed`, {
    params: {
      number: page,
      size,
      textSearch,
      metadataId,
      sortBy: 'PublicationDateDesc',
      sourceKind,
      country,
      isAutopostable: false,
    },
  });
}
