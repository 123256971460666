import { KeyboardEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import Tag from 'theme/atoms/tag';
import baseStyles from 'theme/atoms/inputs/base.module.scss';
import handleRequestError from 'utils/handleRequestError';
import { useDebounce } from 'utils/hooks';
import { CustomError } from 'utils/types/CustomError';

import styles from './LabelsInput.module.scss';

export interface LabelsInputProps {
  name: string;
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
  error?: string;
  readOnly: boolean;
}

function LabelsInput({ name, value, onChange, error, className = '', readOnly }: LabelsInputProps): JSX.Element {
  const { metadataStore } = useStores();
  const { fetchMetadata } = metadataStore;

  const [metadataError, setMetadataError] = useState<CustomError | null>(null);
  const [isRefetching, setIsRefetching] = useState(false);

  const [suggestedLabels, setSuggestedLabels] = useState<string[]>([]);

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 250);

  const isDuplicate = (newLabel: string): boolean => {
    return value.findIndex((label) => label === newLabel) !== -1;
  };

  const handleAddLabel = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.code === 'Enter' || e.code === 'Comma') && searchText) {
      e.preventDefault();

      if (!isDuplicate(searchText)) {
        onChange([...value, searchText]);
        setSearchText('');
      } else {
        //TODO: Handle duplicate tags
        console.error('You cannot add the same tag twice');
      }
    }
  };

  const handleRemoveLabel = (labelToRemove: string) => {
    onChange(value.filter((label) => label !== labelToRemove));
  };

  const handleSuggestedLabelClick = (label: string) => {
    onChange([...value, label]);
    setSuggestedLabels([]);
    setSearchText('');
  };

  const fetchSearched = (newSearchValue: string) => {
    setIsRefetching(true);

    fetchMetadata({ searchText: newSearchValue, metadataTypes: ['UserLabel'] })
      .then((metadata) => {
        const newSuggestedLabels = metadata.map((item) => item.title).filter((item) => !value.includes(item));
        setSuggestedLabels(newSuggestedLabels);
        setIsRefetching(false);
      })
      .catch((err) => {
        const newError = handleRequestError({
          error: err,
          defaultMessage: "Couldn't fetch categories. Try again later",
        });
        setMetadataError(newError);
        setIsRefetching(false);
      });
  };

  useEffect(() => {
    if (debouncedSearchText) {
      fetchSearched(debouncedSearchText);
    } else {
      setSuggestedLabels([]);
    }
  }, [debouncedSearchText]);

  return (
    <div className={clsx(baseStyles.field, className, { [baseStyles.error]: error })}>
      <div className={styles.box}>
        {value.map((tag, i) => (
          <Tag key={tag + i} text={tag} onRemove={handleRemoveLabel} />
        ))}

        <div className={styles.inputBox}>
          <input
            id={name}
            aria-invalid={error ? 'true' : 'false'}
            type="text"
            className={styles.input}
            placeholder={value.length === 0 ? 'Add labels' : ''}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={handleAddLabel}
            autoComplete="off"
            readOnly={readOnly}
          />
          {suggestedLabels.length > 0 && searchText && (
            <div className={styles.dropdown}>
              <div className={styles.suggestedLabels} role="listbox">
                {suggestedLabels.map((label, i) => (
                  <div
                    key={label + i}
                    className={styles.suggestedLabelsItem}
                    role="option"
                    onClick={() => handleSuggestedLabelClick(label)}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {error && <p className={baseStyles.errorMessage}>{error}</p>}
    </div>
  );
}

export default LabelsInput;
