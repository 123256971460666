import { ChangeEvent } from 'react';
import { FieldValues } from 'react-hook-form';
import clsx from 'clsx';

import styles from '../Checkbox/Checkbox.module.scss';
import baseStyles from '../base.module.scss';
import { CheckboxProps, ReactHookFormCore } from '../types';

interface RHFCheckboxProps<T> extends Omit<CheckboxProps, 'name'>, ReactHookFormCore<T> {}

function RHFCheckbox<T extends FieldValues = FieldValues>({
  name,
  label,
  checked,
  onChange,
  className = '',
  register,
  registerOptions = {},
  errors,
}: RHFCheckboxProps<T>): JSX.Element {
  const handleChange = (value: boolean) => {
    onChange && onChange(value);
  };

  const controlledProps = onChange
    ? { checked, onChange: (e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked) }
    : {};

  const error =
    errors && errors[name]
      ? errors[name].type === 'required'
        ? `${label} field is required`
        : errors[name].message
      : '';

  return (
    <div className={clsx(styles.box, className)}>
      <div className={styles.formCheckbox}>
        <input
          id={name}
          aria-invalid={error ? 'true' : 'false'}
          type="checkbox"
          className={styles.checkbox}
          {...register(name, registerOptions)}
          {...controlledProps}
        />
        <span className={styles.formCheckboxCheckmark} />
        <label htmlFor={name} id={name} className={styles.label}>
          {label}
        </label>
      </div>
      {error && <p className={baseStyles.errorMessage}>{error}</p>}
    </div>
  );
}

export default RHFCheckbox;
