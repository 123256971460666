import clsx from 'clsx';

import { ActionButtonType } from './types';
import styles from './ActionButton.module.scss';

interface ActionButtonProps {
  action?: () => void;
  text?: string;
  type: ActionButtonType;
}

function ActionButton({ action, text = '', type }: ActionButtonProps): JSX.Element {
  return (
    <div className={styles.root} onClick={() => action?.()}>
      <div className={clsx(styles.icon, styles[type])} />
      <span>{text || type}</span>
    </div>
  );
}

export default ActionButton;
