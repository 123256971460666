import { MetadataSectionKind } from '.';

const metadataSectionKinds: MetadataSectionKind[] = [
  'Sport',
  'League',
  'Team',
  'Gender',
  'Region',
  'Level',
  'Category',
  'Conference',
  'Division',
  'Subdivision',
];

export default metadataSectionKinds;
