import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { RHFInput } from 'theme/atoms/inputs';
import RHFCheckbox from 'theme/atoms/inputs/RHFCheckbox';
import ButtonSpinner from 'theme/atoms/buttons/ButtonSpinner';
import { ReactComponent as LockIcon } from 'theme/svg/lock.svg';
import { ReactComponent as UserIcon } from 'theme/svg/user.svg';

import { FormValues } from '../models';
import styles from './LoginForm.module.scss';

interface LoginFormProps {
  onSubmit: (values: FormValues) => void;
  error: { message: string } | null;
  isLoading: boolean;
}

function LoginForm({ onSubmit, error, isLoading }: LoginFormProps): JSX.Element {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
      <RHFInput<FormValues>
        label="Email"
        name="email"
        register={register}
        errors={errors}
        type="text"
        placeholder="name@gmail.com"
        endIcon={<UserIcon />}
        registerOptions={{ required: true }}
      />
      <RHFInput<FormValues>
        label="Password"
        name="password"
        register={register}
        errors={errors}
        type="password"
        placeholder="********"
        endIcon={<LockIcon />}
        registerOptions={{ required: true }}
        className={styles.passwordInput}
      />
      <RHFCheckbox<FormValues>
        name="rememberMe"
        label="Remember me"
        register={register}
        errors={errors}
        className={styles.rememberMeCheckbox}
      />
      {error && (
        <p className={styles.errorMessage} data-cy="errorMessage">
          {error.message}
        </p>
      )}

      <button className={styles.submitBtn} type="submit" disabled={isLoading}>
        {isLoading && <ButtonSpinner />}
        <span className={clsx(styles.submitBtnText, { [styles.loading]: isLoading })}>Log in</span>
      </button>
    </form>
  );
}

export default LoginForm;
