const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const CHARS_LENGTH = CHARS.length;

export const UNDERSCORE = '____';

export function generateHash(): string {
  const randomChars = Array.from({ length: 10 }, () => CHARS.charAt(Math.floor(Math.random() * CHARS_LENGTH)));
  return randomChars.join('');
}

export function splitByUnderscore(str: string): string {
  return str.split(UNDERSCORE)[0];
}
