import { useStores } from 'RootStore';
import { observer } from 'mobx-react-lite';
import TextArea from 'theme/atoms/inputs/TextArea';
import Modal from 'theme/atoms/modal';

import styles from './MarkAsConfusingModal.module.scss';

const MarkAsConfusingModal = (): JSX.Element => {
  const { misclassificationsStore } = useStores();
  const {
    closeMarkAsConfusingModal,
    isMarkAsConfusingModalOpen,
    markAsConfusingTextAreaValue,
    markItemAsConfusing,
    setMarkAsConfusingTextAreaValue,
  } = misclassificationsStore;

  const confirmConfusing = (): void => {
    markItemAsConfusing();
    closeMarkAsConfusingModal();
  };

  return (
    <Modal isOpen={isMarkAsConfusingModalOpen} close={closeMarkAsConfusingModal} title="Mark as confusing">
      <TextArea
        label="Add your comment"
        name="Spam"
        value={markAsConfusingTextAreaValue}
        onChange={(value) => setMarkAsConfusingTextAreaValue(value)}
        rows={6}
        placeholder="Would you like to add a comment on why you find this item confusing?"
      />
      <div className={styles.submitBtnContainer}>
        <button className="btn-primary" onClick={confirmConfusing} disabled={!markAsConfusingTextAreaValue}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default observer(MarkAsConfusingModal);
