import React from 'react';
import clsx from 'clsx';
import ButtonSpinner from 'theme/atoms/buttons/ButtonSpinner';

import styles from './Confirm.module.scss';

type ConfirmProps = {
  action: () => void;
  close: () => void;
  isDelete?: boolean;
  question: string;
  submitting?: boolean;
  yesText: string;
};

const Confirm = ({ action, close, isDelete = false, question, submitting, yesText }: ConfirmProps): JSX.Element => {
  return (
    <div className={styles.confirm}>
      <p>{question}</p>
      <div className={styles.btnContainer}>
        <button
          className={clsx(styles.submitBtn, isDelete ? 'btn-cancel' : 'btn-primary')}
          onClick={action}
          disabled={submitting}
        >
          {submitting ? (
            <ButtonSpinner />
          ) : (
            <span className={clsx(styles.submitBtnText, { [styles.loading]: submitting })}>{yesText}</span>
          )}
        </button>
        <button className={isDelete ? 'btn-primary' : 'btn-cancel'} onClick={close} disabled={submitting}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Confirm;
