import { useStores } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import clsx from 'clsx';
import { useToast } from 'contexts/ToastProvider';
import Layout from 'layout';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'theme/atoms/breadcrumbs';
import ActionButton from 'theme/atoms/buttons/ActionButton';
import LoadingTable from 'theme/atoms/loaders/LoadingTable';
import PageHeader from 'theme/atoms/pageHeader';
import Pagination from 'theme/atoms/pagination/Pagination';
import Tag from 'theme/atoms/tag';
import { filtersIcons } from 'utils/constants';
import { isCategorySpamOrConfusing } from 'utils/isCategorySpamOrConfusing';

import AnnotatorDeleteModal from '../AnnotatorDeleteModal';
import AnnotatorName from '../AnnotatorName';
import AnnotatorProgressBar from '../AnnotatorProgressBar';
import styles from './AnnotatorDetails.module.scss';

const AnnotatorDetails = (): JSX.Element => {
  const { addToast, toastMessages } = useToast();
  const { annotatorsStore } = useStores();
  const {
    annotator,
    annotatorAssignmentCount,
    annotatorAssignments,
    changePageNumber,
    changePageSize,
    fetchAnnotator,
    fetchAnnotatorAssignments,
    generateAnnotatorMisclassificationsLink,
    isLoadingAnnotator,
    isLoadingAnnotatorAssignments,
    openAnnotatorDeleteModal,
    pageNumber,
    pageSize,
    resetPagination,
  } = annotatorsStore;

  const { id: annotatorId } = useParams();

  if (annotatorId === undefined) {
    return <span></span>;
  }

  useEffect(() => {
    fetchAnnotator(annotatorId).catch(() => {
      addToast(toastMessages.ANNOTATOR.FETCH_ERROR, 'error');
    });
    return () => resetPagination();
  }, []);

  useEffect(() => {
    fetchAnnotatorAssignments(pageSize, pageNumber + 1, annotatorId).catch(() => {
      addToast(toastMessages.ASSIGNMENTS.FETCH_ERROR, 'error');
    });
  }, [annotatorId, pageSize, pageNumber]);

  return (
    <Layout>
      <Breadcrumbs
        ancestors={[
          { title: 'Dashboard', to: paths.feed },
          { title: 'Annotators', to: paths.annotators },
        ]}
        current={annotator?.name ?? ''}
      />
      <PageHeader title="Details of" />
      <LoadingTable loading={isLoadingAnnotator} className={clsx(styles.table, styles.annotatorTable)}>
        {annotator ? (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Assigned</th>
                <th>Results</th>
                <th>Categories</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-th="Name">
                  <AnnotatorName
                    name={annotator.name}
                    url={generateAnnotatorMisclassificationsLink(annotator.dashboard.annotatorToken)}
                    urlText="Copy URL for reviewer"
                  />
                </td>
                <td data-th="Assigned">{annotator.dashboard.totalItems || 0}</td>
                <td data-th="Results">
                  <AnnotatorProgressBar annotator={annotator} lightTrackColor />
                </td>
                <td data-th="Categories">
                  <div>
                    {annotator.dashboard.targetCategories
                      ?.filter((tc) => isCategorySpamOrConfusing(tc.categoryName))
                      ?.map((tc) => {
                        const Icon = filtersIcons[tc.categoryName] || filtersIcons.League;
                        return <Tag key={tc.id} icon={<Icon />} text={tc.categoryName} />;
                      })}
                  </div>
                </td>
                <td>
                  <div>
                    <ActionButton type="Delete" text="Delete" action={openAnnotatorDeleteModal} />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
      </LoadingTable>

      <PageHeader title="Articles in dataset" className={styles.articlesTableHeader} small />
      <LoadingTable loading={isLoadingAnnotatorAssignments} className={clsx(styles.table, styles.articlesTable)}>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Title</th>
              <th>Comment</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {annotatorAssignments
              .map((a) => ({ ...a, statusClassName: a.status.replaceAll(' ', '') }))
              .map((a) => (
                <tr key={a.id}>
                  <td data-th="Id">{a.id}</td>
                  <td data-th="Name">
                    <AnnotatorName name={a.item.title} />
                  </td>
                  <td data-th="Comment">{a.comment || ''}</td>
                  <td data-th="Status">
                    <div className={clsx(styles.statusLabel, styles[`statusLabel-${a.statusClassName}`])}>
                      {a.status}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          activePage={pageNumber}
          activePageItemCount={annotatorAssignments.length}
          allItemCount={annotatorAssignmentCount}
          disabled={isLoadingAnnotatorAssignments}
          goToPage={(newPageNumber) => changePageNumber(newPageNumber)}
          pageSize={pageSize}
          onPageSizeChange={changePageSize}
        />
      </LoadingTable>

      <AnnotatorDeleteModal annotatorId={annotatorId} isAnnotatorDetailsView />
    </Layout>
  );
};

export default observer(AnnotatorDetails);
