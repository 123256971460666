import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowUpIcon } from 'theme/svg/arrow-up.svg';
import { ReactComponent as CloseIcon } from 'theme/svg/close.svg';
import { useDebounce } from 'utils/hooks';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import Dropdown from './Dropdown/Dropdown';
import styles from './Multiselect.module.scss';
import { List, ListItem } from './types';

interface MultiselectProps {
  className?: string;
  disabled?: boolean;
  list: List;
  useFullWidthForButton?: boolean;
  onDropdownOpen: (title: string) => void;
  onDropdownClose: (title: string) => void;
  onReset: (title: string) => void;
  onSearchValueChange: (list: List, searchValue: string) => void;
  onItemToggle: (list: List, title: string) => void;
  onNextPage?: (data: { listToChange: List; searchText: string }) => void;
}

function Multiselect({
  className,
  disabled = false,
  list,
  useFullWidthForButton,
  onDropdownOpen,
  onDropdownClose,
  onNextPage,
  onSearchValueChange,
  onItemToggle,
  onReset,
}: MultiselectProps): JSX.Element {
  const scrollableElementRef = useRef<HTMLDivElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const debounceSearchText = useDebounce(searchText, 500);

  const multiselectRef = useRef<HTMLDivElement>(null);
  useOnClickOutside({ ref: multiselectRef, handler: () => onDropdownClose(list.title), condition: list.open });

  useEffect(() => {
    setSearchText('');
  }, [list.open]);

  useEffect(() => {
    onSearchValueChange(list, debounceSearchText);
    if (scrollableElementRef.current) scrollableElementRef.current.scrollTop = 0;
  }, [debounceSearchText]);

  const handleToggleFilterCheck = (itemToToggle: ListItem) => {
    onItemToggle(list, itemToToggle.id);
  };

  const handleNextPage = () => {
    if (onNextPage) onNextPage({ listToChange: list, searchText });
  };

  const handleReset = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onReset(list.title);
  };

  const checkedItems = list.items.filter((item) => item.checked);

  return (
    <div className={clsx(styles.multiselectWrapper, className)} ref={multiselectRef}>
      <button
        onClick={() => (list.open ? onDropdownClose(list.title) : onDropdownOpen(list.title))}
        type="button"
        disabled={disabled}
        className={clsx(styles.filter, {
          [styles.useFullWidthForButton]: useFullWidthForButton,
          [styles.withItemsChecked]: checkedItems.length && !list.open,
          [styles.open]: list.open,
        })}
      >
        <div className={styles.title}>
          {list.icon ? (
            <div className={styles.iconBox}>
              <list.icon />
            </div>
          ) : null}

          <div className={styles.filterName}>{checkedItems.length ? checkedItems[0].title : list.title}</div>
          {checkedItems.length > 1 ? <div className={styles.filterNumber}>+{checkedItems.length - 1}</div> : null}
        </div>

        {checkedItems.length ? (
          <div className={styles.iconClose} onClick={handleReset}>
            <CloseIcon />
          </div>
        ) : (
          <div className={styles.iconOpen}>
            <ArrowUpIcon />
          </div>
        )}
      </button>
      {list.open && (
        <Dropdown
          list={list}
          ref={scrollableElementRef}
          searchText={searchText}
          onChangeSearchText={(value: string) => setSearchText(value)}
          toggleFilterCheck={handleToggleFilterCheck}
          onNextPage={handleNextPage}
          wide={list.kind === 'Team'}
        />
      )}
    </div>
  );
}

export default Multiselect;
