import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';
import { useStores } from 'RootStore';
import { paths } from 'app/routes/paths.const';
import Layout from 'layout';
import Breadcrumbs from 'theme/atoms/breadcrumbs';
import { List } from 'theme/atoms/multiselect';
import PageHeader from 'theme/atoms/pageHeader';
import { ReactComponent as AddFolderIcon } from 'theme/svg/folder-add.svg';
import getFiltersLists from 'utils/getFiltersLists';

import styles from './Profiles.module.scss';

import ProfilesList from './ProfilesList/ProfilesList';
import ProfilesFilters from './ProfilesFilters/ProfilesFilters';
import ProfileArchiveModal from '../ProfileArchiveModal/ProfileArchiveModal';
import ProfileFormModal from '../ProfileFormModal/ProfileFormModal';

const PROFILE_TYPES = {
  rss: 'RSS',
  twitter: 'Twitter',
  sitemap: 'Sitemap',
};

function Profiles(): JSX.Element {
  const { profilesStore } = useStores();
  const {
    changeSearchValue,
    clearFilters,
    closeProfileArchiveModal,
    closeProfileForm,
    isParentProfileOpen,
    isProfileArchiveModalOpen,
    isProfileFormOpen,
    openProfileForm,
    profile,
    searchValue,
    setNewlyCreated,
  } = profilesStore;

  const { profileType } = useParams();
  const [lists, setLists] = useState<List[]>([]);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setLists(getFiltersLists(profileType));
  }, [profileType]);

  const highlightNewlyCreated = (): void => {
    setNewlyCreated(true);
    timer.current = setTimeout(() => {
      setNewlyCreated(false);
    }, 7000);
  };

  useEffect(() => {
    return () => {
      clearFilters();
      if (timer.current) clearTimeout(timer.current);
    };
  }, [profileType]);

  const profileTypeName = profileType ? PROFILE_TYPES[profileType] : '';

  return (
    <Layout
      searchConfig={{
        value: searchValue,
        onChange: (newValue: string) => changeSearchValue(newValue, profileType ?? ''),
        view: 'profiles',
      }}
    >
      <div className={styles.inner}>
        <Breadcrumbs ancestors={[{ title: 'Dashboard', to: paths.feed }]} current="Browsing feeds" />
        <PageHeader title={`Browsing ${profileTypeName} Feeds`}>
          {profileTypeName && (
            <button className={clsx(styles.addProfileBtn, 'btn-primary')} onClick={() => openProfileForm()}>
              <AddFolderIcon />
              Add {profileTypeName} Feed
            </button>
          )}
        </PageHeader>
        <div className={styles.profilesContainer}>
          <ProfilesFilters lists={lists} setLists={setLists} />
          <ProfilesList lists={lists} setLists={setLists} />
        </div>
      </div>

      <ProfileFormModal
        isOpen={isProfileFormOpen}
        close={closeProfileForm}
        profile={profile}
        isParentDetails={isParentProfileOpen}
        onCreate={highlightNewlyCreated}
      />
      <ProfileArchiveModal isOpen={isProfileArchiveModalOpen} close={closeProfileArchiveModal} />
    </Layout>
  );
}

export default observer(Profiles);
