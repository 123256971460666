import { RootStore } from 'RootStore';
import { makeAutoObservable } from 'mobx';
import { Metadata, MetadataRawBEData } from 'utils/types/Metadata';

import { CarelistMetadataErrors, FetchMetadataProps } from '.';
import * as requests from './metadataRequests';
import { CarelistMetadata } from './metadataTypes';

export const filtersPerPage = 10;

class MetadataStore {
  rootStore: RootStore;

  feedMetadata: MetadataRawBEData[] = [];
  userLabels: Metadata[] = [];
  categories: Metadata[] = [];

  isFetchingCarelistMetadata = false;
  isInitialCarelistMetadataFetched = false;

  carelistMetadata: CarelistMetadata = {
    sports: [],
    leagues: [],
    teams: [],
  };

  carelistMetadataErrors: CarelistMetadataErrors = {};

  isMetadataFetched = false;
  error = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  fetchMetadata = ({
    metadataTypes,
    kinds = [],
    page = 1,
    searchText,
  }: FetchMetadataProps): Promise<MetadataRawBEData[]> => {
    return requests
      .fetchMetadata({
        metadataTypes,
        kinds,
        number: page,
        size: filtersPerPage,
        // sortBy: 'ItemCountDesc',
        textSearch: searchText,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          this.rootStore.authStore.logout();
        }
        throw err;
      });
  };
}

export default MetadataStore;
