import { MutableRefObject, useEffect, useRef } from 'react';

interface Props {
  ref: MutableRefObject<unknown> | MutableRefObject<unknown>[];
  handler: () => void;
  condition?: boolean;
}

const useOnClickOutside = ({ ref, handler, condition = true }: Props): void => {
  const shouldHandle = useRef(condition);

  const handleClickOutside = (event: MouseEvent) => {
    if (shouldHandle.current === true) {
      if (ref) {
        if (Array.isArray(ref)) {
          let shouldRunHandler = true;

          for (const refElement of ref) {
            // eslint-disable-next-line
            if (refElement.current && (refElement.current as any).contains(event.target)) {
              shouldRunHandler = false;
              break;
            }
          }

          if (shouldRunHandler) {
            handler();
          }
        } else {
          // eslint-disable-next-line
          if (ref.current && !(ref.current as any).contains(event.target)) {
            handler();
          }
        }
      }
    }
  };

  useEffect(() => {
    shouldHandle.current = condition;
  }, [condition]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export default useOnClickOutside;
