import React from 'react';
import CopyToClipboard from 'theme/atoms/copyToClipboard';

import styles from './AnnotatorName.module.scss';

type AnnotatorNameProps = {
  name: string;
  url?: string;
  urlText?: string;
};

const AnnotatorName = ({ name, url, urlText }: AnnotatorNameProps): JSX.Element => {
  return (
    <div className={styles.root}>
      <span className={styles.name}>{name}</span>
      {url && <CopyToClipboard className={styles.copyToClipboard} url={url} text={urlText} />}
    </div>
  );
};

export default AnnotatorName;
