import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/App';
import 'theme/theme-style.scss';

import 'theme/fonts/Inter-SemiBold.ttf';
import 'theme/fonts/Inter-SemiBold.woff';
import 'theme/fonts/Inter-SemiBold.woff2';
import 'theme/fonts/Inter-Medium.ttf';
import 'theme/fonts/Inter-Medium.woff';
import 'theme/fonts/Inter-Medium.woff2';
import 'theme/fonts/Inter-Regular.ttf';
import 'theme/fonts/Inter-Regular.woff';
import 'theme/fonts/Inter-Regular.woff2';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
