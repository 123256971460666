import { MetadataSectionKind } from 'metadata';
import { MetadataSectionGroup, Metadata, MetadataRawBEData } from 'utils/types/Metadata';
import { Profile, ProfileRawBEData } from 'utils/types/Profile';

const mapUserLabels = (metadata: MetadataRawBEData[] | undefined): Metadata[] => {
  if (typeof metadata === 'undefined') return [];

  return (
    metadata
      ?.filter((m) => m.metadataType === 'UserLabel')
      .map(({ id, title }) => ({
        id,
        title,
      })) ?? []
  );
};

const mapSectionsGroups = (metadata: MetadataRawBEData[] | undefined): MetadataSectionGroup[] => {
  if (typeof metadata === 'undefined') return [];

  return (
    metadata
      ?.map((m) => (m.metadataType === 'Category' ? 'Category' : m.kind))
      .filter((kind, i, arr) => kind && arr.indexOf(kind) === i)
      .map((kind) => {
        const checkedKind = kind as MetadataSectionKind;

        return {
          kind: checkedKind,
          items:
            metadata
              ?.filter((m) => (m.metadataType === 'Category' ? m.metadataType === kind : m.kind === kind))
              .map(({ id, title, region, level }) => ({
                id,
                title,
                kind: checkedKind,
                region: region || '',
                level: level || '',
              })) ?? [],
        };
      }) ?? []
  );
};

export const mapProfile = ({
  country,
  createdAt,
  flags,
  id,
  imageUrl,
  lastError,
  latitude,
  longitude,
  metadata,
  parent,
  postalCode,
  source,
  sourceKind,
  title,
  twitterUsername,
}: ProfileRawBEData): Profile => ({
  autoPosting: flags?.autoPosting || false,
  backload: flags?.backload || false,
  country,
  createdAt,
  disableImage: flags?.disableImage || false,
  id,
  imageUrl,
  isDisabled: flags?.isDisabled || false,
  lastError: lastError || '',
  latitude,
  longitude,
  mlCategorization: flags?.mlCategorization || false,
  parent: parent ? mapProfile(parent) : null,
  postalCode,
  sectionsGroups: mapSectionsGroups(metadata),
  source,
  sourceKind,
  teamIdentification: flags?.teamIdentification || false,
  title: title || '',
  twitterUsername: twitterUsername || '',
  useKeyPoints: flags?.useKeyPoints || false,
  useOpenaiMetadata: flags?.useOpenaiMetadata || false,
  useOpenaiSports: flags?.useOpenaiSports || false,
  useProxy: flags?.useProxy || false,
  userLabels: mapUserLabels(metadata),
  useSeoTitle: flags?.useSeoTitle || false,
  useShortDescription: flags?.useShortDescription || false,
});
