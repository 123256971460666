import { RootStore } from 'RootStore';
import debounce from 'lodash.debounce';
import { MetadataSectionKind } from 'metadata';
import { makeAutoObservable } from 'mobx';
import { additionalFilters } from 'utils/constants';
import handleAdditionalFilters from 'utils/handleAdditionalFilters';
import { Filter } from 'utils/types/Filter';
import { FiltersSource } from 'utils/types/FiltersSource';
import { MetadataSectionGroup, Metadata } from 'utils/types/Metadata';
import { Post } from 'utils/types/Post';
import { Profile } from 'utils/types/Profile';

import * as requests from './postsRequests';

class PostsStore {
  rootStore: RootStore;

  searchValue = '';

  isLoadingPosts = false;

  arePostsFetched = false;

  areInitialDataFetched = false;
  postsError = false;
  posts: Post[] = [];
  page = 1;
  shouldFetchNextPage = true;

  filters: Filter[] = [];
  filtersSource: FiltersSource = 'main';

  post: Post | null = null;

  isProfileFormOpen = false;
  profile: Profile | null = null;

  filterClickedOnPost: Filter | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  debouncedFetchFilteredPosts = debounce(() => {
    this.fetchPosts(true);
  }, 500);

  updateFilters = (newFilters: Filter[]): boolean => {
    let isChange = false;

    if (
      this.filters.length === newFilters.length &&
      this.filters.every((filter, i) => filter.id === newFilters[i].id)
    ) {
      isChange = false;
    } else {
      this.filters = newFilters;
      this.debouncedFetchFilteredPosts();
      isChange = true;
    }

    return isChange;
  };

  changeFilterClickedOnPost = (filter: Filter): void => {
    this.filterClickedOnPost = filter;
  };

  updateFiltersSource = (source: FiltersSource): void => {
    this.filtersSource = source;
  };

  clearFilters = (): boolean => {
    let shouldScrollToTop = false;
    if (this.filters.length || this.searchValue) {
      this.filters = [];
      this.searchValue = '';
      shouldScrollToTop = true;
    }

    this.fetchPosts(true);
    return shouldScrollToTop;
  };

  fetchPosts = (resetPreviousData?: boolean): Promise<void> => {
    this.shouldFetchNextPage = true;

    if (resetPreviousData) {
      this.isLoadingPosts = true;
      this.page = 1;
    }

    const filtersIds = this.filters.map((filter) => filter.id);
    const metadataFiltersIds = filtersIds.filter((fId) => !additionalFilters.includes(fId));
    const otherFiltersIds = filtersIds.filter((fId) => additionalFilters.includes(fId));

    return requests
      .fetchPosts({
        textSearch: this.searchValue,
        metadataId: metadataFiltersIds,
        page: this.page,
        ...handleAdditionalFilters(otherFiltersIds),
      })
      .then((res) => {
        if (!this.areInitialDataFetched) this.areInitialDataFetched = true;
        const postsRaw = res.data;
        const previousPosts = resetPreviousData ? [] : [...this.posts];

        if (postsRaw.length < requests.postsPerPage) {
          this.shouldFetchNextPage = false;
        }

        this.posts = [
          ...previousPosts,
          ...postsRaw.map((post) => {
            const userLabels: Metadata[] = post.metadata
              ? post.metadata
                  .filter((el) => el.metadataType === 'UserLabel')
                  .map((userLabelRaw) => ({
                    id: userLabelRaw.id,
                    title: userLabelRaw.title,
                  }))
              : [];

            const sectionsGroups: MetadataSectionGroup[] = post.metadata
              ? post.metadata
                  .map((el) => el.kind)
                  .filter((kind, i, arr) => kind && arr.indexOf(kind) === i)
                  .map((kind) => {
                    const checkedKind = kind as MetadataSectionKind;
                    return {
                      kind: checkedKind,
                      items: post.metadata
                        ? post.metadata
                            .filter((r) => r.kind === kind)
                            .map((item) => {
                              return {
                                id: item.id,
                                title: item.title,
                                kind: checkedKind,
                              };
                            })
                        : [],
                    };
                  })
              : [];

            const categories: Metadata[] = [];

            return {
              ...post,
              userLabels,
              sectionsGroups,
              categories,
            };
          }),
        ];

        this.page += 1;
        this.postsError = false;
        this.arePostsFetched = true;
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          this.rootStore.authStore.logout();
          throw err;
        }
        this.postsError = true;
      })
      .finally(() => {
        if (resetPreviousData) {
          this.isLoadingPosts = false;
        }
      });
  };

  changeSearchValue = (newValue: string): void => {
    this.searchValue = newValue;
    this.debouncedFetchFilteredPosts();
  };

  openProfileForm = (profile?: Profile): void => {
    this.isProfileFormOpen = true;
    this.profile = profile || null;
  };

  closeProfileForm = (): void => {
    this.isProfileFormOpen = false;
    this.profile = null;
  };
}

export default PostsStore;
